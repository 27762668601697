import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../logincomponents/Title";
import TextField from "../logincomponents/PhoneVerification";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import BottomButtons from "../logincomponents/BottomButtons";
import MustTextField from "../logincomponents/MustTextField";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LanguageContext } from "../LanguageContext";

const PH = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { translations, language } = useContext(LanguageContext);
  const foundUserName = sessionStorage.getItem("userNameforPH");

  const foundUserId = sessionStorage.getItem("phoneNumberforPH");
  const phoneNumber = location.state?.phoneNumber || "";
  const name = location.state?.name || "";
  const [realNumber, setRealNumber] = useState("");

  const handlePhoneNumberChange = (newPhoneNumber) => {
    // +82로 시작하는 번호를 010으로 변경
    let formattedNumber = newPhoneNumber;
    if (formattedNumber.startsWith("+82")) {
      formattedNumber = "0" + formattedNumber.slice(3);
    }

    // 숫자만 추출
    formattedNumber = formattedNumber.replace(/\D/g, "");

    // 11자리로 제한
    formattedNumber = formattedNumber.slice(0, 11);

    // 형식 적용 (010-1234-5678)
    if (formattedNumber.length > 3) {
      formattedNumber = formattedNumber.replace(
        /(\d{3})(\d{0,4})(\d{0,4})/,
        "$1-$2-$3"
      );
    }
  };

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length === 11) {
      return `+82${cleaned.substring(1)}`;
    }
    return `+82${cleaned}`;
  };

  const handlePasswordReset = async () => {
    if (foundUserName !== name) {
      const toastId = "auth-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("인증 정보가 일치하지 않습니다.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return null;
    } else if (!name) {
      const toastId = "auth-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("본인인증을 완료해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return null;
    } else {
      if (phoneNumber !== foundUserId) {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/auth/update-phone`,
            {
              newPhoneNumber: formatPhoneNumber(phoneNumber),
            },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                  "accessToken"
                )}`, // Add your bearer token here
              },
            }
          );
          if (response.status === 200) {
            navigate("registersetting");
          }
        } catch (err) {}
      }
    }
  };

  const openNiceWindow = async () => {
    sessionStorage.setItem("registerwhere", "ph");
    const { form } = document;
    const res = await axios.get(`https://pastellapp.com/api/auth/nice`);

    if (form && res.data) {
      const { tokenVersionId, encData, integrityValue } = res.data;
      form.enc_data.value = encData;
      form.token_version_id.value = tokenVersionId;
      form.integrity_value.value = integrityValue;
      form.submit();
    }
  };

  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop={translations.ph1}
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        <FormForm>
          <OptionalTextField
            prop={translations.ph2}
            value={foundUserId}
            readOnly={true}
            onChange={() => {}}
            style={{ backgroundColor: "#E2E8EF" }}
            disabled={true}
          />
          {name ? null : (
            <div
              onClick={openNiceWindow}
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                margin: "20px auto", // To center horizontally
                maxWidth: "200px", // Optional, adjust as needed
              }}
            >
              {translations.ph3}
            </div>
          )}
          {phoneNumber ? (
            <MustTextField
              prop="변경 휴대폰번호"
              prop1="본인인증을 진행해주세요."
              value={phoneNumber}
              style={{ backgroundColor: "#E2E8EF" }}
            />
          ) : (
            ""
          )}
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BottomButtons prop1={translations.ph4} onClick={handlePasswordReset} />
      </div>
      <form
        name="form"
        id="form"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
      <ToastContainer />
    </ReactiveOuter>
  );
};

export default PH;
