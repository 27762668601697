import styles from "./OptionalTextField.module.css";

const OptionalTextField = ({
  prop,
  prop1,
  value,
  onChange,
  disabled,
  darkMode,
}) => {
  return (
    <div className={`${styles.textField} `}>
      <b className={`${styles.b} ${darkMode ? "text-[#fff]" : ""}`}>
        <span className={darkMode ? "text-[#fff]" : ""}>{prop}</span>
      </b>
      <input
        className={`${styles.field} ${
          darkMode ? "bg-[#22232a] text-[#fff] border-none" : ""
        }`}
        placeholder={prop1}
        type="text"
        maxLength="15"
        autoCorrect="off"
        autoComplete="off"
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default OptionalTextField;
