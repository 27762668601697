import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FrameComponent3 from "../logincomponents/FrameComponent3";
import styles from "./PW1.module.css";
import Field from "../logincomponents/Field";
import Title from "../logincomponents/Title";
import PropTypes from "prop-types";
import BBtn from "../logincomponents/BBtn";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PW1 = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [foundUserId, setFoundUserId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/;

    if (!password) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("비밀번호를 입력해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return false;
    }

    if (!passwordRegex.test(password)) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error(
          "비밀번호는 영문, 숫자, 특수문자 조합으로 8자~15자 이내여야 합니다.",
          {
            position: "top-center",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: toastId, // Assign the unique toastId
            progress: undefined,
          }
        );
      }
      return false;
    }

    if (password !== confirmPassword) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("비밀번호가 일치하지 않습니다.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (userId) {
      setFoundUserId(decodeURIComponent(userId));
    } else {
    }
  }, [userId]);

  const handlePasswordChange = (value) => {
    const syntheticEvent = { target: { value } };

    setPassword(syntheticEvent.target.value);
  };

  const handleConfirmPasswordChange = (value) => {
    const syntheticEvent = { target: { value } };
    setConfirmPassword(syntheticEvent.target.value);
  };

  const handleSubmit = async () => {
    if (!validatePassword()) {
      return; // Stop if validation fails
    }
    if (password === confirmPassword) {
      // 비밀번호 재설정 로직 구현

      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/auth/reset-password`,
          {
            userid: foundUserId,
            newPassword: password,
          }
        );
        if (response.status === 200) {
          if (sessionStorage.getItem("whereforP" === "rs")) {
            sessionStorage.removeItem("wehreforP");
            navigate("/registersetting");
          } else {
            navigate("/login1");
          }
        }
      } catch (err) {}
    } else {
      alert("비밀번호가 일치하지 않습니다.");
    }
  };

  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="비밀번호 재설정"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        {/* <FrameComponent3 prop="비밀번호 재설정" prop1="1213213@fmail.com" /> */}
        <section className={styles.pwInner}>
          <div className={[styles.textFieldParent, className].join(" ")}>
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="#E2E8EF"
              prop=""
              prop1="아이디"
              value={foundUserId}
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
              readOnly={true}
              disabled={true}
            />
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="unset"
              prop="영문, 숫자, 특수문자 조합 8자-15자 이내"
              prop1="비밀번호 재설정"
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
              value={password}
              onChange={handlePasswordChange}
              type="password"
            />
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="unset"
              prop="비밀번호를 한번 더 입력해주세요"
              prop1="비밀번호 확인"
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              type="password"
            />
          </div>
        </section>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BBtn prop={"비밀번호 재설정"} onClick={handleSubmit} />
      </div>
      <ToastContainer />
    </ReactiveOuter>
  );
};

PW1.propTypes = {
  className: PropTypes.string,
};

export default PW1;
