import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { LanguageContext } from "./LanguageContext";

const MyPageFaq = () => {
  const { translations, language } = useContext(LanguageContext);

  // State for FAQ data and filtered FAQs
  const [faqs, setFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);

  // State for selected filter
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(
    language === "kor" ? "전체" : "All"
  );

  // Fetch FAQ data from API
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch("https://pastellapp.com/api/faq");
        const data = await response.json();
        setFaqs(data);
        setFilteredFaqs(data); // Initially display all FAQs
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
      }
    };
    fetchFaqs();
  }, []);

  // Update filtered FAQs based on selected filter
  useEffect(() => {
    if (selectedFilter === "전체" || selectedFilter === "All") {
      setFilteredFaqs(faqs);
    } else {
      const filtered = faqs.filter((faq) => faq.category === selectedFilter);
      setFilteredFaqs(filtered);
    }
  }, [selectedFilter, faqs]);

  // Function to handle accordion click
  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle accordion
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleFilterClick = (filter) => {
    if (filter === "General") {
      setSelectedFilter("일반");
    } else if (filter === "Payment") {
      setSelectedFilter("결제");
    } else if (filter === "Refund") {
      setSelectedFilter("환불");
    } else if (filter === "Study") {
      setSelectedFilter("학습");
    } else if (filter === "Contents") {
      setSelectedFilter("기능");
    } else {
      setSelectedFilter(filter);
    }
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    // Close all accordions when selectedFilter changes
    setOpenIndex(null);
  }, [selectedFilter]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);
  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex flex-col items-center justify-between h-auto bg-[#faf8f6] dark:bg-[#121418]">
        <div className="flex items-center justify-between w-full h-[50px]">
          <div
            className="w-[36px] h-[36px] flex items-center justify-center"
            onClick={handleGoBack}
          >
            <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
          </div>
          <div className="flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
            FAQ
          </div>
          <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
            <img width="10" height="16" src="back.png" alt="icon" />
          </div>
        </div>

        {/* Filter Row */}
        <div
          className={`flex items-center ${
            language === "kor" ? "justify-center" : ""
          } gap-[6px] w-full pb-[16px] px-[16px] overflow-x-auto no-scrollbar`}
        >
          {[
            translations.faq1, // 전체
            translations.faq2, // 일반
            translations.faq3, // 결제
            translations.faq4, // 환불
            translations.faq5, // 학습
            translations.faq6, // 기능
          ].map((filter) => (
            <div
              key={filter}
              className={`flex flex-row items-center justify-center py-[8px] px-[14px] rounded-[59px] 
              ${
                selectedFilter === filter ||
                (selectedFilter === "일반" && filter === translations.faq2) ||
                (selectedFilter === "결제" && filter === translations.faq3) ||
                (selectedFilter === "환불" && filter === translations.faq4) ||
                (selectedFilter === "학습" && filter === translations.faq5) ||
                (selectedFilter === "기능" && filter === translations.faq6)
                  ? darkMode
                    ? "bg-[#fff] border-[#fff] text-[#000]"
                    : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                  : darkMode
                  ? " border-[#e9ecef] text-[#fff]"
                  : " border-[#e9ecef] text-[#000]"
              }`}
              onClick={() => handleFilterClick(filter)}
            >
              <div
                className={`text-[14px] leading-[100%] font-['Pretendard'] font-medium  ${
                  selectedFilter === filter
                    ? "text-[#3677f6]"
                    : darkMode
                    ? "text-[#fff]"
                    : "text-[#000]"
                } whitespace-nowrap`}
              >
                {filter}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* FAQ Content */}
      <div className="absolute left-0 top-[125px] w-[100%] flex flex-col items-start justify-start gap-[16px]">
        <div className="w-[100%] flex flex-col items-start justify-start bg-[#fff] dark:bg-[#22232a]">
          {filteredFaqs.map((faq, index) => (
            <div key={faq.id} className="self-stretch">
              {/* Accordion Header */}
              <div
                className="h-[54px] shrink-0 flex flex-row items-center justify-between py-[10px] px-[20px] cursor-pointer border-[solid] border-[#e9ecef] dark:border-[#464a5a] border-b-[1px]"
                onClick={() => handleAccordionClick(index)}
              >
                <div className="text-[16px] leading-[100%] font-['Pretendard'] font-bold text-[#000] dark:text-[#fff] whitespace-nowrap">
                  {faq.title}
                </div>
                <img
                  width="30"
                  height="30"
                  src={"open.png"}
                  alt="toggle icon"
                  className={`transition-transform duration-300 ${
                    openIndex === index ? "rotate-180" : "rotate-0"
                  } ${darkMode ? "filter invert" : ""}`}
                />
              </div>

              {/* Accordion Content */}
              {openIndex === index && (
                <div className="self-stretch flex flex-row items-center justify-start pt-[10px] px-[20px] pb-[20px] border-[solid] border-[#e9ecef] border-t-0">
                  <div
                    className="flex-1 text-[14px] leading-[22px] font-['Pretendard'] font-medium text-[#383c4b] dark:text-[#a9b6ca]"
                    dangerouslySetInnerHTML={{ __html: faq.content }}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyPageFaq;
