import React, { useState, useEffect } from 'react';
import trashIcon from '../../assets/admin/icons/trash.png';

const ScriptEditor = ({ script, onScriptChange, language, type }) => {
  const [localScript, setLocalScript] = useState(script);
  const [characters, setCharacters] = useState(['A', 'B']);

  useEffect(() => {
    const initialScript = {
      ...script,
      humanA: script.humanA || '',
      humanB: script.humanB || '',
      humancount: Math.max(2, script.humancount || 0)
    };
    setLocalScript(initialScript);
    setCharacters(['A', 'B', ...['C', 'D'].filter(char => initialScript[`human${char}`])]);
  }, [script]);

  const handleChange = (e, char) => {
    const { name, value } = e.target;
    const updatedScript = {
      ...localScript,
      [name]: value,
    };

    if (name.startsWith('human')) {
      const updatedCharacters = ['A', 'B', 'C', 'D'].filter(c => 
        c === char ? value : updatedScript[`human${c}`]
      );
      setCharacters(updatedCharacters);
      updatedScript.humancount = updatedCharacters.length;
    }

    setLocalScript(updatedScript);
    onScriptChange(language, type, updatedScript);
  };

  const addCharacter = () => {
    if (characters.length < 4) {
      setCharacters(prev => [...prev, String.fromCharCode(65 + prev.length)]);
    }
  };

  const removeCharacter = (char) => {
    if (characters.length > 2) {
      const updatedCharacters = characters.filter(c => c !== char);
      setCharacters(updatedCharacters);

      const updatedScript = { ...localScript };
      ['A', 'B', 'C', 'D'].forEach((c, index) => {
        if (index < updatedCharacters.length) {
          updatedScript[`human${c}`] = updatedScript[`human${updatedCharacters[index]}`] || '';
        } else {
          updatedScript[`human${c}`] = '';
        }
      });

      updatedScript.humancount = updatedCharacters.length;
      setLocalScript(updatedScript);
      onScriptChange(language, type, updatedScript);
    }
  };

  // JSX 부분은 변경 없음
  return (
    <div className="mb-4">
      {/* BACKGROUND Section */}
      <div className="mb-2.5">
        <label
          className="block mb-2.5"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
            textAlign: 'left',
          }}
        >
          BACKGROUND
        </label>
        <textarea
          type="text"
          name="background"
          value={localScript.background || ''}
          onChange={(e) => handleChange(e)}
          className="border rounded-md w-full h-[220px]"
          placeholder="배경설명을 작성하세요"
          style={{
            padding: '18px 14px',
            borderRadius: '12px',
            fontSize: '14px',
            border: '1px solid #E2E8EF',
          }}
        />
      </div>
  
      {/* Coversation 등장인물 Section */}
      <div className="mb-4">
        <label
          className="block mb-1"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
            textAlign: 'left',
          }}
        >
          Conversation 등장인물
        </label>
        <div className="space-y-4">
          {characters.map((char, index) => (
            <div className="flex items-center space-x-4" key={index}>
              <label
                className="block w-[114px]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                {`인물 ${String.fromCharCode(65 + index)}${index === 0 ? ' (발화자)' : ''}`}
              </label>
              <input
                type="text"
                name={`human${char}`}
                value={localScript[`human${char}`] || ''}
                onChange={(e) => handleChange(e, char)}
                className="border p-2 rounded-md flex-grow"
                placeholder={`이름(MBTI), 성별`}
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
              {characters.length > 1 && (
                <button
                  type="button"
                  className="p-2 rounded-md flex items-center justify-center"
                  onClick={() => removeCharacter(char)}
                >
                  <img src={trashIcon} alt="Delete" className="w-5 h-5" />
                </button>
              )}
            </div>
          ))}
          {characters.length < 4 && (
            <div className="flex items-center space-x-4">
              <label
                className="block w-[114px]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                {`인물 ${String.fromCharCode(65 + characters.length)}`}
              </label>
              <button
                type="button"
                className="p-2 rounded-md flex-grow border border-[#A9B6CA] text-[#666B81] text-left"
                onClick={addCharacter}
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
              >
                추가하기
              </button>
            </div>
          )}
        </div>
      </div>
  
      {/* Conversation Section */}
      <div className="mb-4">
        <label
          className="block mb-2.5"
          style={{
            fontFamily: 'Pretendard Variable',
            fontSize: '14px',
            fontWeight: 700,
            textAlign: 'left',
          }}
        >
          Conversation
        </label>
        <textarea
          name="script"
          value={localScript.script}
          onChange={(e) => handleChange(e)}
          className="border rounded-md w-full h-[278px]"
          placeholder="대화문을 입력하세요"
          style={{
            padding: '18px 14px',
            borderRadius: '12px',
            border: '1px solid #E2E8EF',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 500,
            textAlign: 'left',
          }}
        />
      </div>

      {/* NOTE Section */}
      <div className="mb-4">
        <textarea
          name="note"
          value={localScript.note}
          onChange={(e) => handleChange(e)}
          className="border rounded-md w-full h-[48px]"
          placeholder="NOTE"
          style={{
            padding: '14px 14px',
            borderRadius: '12px',
            fontSize: '14px',
            border: '1px solid #E2E8EF',
          }}
        />
      </div>

      {/* 아래 선 추가 */}
      <div className="border-b-[1px] border-[#E2E8EF] mt-4"></div>
    </div>
  );
};

export default ScriptEditor;