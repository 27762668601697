import styles from "./FormForm.module.css";

const FormForm = ({ children, darkMode }) => {
  return (
    <div className={`${styles.frameDiv} ${darkMode ? "bg-[#121418]" : ""}`}>
      <form className={styles.textFieldParent}>{children}</form>
    </div>
  );
};

export default FormForm;
