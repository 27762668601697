import axios from 'axios';
import API_BASE_URL from '../../config';

// 결제 조회
export const getCash = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/payment`);
        return response.data;
    } catch (error) {
        console.error('Error fetching cash:', error);
        throw error;
    }
};

// 결제 취소
export const createCash = async (cash) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/payment/cancel`, cash);
        return response.data;
    } catch (error) {
        console.error('Error creating cash:', error);
        throw error;
    }
};

// 결제 상세조회
export const getCashById = async (cashId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/payment/${cashId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching cash:', error);
        throw error;
    }
};

// 결제 페이지네이션
export const getCashPagination = async (page = 0, limit = 20, search = '', goodsName = '', startDate = null, endDate = null) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/payment/list`, {
            params: { 
                page: Math.max(1, page), // 페이지 번호가 1 미만이 되지 않도록 보장
                limit, 
                search, 
                goodsName,
                startDate: startDate ? startDate.toISOString() : null,
                endDate: endDate ? endDate.toISOString() : null
            }   
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching cash:', error);
        throw error;
    }
};
