// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import login_main from '../../assets/admin/images/logo/login_main.png';
import axios from 'axios';
import API_BASE_URL from '../../config';

function Login() {
  const [username, setUsername] = useState(() => localStorage.getItem('savedUsername') || '');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(() => localStorage.getItem('rememberMe') === 'true');
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/manage-signin`, {
        userid: username,
        password: password
      });
      
      // 아이디 저장 처리
      if (rememberMe) {
        localStorage.setItem('savedUsername', username);
        localStorage.setItem('rememberMe', 'true');
      } else {
        localStorage.removeItem('savedUsername');
        localStorage.setItem('rememberMe', 'false');
      }

      if (response.data && response.data.accessToken && response.data.refreshToken) {
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        
        // 사용자 프로필 정보 가져오기
        const profileResponse = await axios.get(`${API_BASE_URL}/auth/manage/profile`, {
          headers: { Authorization: `Bearer ${response.data.accessToken}` }
        });

        if (profileResponse.data && profileResponse.data.userType) {
          if (profileResponse.data.userType === 'admin' || profileResponse.data.userType === 'manager') {
            navigate('/dashboard');
            window.location.reload();
          } else {
            alert('접속 권한이 없습니다.');
            navigate('/admin');
          }
        } else {
          throw new Error('사용자 정보를 가져오는데 실패했습니다.');
        }
      } else {
        throw new Error('토큰이 올바르지 않습니다.');
      }
    } catch (error) {
      console.error("로그인 실패", error);
      alert('아이디 또는 비밀번호가 잘못되었습니다.');
      navigate('/admin');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  // 체크박스 상태 변경 핸들러
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <div className="flex items-center justify-center min-h-screen" style={{ backgroundColor: '#FAF8F6' }}>
      <div className="w-full bg-white shadow-lg" style={{ width: '496px', height: '552px', padding: '50px 100px', borderRadius: '12px' }}>
        {/* Pastella 로고 */}
        <div className='flex justify-center mb-[40px]'>
          <img src={login_main} style={{ width: '297px' }} alt="Pastella Logo" />
        </div>
        
        {/* 아이디와 비밀번호 입력 필드 */}
        <div className="" style={{ width: '297px' }}>
          <div>
            <label htmlFor="username" className="block" style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
              textAlign: 'left'
            }}>아이디</label>
            <input
              type="text"
              id="username"
              className="w-full mt-[10px] px-[14px] py-[18px] rounded-lg border"
              placeholder="아이디를 입력하세요"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{
                color: '#0E111F',
                width: '297px',
                height: '48px',
                borderRadius: '12px'
              }}
            />
          </div>
          <div>
            <label htmlFor="password" className="block mt-3.5" style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
              textAlign: 'left'
            }}>비밀번호</label>
            <input
              type="password"
              id="password"
              className="w-full mt-[10px] px-[14px] py-[18px] rounded-lg border"
              placeholder="비밀번호를 입력하세요"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                color: '#0E111F',
                width: '297px',
                height: '48px',
                borderRadius: '12px'
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
  
        {/* 아이디 저장 체크박스 */}
        <div className="flex items-center mt-[14px]">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMeChange}
            className="w-4 h-4 text-gray-600 border-gray-300 rounded focus:ring-gray-500"
          />
          <label htmlFor="remember-me" className="ml-2" style={{
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 500,
            textAlign: 'left'
          }}>
            아이디 저장
          </label>
        </div>
  
        {/* 로그인 버튼 */}
        <button
          onClick={handleLogin}
          className="w-full py-2 mt-[40px] text-white rounded-md h-[54px]"
          style={{
            background: 'linear-gradient(64.95deg, #61E3EB 2.65%, #3677F6 94.53%)',
          }}
        >
          로그인
        </button>
      </div>
    </div>
  );
}

export default Login;
