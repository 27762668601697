import React, { useState, useEffect } from "react";
import OptionalTextField from "../logincomponents/OptionalTextField";
import BBtn from "../logincomponents/BBtn";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import Title from "../logincomponents/Title";
import FormForm from "../logincomponents/FormForm";
import MustTextField from "../logincomponents/MustTextField";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const SocialLogin = () => {
  const location = useLocation();
  const { name, phoneNumber } = location.state || {};
  const navigate = useNavigate();
  const [recommender, setRecommender] = useState("");
  const [ageConsent, setAgeConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const savedFormData = JSON.parse(sessionStorage.getItem("socialLoginForm"));
    if (savedFormData) {
      setRecommender(savedFormData.recommender || "");
      setAgeConsent(savedFormData.ageConsent || false);
      setMarketingConsent(savedFormData.marketingConsent || false);
    }
  }, []);

  // Save form data to localStorage before navigating to NICE authentication
  const saveFormData = () => {
    const formData = {
      recommender,
      ageConsent,
      marketingConsent,
    };
    sessionStorage.setItem("socialLoginForm", JSON.stringify(formData));
  };
  // Function to format the phone number
  const formatPhoneNumber = (value) => {
    // Remove non-digit characters
    const cleaned = value.replace(/\D/g, "");

    // Check if the cleaned number is 11 digits long
    if (cleaned.length === 11) {
      // Remove the leading zero and add +82
      return `+82${cleaned.substring(1)}`;
    }

    // Default case: just return the cleaned number with +82
    return `+82${cleaned}`;
  };

  const policyStyle = {
    fontSize: "12px",
    lineHeight: "1.5",
    textAlign: "center" /* Center text inside the div */,
    margin: "10px auto" /* Horizontally center within its parent */,
  };

  const updateProfile = async () => {
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    const userId = sessionStorage.getItem("passUserId");
    const data = {
      userid: userId, // replace with your actual userId
      recommender: recommender, // replace with your actual recommender
      marketingConsent: marketingConsent, // replace with actual consent value
      phoneNumber: formattedPhoneNumber, // replace with actual phone number
    };
    console.log(data);

    if (!ageConsent) {
      const toastId = "age-consent-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("만 14세 이상 확인이 필요합니다.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return; // Stop further execution
    }

    try {
      const response = await fetch(`${apiUrl}/auth/complete-profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });

      if (response.ok) {
        navigate("/nickname", {
          state: {
            userId,
          },
        });
      } else if (response.status === 409) {
        // Handle 409 Conflict (e.g., already registered phone number)
        const toastId = "conflict-error"; // Unique ID for the toast
        if (!toast.isActive(toastId)) {
          toast.error("이미 가입된 휴대폰번호입니다.", {
            position: "top-center",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: toastId,
            progress: undefined,
          });
        }
      } else {
        // Handle 409 Conflict (e.g., already registered phone number)
        const toastId = "conflict-error"; // Unique ID for the toast
        if (!toast.isActive(toastId)) {
          toast.error("회원가입에 실패하였습니다.", {
            position: "top-center",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: toastId,
            progress: undefined,
          });
        }
      }
    } catch (error) {
      alert("An error occurred while updating the profile.");
    }
  };
  const handleConditions = () => {
    saveFormData();
    navigate("/conditions");
  };
  const handleTerms = () => {
    saveFormData();
    navigate("/terms");
  };

  const openNiceWindow = async () => {
    saveFormData();
    const { form } = document;
    const res = await axios.get(`https://pastellapp.com/api/auth/nice`);

    if (form && res.data) {
      const { tokenVersionId, encData, integrityValue } = res.data;
      form.enc_data.value = encData;
      form.token_version_id.value = tokenVersionId;
      form.integrity_value.value = integrityValue;
      form.submit();
    }
  };
  return (
    <div className="flex min-h-screen justify-center w-full bg-[#fff] font-sans">
      <div className="relative min-x-[360px] w-full bg-[#fff]">
        <ReactiveOuter>
          <FormTop>
            <Title
              titleGap="40%"
              frameDivWidth="unset"
              prop="회원가입"
              bAlignSelf="unset"
              bHeight="unset"
              bOpacity="unset"
              bMinWidth="101px"
              bDisplay="inline-block"
              to="/welcome"
            />
            <FormForm>
              {name ? (
                <MustTextField
                  prop="이름"
                  prop1="본인인증을 진행해주세요."
                  value={name}
                  style={{ backgroundColor: "#E2E8EF" }}
                />
              ) : (
                <div
                  onClick={openNiceWindow}
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    textAlign: "center",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    margin: "20px auto", // To center horizontally
                    maxWidth: "200px", // Optional, adjust as needed
                  }}
                >
                  본인인증
                </div>
              )}
              {phoneNumber ? (
                <MustTextField
                  prop="휴대폰번호"
                  prop1="본인인증을 진행해주세요."
                  value={phoneNumber}
                  style={{ backgroundColor: "#E2E8EF" }}
                />
              ) : (
                ""
              )}
              <OptionalTextField
                prop="추천인 계정 입력"
                prop1="이름"
                value={recommender}
                onChange={(e) => setRecommender(e.target.value)}
              ></OptionalTextField>
              <div className="flex flex-col gap-4 w-full">
                <div className="flex items-center justify-between">
                  <b className="mr-2">
                    만 14세 이상입니다{" "}
                    <span style={{ color: "var(--color-tomato)" }}>*</span>
                  </b>
                  <input
                    type="checkbox"
                    checked={ageConsent}
                    onChange={(e) => setAgeConsent(e.target.checked)} // Set 만 14세 이상입니다 in state
                  />
                </div>
                <div className="flex items-center justify-between">
                  <b className="mr-2">마케팅 수신 동의(선택)</b>
                  <input
                    type="checkbox"
                    checked={marketingConsent}
                    onChange={(e) => setMarketingConsent(e.target.checked)} // Set 마케팅 수신 동의(선택) in state
                  />
                </div>
              </div>
            </FormForm>
          </FormTop>
        </ReactiveOuter>
        <div className="h-[20vh]"></div>
        <div className="w-full">
          <div style={policyStyle}>
            <a className="underline" onClick={handleConditions}>
              <b>이용 약관</b>
            </a>
            과{" "}
            <a className="underline" onClick={handleTerms}>
              <b>개인정보 처리방침</b>
            </a>
            에 동의시 회원가입을 진행해주세요
          </div>
          <BBtn prop={"회원가입"} onClick={updateProfile} />
        </div>
        <div className="h-[2vh]"></div>
      </div>
      <ToastContainer />
      <form
        name="form"
        id="form"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
    </div>
  );
};

export default SocialLogin;
