import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Mbti2 = () => {
  // State to track selected options for each row
  const [mbti, setMbti] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    row1: sessionStorage.getItem("onMbti")
      ? sessionStorage.getItem("onMbti")[0]
      : null,
    row2: sessionStorage.getItem("onMbti")
      ? sessionStorage.getItem("onMbti")[1]
      : null,
    row3: sessionStorage.getItem("onMbti")
      ? sessionStorage.getItem("onMbti")[2]
      : null,
    row4: sessionStorage.getItem("onMbti")
      ? sessionStorage.getItem("onMbti")[3]
      : null,
  });

  useEffect(() => {
    const values = Object.values(selectedOptions);

    // Combine values into a single string, filtering out null or undefined values
    const combinedString = values
      .filter((value) => value !== null && value !== undefined)
      .join("");

    setMbti(combinedString);
  }, [selectedOptions]);
  // Handle option selection
  const handleSelect = (row, option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [row]: option,
    }));
  };

  // Define the options for each row
  const options = {
    row1: [
      {
        id: "E",
        title: "외향적인",
        description: "많은 사람들 사이에서 에너지를 얻어요",
      },
      {
        id: "I",
        title: "내향적인",
        description: "내면에 깊게 집중하며 에너지를 얻어요",
      },
    ],
    row2: [
      {
        id: "N",
        title: "직관적인",
        description: "아이디어를 중시하고 미래지향적이에요",
      },
      {
        id: "S",
        title: "감각적인",
        description: "실제 경험을 중시하고 현실주의적이에요",
      },
    ],
    row3: [
      {
        id: "F",
        title: "감정 중심",
        description: "상황과 관계를 바탕으로 가치판단을 해요",
      },
      {
        id: "T",
        title: "사고중심",
        description: "사실과 객관을 바탕으로 가치판단을 해요",
      },
    ],
    row4: [
      {
        id: "J",
        title: "판단하는",
        description: "계획성 있게 행동하고 체계적인 환경을 좋아해요",
      },
      {
        id: "P",
        title: "인식하는",
        description: "융통성 있게 행동하고 자율적인 환경을 좋아해요",
      },
    ],
  };

  // Navigation
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, nickname, selectedOption, selectedOption2 } =
    location.state || {};

  const handleNextClick = () => {
    // Check if all options are selected
    if (Object.values(selectedOptions).every((option) => option !== null)) {
      sessionStorage.setItem("onMbti", mbti);
      navigate("/persona", {
        state: {
          userId,
          nickname,
          selectedOption,
          selectedOption2,
          mbti,
        },
      });
    } else {
      const toastId = "mbti-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("모든 옵션을 선택해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
    }
  };

  const handleGoBack = () => {
    sessionStorage.setItem("onMbti", mbti);
    navigate(-1);
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff]">
      <div className="mb-[4vh]"></div>
      {/* Header */}
      <div className="flex items-center justify-between px-[16px] h-[50px] bg-[#fff]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
          onClick={handleGoBack}
        >
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Progress Bar */}
      <div className="w-full h-1 bg-gray-200">
        <div className="w-3/4 h-full bg-blue-500"></div>
      </div>

      {/* Title and Description */}
      <div className="px-[16px] py-[20px] bg-[#fff]">
        <div className="text-[18px] leading-[100%] font-sans font-bold text-[#000] mb-[14px]">
          MBTI를 입력해주세요
        </div>
        <div className="text-[14px] leading-[22px] font-sans text-[#000]">
          설명을 읽고 나와 더 가까운 쪽을 선택하세요
        </div>
      </div>

      {/* MBTI Options */}
      <div className="flex-1 grid grid-cols-2 gap-[16px] p-[16px]">
        {/* Row 1 */}
        {options.row1.map((option) => (
          <div
            key={option.id}
            className={`flex flex-col items-center justify-center gap-[12px] py-[22px] px-0 bg-[#fff] border-[1px] border-solid border-[#d5dce4] rounded-[10px] ${
              selectedOptions.row1 === option.id
                ? "bg-blue-100 border-blue-500"
                : ""
            }`}
            onClick={() => handleSelect("row1", option.id)}
          >
            <div className="text-[58px] leading-[100%] font-sans font-bold text-[#a9b6ca]">
              {option.id}
            </div>
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#666b81]">
              {option.title}
            </div>
            <div className="text-[14px] leading-[22px] font-sans text-[#666b81] text-center">
              {option.description}
            </div>
          </div>
        ))}

        {/* Row 2 */}
        {options.row2.map((option) => (
          <div
            key={option.id}
            className={`flex flex-col items-center justify-center gap-[12px] py-[22px] px-0 bg-[#fff] border-[1px] border-solid border-[#d5dce4] rounded-[10px] ${
              selectedOptions.row2 === option.id
                ? "bg-blue-100 border-blue-500"
                : ""
            }`}
            onClick={() => handleSelect("row2", option.id)}
          >
            <div className="text-[58px] leading-[100%] font-sans font-bold text-[#a9b6ca]">
              {option.id}
            </div>
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#666b81]">
              {option.title}
            </div>
            <div className="text-[14px] leading-[22px] font-sans text-[#666b81] text-center">
              {option.description}
            </div>
          </div>
        ))}

        {/* Row 3 */}
        {options.row3.map((option) => (
          <div
            key={option.id}
            className={`flex flex-col items-center justify-center gap-[12px] py-[22px] px-0 bg-[#fff] border-[1px] border-solid border-[#d5dce4] rounded-[10px] ${
              selectedOptions.row3 === option.id
                ? "bg-blue-100 border-blue-500"
                : ""
            }`}
            onClick={() => handleSelect("row3", option.id)}
          >
            <div className="text-[58px] leading-[100%] font-sans font-bold text-[#a9b6ca]">
              {option.id}
            </div>
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#666b81]">
              {option.title}
            </div>
            <div className="text-[14px] leading-[22px] font-sans text-[#666b81] text-center">
              {option.description}
            </div>
          </div>
        ))}

        {/* Row 4 */}
        {options.row4.map((option) => (
          <div
            key={option.id}
            className={`flex flex-col items-center justify-center gap-[12px] py-[22px] px-0 bg-[#fff] border-[1px] border-solid border-[#d5dce4] rounded-[10px] ${
              selectedOptions.row4 === option.id
                ? "bg-blue-100 border-blue-500"
                : ""
            }`}
            onClick={() => handleSelect("row4", option.id)}
          >
            <div className="text-[58px] leading-[100%] font-sans font-bold text-[#a9b6ca]">
              {option.id}
            </div>
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#666b81]">
              {option.title}
            </div>
            <div className="text-[14px] leading-[22px] font-sans text-[#666b81] text-center">
              {option.description}
            </div>
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={handleNextClick}
        >
          다음
        </button>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default Mbti2;
