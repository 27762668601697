import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../logincomponents/Title";
import TextField from "../logincomponents/PhoneVerification";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import BottomButtons from "../logincomponents/BottomButtons";
import MustTextField from "../logincomponents/MustTextField";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LanguageContext } from "../LanguageContext";

const PW = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { translations, language } = useContext(LanguageContext);
  const foundUserId = sessionStorage.getItem("userIdforP");
  const phoneNumber = location.state?.phoneNumber || "";
  const name = location.state?.name || "";

  const checkId = async () => {
    if (name && phoneNumber) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/find-userid`,
          {
            name: name,
            phoneNumber:
              phoneNumber[0] === "+"
                ? phoneNumber
                : "+82" + phoneNumber.slice(1),
          }
        );
        if (response) {
          console.log("여기", response.data.userid);
          if (response.data.userid === foundUserId) {
            return true;
          } else return false;
        }
      } catch {}
    } else return false;
  };

  const handlePasswordReset = async () => {
    const isUserIdValid = await checkId(); // Wait for the checkId promise to resolve

    if (!isUserIdValid) {
      const toastId = "auth-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("비밀번호 재설정은 회원 본인만 가능합니다.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return null;
    } else if (!name) {
      const toastId = "auth-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("본인인증을 완료해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return null;
    }
    navigate(`/lost-pw2/${encodeURIComponent(foundUserId)}`);
  };

  const openNiceWindow = async () => {
    sessionStorage.setItem("registerwhere", "pw");
    const { form } = document;
    const res = await axios.get(`https://pastellapp.com/api/auth/nice`);

    if (form && res.data) {
      const { tokenVersionId, encData, integrityValue } = res.data;
      form.enc_data.value = encData;
      form.token_version_id.value = tokenVersionId;
      form.integrity_value.value = integrityValue;
      form.submit();
    }
  };

  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop={translations.pw1}
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
          to="/welcome"
        />
        <FormForm>
          <OptionalTextField
            prop={translations.pw2}
            value={foundUserId}
            readOnly={true}
            onChange={() => {}}
            style={{ backgroundColor: "#E2E8EF" }}
            disabled={true}
          />
          {name ? (
            <MustTextField
              prop="이름"
              prop1="본인인증을 진행해주세요."
              value={name}
              style={{ backgroundColor: "#E2E8EF" }}
            />
          ) : (
            <div
              onClick={openNiceWindow}
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "5px",
                cursor: "pointer",
                margin: "20px auto", // To center horizontally
                maxWidth: "200px", // Optional, adjust as needed
              }}
            >
              {translations.pw3}
            </div>
          )}
          {phoneNumber ? (
            <MustTextField
              prop="휴대폰번호"
              prop1="본인인증을 진행해주세요."
              value={phoneNumber}
              style={{ backgroundColor: "#E2E8EF" }}
            />
          ) : (
            ""
          )}
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BottomButtons prop1={translations.pw4} onClick={handlePasswordReset} />
      </div>
      <form
        name="form"
        id="form"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
      <ToastContainer />
    </ReactiveOuter>
  );
};

export default PW;
