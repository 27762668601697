import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../config';
import Navigation from '../../components/admin/Navigation';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';
import categoryIcon from '../../assets/admin/icons/category.png';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { fetchTreeNodes } from '../../api/admin/treeNode';

const LearnExpression = () => {
  const navigate = useNavigate();
  // 상태 정의
  const [data, setData] = useState([]); // 목록 데이터를 저장할 상태
  const [totalItems, setTotalItems] = useState(0); // 전체 항목 수
  const [title, setSearchTerm] = useState(''); // 검색어
  const [filter, setFilter] = useState({ // 필터 상태 초기값 설정
    category: '전체'
  });
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' }); // 정렬 설정
  const [selectedRows, setSelectedRows] = useState([]); // 선택된 행
  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [itemsPerPage, setItemsPerPage] = useState(20); // 페이지 당 항목 수
  const [metaData, setMetaData] = useState(null); // 메타 데이터
  const [treeData, setTreeData] = useState(null);
  const [selected1Depth, setSelected1Depth] = useState(null);
  const [selected2Depth, setSelected2Depth] = useState(null);
  const [selected3Depth, setSelected3Depth] = useState(null);
  const [options2Depth, setOptions2Depth] = useState([]);
  const [options3Depth, setOptions3Depth] = useState([]);
  const [isAcademicDropdownOpen, setIsAcademicDropdownOpen] = useState(false);
  const [isItemLimitDropdownOpen, setIsItemLimitDropdownOpen] = useState(false);
  const [isTag1DepthDropdownOpen, setIsTag1DepthDropdownOpen] = useState(false);
  const [isTag2DepthDropdownOpen, setIsTag2DepthDropdownOpen] = useState(false);
  const [isTag3DepthDropdownOpen, setIsTag3DepthDropdownOpen] = useState(false);
  const [academicFilter, setAcademicFilter] = useState('전체');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupTagNames, setPopupTagNames] = useState([]);
  const [currentTagId, setCurrentTagId] = useState('');

  // 페이지네이션을 위한 기본 데이터 가져오는 함수
  const fetchDefaultPageData = useCallback(async (page, limit) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/expressions/manage`, {
        params: { page, limit },
      });

      setData(response.data.data);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('Failed to fetch default data:', error);
    }
  }, []);


  // 필터를 서버에 적용하는 fetchPageData 함수
  const fetchPageData = useCallback(async (page, limit, tagid = currentTagId, academic = academicFilter) => {
    try {
      const params = {
        page,
        limit,
        title,
        category: filter.category === '전체' ? '' : filter.category,
        tagid,
        academic_english: academic === '전체' ? '' : academic === 'true',
      };

      const response = await axios.get(`${API_BASE_URL}/expressions/managerFilter`, {
        params,
      });

      let sortedData = response.data.data;

      // 정렬 로직 추가
      if (sortConfig.key) {
        sortedData = sortedData.sort((a, b) => {
          if (sortConfig.direction === 'ascending') {
            return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
          } else {
            return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
          }
        });
      }

      setData(sortedData);
      setTotalItems(response.data.total);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  }, [title, filter, sortConfig, academicFilter, currentTagId]);

  // 검색 버튼 클릭 핸들러
  const handleSearch = () => {

    setCurrentPage(0); // 검색 시 페이지를 첫 페이지로 리셋
    fetchPageData(1, itemsPerPage); // 검색 시 필터 조건으로 데이터 가져오기
  };

  // 메타 데이터를 기반으로 한국어를 영어로 변환하는 함수
  const mapToEnglishValue = (category, value) => {
    if (!metaData || !metaData[category]) return value;
    return metaData[category][value] || value;
  };

  // 메타 데이터를 기반으로 영어를 한국어로 변환하는 함수
  const mapToKoreanValue = (category, value) => {
    if (!metaData || !metaData[category]) return value;
    return Object.keys(metaData[category]).find(key => metaData[category][key] === value) || value;
  };

  // 필터 버튼 클릭 시 데이터 초기화 후 서버에서 필터링된 데이터 가져오기
  const handleFilterButtonClick = (filterType, item) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      [filterType]: prevFilter[filterType] === item ? '전체' : item, // 선택된 값이 같으면 전체로 변경
    }));
  };

  // 컴포넌트 마운트 시 초기 데이터 가져오기
  useEffect(() => {
    fetchDefaultPageData(1, itemsPerPage); // 페이지네이션을 위해 초기 페이지를 1로 설정
  }, [fetchDefaultPageData]); // `fetchDefaultPageData`를 종속성 배열에 추가

  // 메타 데이터 가져오기 (초기 로드 시)
  useEffect(() => {
    fetch('/select_kor_eng.json')
      .then(response => response.json())
      .then(data => setMetaData(data)) // 메타 데이터 설정
      .catch(error => console.error('Error fetching metaData:', error));
  }, []);


  // 페이지 클릭 핸들러 (페이지 변경 시)
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    fetchPageData(data.selected + 1, itemsPerPage, currentTagId, academicFilter);
  };

  // 정렬 핸들러 (정렬 기준 및 방향 설정)
  const handleSort = (key) => {
    let direction = 'ascending'; // 기본 정렬 방향
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'; // 동일 키 클릭 시 방향 반전
    }
    setSortConfig({ key, direction }); // 정렬 설정 업데트
    
    // 정렬된 데이터를 다시 가져오기 위해 fetchPageData 호출
    fetchPageData(1, itemsPerPage); // 정렬 후 첫 페이지를 가져옵니다.
  };

  // 행 선택 핸들러 (체크박스 선택)
  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id) // 이미 선택된 경우 제거
        : [...prevSelected, id] // 선택된 경우 추가
    );
  };

  // 삭제 핸들러
  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      alert('삭제할 항목을 선택해주세요.');
      return;
    }
  
    const isConfirmed = window.confirm(`선택한 ${selectedRows.length}개의 항목을 삭제하시겠습니까?`);
    
    if (isConfirmed) {
      try {
        // 각 컴포넌트에 맞는 API 엔드포인트 사용
        await Promise.all(selectedRows.map(id => axios.delete(`${API_BASE_URL}/expressions/manage/${id}`)));
        setData((prevData) => prevData.filter((item) => !selectedRows.includes(item.id)));
        setSelectedRows([]);
        alert('선택한 항목이 성공적으로 삭제되었습니다.');
      } catch (error) {
        console.error('Error deleting items:', error);
        alert('항목 삭제 중 오류가 발생했습니다.');
      }
    } else {
      console.log('삭제가 취소되었습니다.');
    }
  };

  // 모든 행 선택/해제 핸들러
  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]); // 모든 선택 해제
    } else {
      setSelectedRows(data.map(item => item.id)); // 모든 행 선택
    }
  };

  // 스크립트 생성 핸들러
  const handleCreateScript = async () => {

    try {
      const manageData = {
        title: '',
        category: '',
        academic_english: false,
        memo: '',
        link: '',
        used: false,
        tag: []
      };
  
      // `managerId`를 state로 전달하며 `CustomizeManage` 페이지로 이동
      navigate(`/expression/manage/create`);
    } catch (error) {
      console.error('Failed to create manager:', error);
    }
  };

  // 스크립트 수정 핸들러
  const handleEditScript = (managerId) => {
    navigate(`/expression/manage/edit/${managerId}`); // edit 모드로 이동
  };

  // Tree Data 로드
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchTreeNodes(4); // 'Expression'에 해당하는 ID 4를 사용
      setTreeData(data);
    };
    fetchData();
  }, []);

  const getNodeLabel = (node) => {
    const koTranslation = node.translations.find((t) => t.language === "kor");
    return koTranslation?.name || node.name;
  };

  const handle1DepthChange = (option) => {
    setSelected1Depth(option);
    setSelected2Depth(null);
    setSelected3Depth(null);
    setOptions2Depth(option.children || []);
    setOptions3Depth([]);
  };

  const handle2DepthChange = (option) => {
    setSelected2Depth(option);
    setSelected3Depth(null);
    setOptions3Depth(option.children || []);
  };

  const handle3DepthChange = (option) => {
    setSelected3Depth(option);
    setCurrentTagId(option.id);
    fetchPageData(1, itemsPerPage, option.id, academicFilter);
  };

  const handleAcademicFilterChange = (value) => {
    setAcademicFilter(value);
    fetchPageData(1, itemsPerPage, currentTagId, value);
  };

  const handleItemLimitChange = (value) => {
    setItemsPerPage(value);
    fetchPageData(1, value, currentTagId, academicFilter);
  };

  const findNodeById = (tree, id) => {
    if (tree.id === id) return tree;
    if (tree.children) {
      for (let child of tree.children) {
        const result = findNodeById(child, id);
        if (result) return result;
      }
    }
    return null;
  };

  const findParentNode = (data, id) => {
    for (let node of data.children || []) {
      if (node.children?.some(child => child.id === id)) {
        return node;
      }
      const found = findParentNode(node, id);
      if (found) return found;
    }
    return null;
  };

  const findMaxDepth = (paths) => {
    return Math.max(...paths.map(path => path.split(' > ').length));
  };

  const filterByMaxDepth = (paths) => {
    const maxDepth = findMaxDepth(paths);
    return paths.filter(path => path.split(' > ').length === maxDepth);
  };

  const buildSelectionPath = (selections, data) => {
    const paths = [];
    selections.forEach(id => {
      const path = [];
      let currentNode = findNodeById(data, id);
      while (currentNode && currentNode.id !== 4) { // 루트 노드(id: 4)에 도달할 때까지 반복
        path.unshift(getNodeLabel(currentNode));
        currentNode = findParentNode(data, currentNode.id);
      }
      if (path.length > 0) {
        paths.push(path.join(' > '));
      }
    });
    return filterByMaxDepth(paths);
  };

  const handleCategoryClick = (item) => {
    if (Array.isArray(item) && item.length > 0 && treeData) {
      const idArray = item.map(obj => obj.id);
      const tagNames = buildSelectionPath(idArray, treeData);
      setPopupTagNames(tagNames);
      setIsPopupOpen(true);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  if (!metaData) {
    return <div>Loading...</div>; // 메타 데이터 로딩 중
  }

  // 기능 구현 할 예정이라는 알림창
  const handleAlert = () => {
    alert('기능 구현 중입니다.');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const dropdownStyle = {
    width: '160px',
    height: '36px',
    padding: '18px 8px 18px 14px',
    borderRadius: '12px',
    border: '1px solid #E2E8EF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#E2E8EF',
  };

  const selectedDropdownStyle = {
    ...dropdownStyle,
    color: 'black',
    borderColor: '#E3EDFF',
  };

  return (
  <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
    <Navigation />
    <div 
      style={{
        padding: '26px 30px', // 위아래 26px, 좌우 30px 여백을 줍니다.
      }}
    >
      {/* 제목 표시 */}
      <h1 
        className="font-bold uppercase"
        style={{
          width: '140px',
          height: '28px',
          fontFamily: 'Pretendard',
          fontSize: '24px',
          fontWeight: 700,
          textAlign: 'left',
        }}
      >
        EXPRESSION
      </h1>

      {/* 검색 및 필터 영역 */}
      <div 
        className="bg-white p-5 rounded-lg shadow-md"
        style={{
          margin: '26px 0px 20px 0', // 위아래 26px, 좌우 30px 여백을 줍니다.
        }}
      >
        
        {/* 타이틀 검색 섹션 */}
        <div>
          <label 
            className="block"
            style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
              textAlign: 'left',
            }}
          >
            타이틀 검색
          </label>
          <div className='flex items-center space-x-3 mt-2.5'>
            {/* 검색어 입력 필드 */}
            <input
              type="text"
              placeholder="검색어를 입력하세요"
              value={title}
              onChange={(e) => setSearchTerm(e.target.value)} // 입력 변경 시 상태 업데이트
              className="border flex-grow h-[48px]"
              style={{
                padding: '18px 14px',
                borderRadius: '12px',
                border: '1px solid #E2E8EF',
              }}
              onKeyDown={handleKeyDown}
            />
            {/* 검색 버튼 */}
            <button 
              onClick={handleSearch} 
              className="w-[100px] h-[50px] bg-[#3677F6] text-white ml-2"
              style={{
                padding: '18px 14px',
                borderRadius: '12px',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '14px',
              }}
            >
              검색어 검색
            </button>
          </div>
        </div>

        {/* 필터 검색 섹션 */}
        <div className="mt-4">
          <div className="space-y-4">

            {/* 표현 필터 */}
            <div>
              <label 
                className="block mb-3.5"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '14px',
                  textAlign: 'left',
                }}
              >
                표현
              </label>
              <div className="relative flex flex-wrap w-full">
                <div className="flex flex-wrap flex-grow">
                {Object.keys(metaData.expression).map((item) => (
                  <button
                    key={item}
                    onClick={() => {
                      const englishValue = mapToEnglishValue('expression', item); // 한국어 -> 영어 변환
                      handleFilterButtonClick('category', englishValue); // 변환된 영어 값을 필터에 적용
                    }}
                    className={`m-1 px-4 py-2 rounded-full border ${
                      filter.category === mapToEnglishValue('expression', item) // 현재 필터 값과 비교
                        ? 'bg-[#E3EDFF] text-[#3677F6] border-[#3677F6]'
                        : 'bg-white border-[#E2E8EF] text-black'
                    }`}
                    style={{
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 500,
                      lineHeight: '14px',
                    }}
                  >
                    {item} {/* 한국어 표현 표시 */}
                  </button>
                ))}
                  <div 
                    className="absolute right-0"
                    style={{ top: '-10px' }}
                  >
                    {/* 검색 버튼, 필터 적용 시 검색 */}
                    <button 
                      onClick={handleSearch} 
                      className="w-[100px] h-[50px] bg-[#3677F6] text-white"
                      style={{
                        padding: '18px 14px',
                        borderRadius: '12px',
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '14px',
                      }}
                    >
                      필터 검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 목록 테이블 섹션 */}
      <div className="bg-white rounded-lg shadow-md">
        {/* 목록 헤더 */}
        <div className="flex justify-between items-center">
          
          {/* 검색 결과 및 선택 항목 수 표시 */}
          <div className="text-lg mt-5 mb-7 ml-5">
            <label 
              className="text-xl font-bold text-[#0E111F] mr-5"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontWeight: 700,
                textAlign: 'left',
              }}
            >
              목록
            </label>
            <label 
              className="text-sm font-medium text-[#0E111F]"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                textAlign: 'left',
              }}
            >
              검색결과 {totalItems}개 | 선택 {selectedRows.length}개 | 현재 페이지 {currentPage + 1 }
            </label>
          </div>
          {/* 액션 버튼들 */}
          <div className="flex space-x-2 mr-5 items-center">
            {/* 1뎁스 태그 선택 드롭다운 */}
            <div className="relative">
              <div
                style={selected1Depth ? selectedDropdownStyle : dropdownStyle}
                onClick={() => setIsTag1DepthDropdownOpen(!isTag1DepthDropdownOpen)}
              >
                <span>{selected1Depth ? getNodeLabel(selected1Depth) : "1depth"}</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isTag1DepthDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  {treeData?.children.map((option1) => (
                    <div
                      key={option1.id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        handle1DepthChange(option1);
                        setIsTag1DepthDropdownOpen(false);
                      }}
                    >
                      {getNodeLabel(option1)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* 2뎁스 태그 선택 드롭다운 */}
            <div className="relative">
              <div
                style={selected2Depth ? selectedDropdownStyle : dropdownStyle}
                onClick={() => setIsTag2DepthDropdownOpen(!isTag2DepthDropdownOpen)}
              >
                <span>{selected2Depth ? getNodeLabel(selected2Depth) : "2depth"}</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isTag2DepthDropdownOpen && selected1Depth && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  {options2Depth.map((option2) => (
                    <div
                      key={option2.id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        handle2DepthChange(option2);
                        setIsTag2DepthDropdownOpen(false);
                      }}
                    >
                      {getNodeLabel(option2)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* 3뎁스 태그 선택 드롭다운 */}
            <div className="relative">
              <div
                style={selected3Depth ? selectedDropdownStyle : dropdownStyle}
                onClick={() => setIsTag3DepthDropdownOpen(!isTag3DepthDropdownOpen)}
              >
                <span>{selected3Depth ? getNodeLabel(selected3Depth) : "3depth"}</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isTag3DepthDropdownOpen && selected2Depth && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  <div 
                    className="p-2 hover:bg-gray-100 cursor-pointer" 
                    onClick={() => {
                      // 모든 뎁스 초기화
                      setSelected1Depth(null);
                      setSelected2Depth(null);
                      setSelected3Depth(null);
                      // 2뎁스, 3뎁스 옵션 초기화
                      setOptions2Depth([]);
                      setOptions3Depth([]);
                      // 현재 태그 ID 초기화
                      setCurrentTagId('');
                      // 데이터 새로고침
                      fetchPageData(1, itemsPerPage);
                      // 드롭다운 닫기
                      setIsTag3DepthDropdownOpen(false);
                    }}
                  >
                    선택 취소
                  </div>
                  {options3Depth.map((option3) => (
                    <div
                      key={option3.id}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        handle3DepthChange(option3);
                        setIsTag3DepthDropdownOpen(false);
                      }}
                    >
                      {getNodeLabel(option3)}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Academic 필터 */}
            <div className="relative">
              <div
                style={academicFilter !== '전체' ? selectedDropdownStyle : dropdownStyle}
                onClick={() => setIsAcademicDropdownOpen(!isAcademicDropdownOpen)}
              >
                <span>{academicFilter === 'true' ? 'Academic' : academicFilter === 'false' ? 'Non-Academic' : 'Academic'}</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isAcademicDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  <div className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => {handleAcademicFilterChange('전체'); setIsAcademicDropdownOpen(false);}}>전체</div>
                  <div className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => {handleAcademicFilterChange('true'); setIsAcademicDropdownOpen(false);}}>Academic</div>
                  <div className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => {handleAcademicFilterChange('false'); setIsAcademicDropdownOpen(false);}}>Non-Academic</div>
                </div>
              )}
            </div>

            {/* 항목 수 선택 */}
            <div className="relative">
              <div
                style={selectedDropdownStyle}
                onClick={() => setIsItemLimitDropdownOpen(!isItemLimitDropdownOpen)}
              >
                <span>{`${itemsPerPage}개씩 보기`}</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
              </div>
              {isItemLimitDropdownOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                  {[5, 10, 20].map((limit) => (
                    <div
                      key={limit}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        handleItemLimitChange(limit);
                        setIsItemLimitDropdownOpen(false);
                      }}
                    >
                      {`${limit}개씩 보기`}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <button 
              onClick={handleDelete} 
              className="p-2 rounded-md text-[#383C4B]"
              style={{
                width: '100px',
                height: '36px',
                borderRadius: '12px',
                background: '#C5D0DD',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
              }}
            >
              선택 삭제
            </button>
            <button 
              onClick={handleCreateScript} 
              className="p-2 rounded-md text-[#3677F6]"
              style={{
                width: '100px',
                height: '36px',
                borderRadius: '12px',
                background: '#E3EDFF',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
              }}
            >
              표현언어 생성
            </button>
          </div>
        </div>

        {/* 데이터 테이블 */}
        <table className="min-w-full divide-y divide-gray-200 shadow-md">
          {/* 테이블 헤더 */}
          <thead 
            className="bg-[#E2E8EF]"
            style={{
              height: '40px',
              border: '0px 1px 0px 1px',
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 500,
              color: '#666B81',
            }}
          >
            <tr className='align-middle'>
              <th className='text-center w-16'>
                {/* 모든 항목 선택/해제 체크박스 */}
                <input 
                  type="checkbox" 
                  onChange={handleSelectAll} 
                  checked={selectedRows.length === data.length} // 모든 항목이 선택되었는지 확인
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                />
              </th>
              {/* 테이블 컬럼 헤더 - 클릭 시 정렬 기능 제공 */}
              <th 
                className="text-center font-medium text-gray-500 tracking-wider w-16"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#666B81',
                }}
              >
                No.
              </th>
              <th 
                onClick={() => handleSort('title')} 
                className="text-left font-medium text-gray-500 tracking-wider cursor-pointer w-109"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#666B81',
                }}
              >
                제목<img 
                    src={handleSortIcon} 
                    alt="Sort Icon" 
                    className="inline-block ml-1"
                    style={{ width: '22px', height: '22px' }}
                   />
              </th>
              <th 
                className="text-center font-medium text-gray-500 tracking-wider w-57"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#666B81',
                }}
              >
                표현
              </th>
              <th 
                className="text-center font-medium text-gray-500 tracking-wider w-57"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#666B81',
                }}
              >
                Academic
              </th>
              <th 
                className="text-center font-medium text-gray-500 tracking-wider w-57"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#666B81',
                }}
              >
                출력여부
              </th>
              <th 
                onClick={() => handleSort('createdAt')} 
                className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-57"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#666B81',
                }}
              >
                생성일자<img 
                        src={handleSortIcon} 
                        alt="Sort Icon" 
                        className="inline-block ml-1"
                        style={{ width: '22px', height: '22px' }}
                      />
              </th>
              <th 
                onClick={() => handleSort('lastmodified')} 
                className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-57"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#666B81',
                }}
              >
                마지막 수정일<img 
                            src={handleSortIcon} 
                            alt="Sort Icon" 
                            className="inline-block ml-1"
                            style={{ width: '22px', height: '22px' }}
                          />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider w-16 pr-2">
                카테고리
              </th>
            </tr>
          </thead>

          {/* 테이블 본문 */}
          <tbody 
            className="bg-white divide-y divide-gray-200"
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 500,
              color: '#000000',
            }}
          >
            {data.map((item, index) => (
              <tr key={item.id} className='hover:bg-gray-100 transition duration-150 ease-in-out h-[40px]'>
                <td className="whitespace-no-wrap text-center w-16">
                  {/* 각 항목 선택 체크박스 */}
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(item.id)} // 선택 여부에 따라 체크
                    onChange={(e) => {
                      e.stopPropagation(); // 클릭 이벤트 전파 중지
                      handleSelectRow(item.id); // 선택된 항목 업데이트
                    }}
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  />
                </td>
                {/* 테이블 데이터 셀 */}
                <td className="px-6 py-4 whitespace-no-wrap">
                  {index + 1}
                </td>
                <td className="whitespace-no-wrap cursor-pointer" onClick={() => handleEditScript(item.id)}>
                  {item.title}
                </td>
                <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                  {mapToKoreanValue('expression', item.category)}
                </td>
                <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                  {item.academic_english ? 'YES' : 'NO'}
                </td>
                <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                  {item.used ? 'YES' : 'NO'}
                </td>
                <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                  {new Date(item.createdAt).toLocaleDateString()} ({new Date(item.createdAt).toLocaleTimeString()})
                </td>
                <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleEditScript(item.id)}>
                  {new Date(item.lastmodified).toLocaleDateString()} ({new Date(item.lastmodified).toLocaleTimeString()})
                </td>
                <td className="whitespace-no-wrap text-center cursor-pointer">
                  {Array.isArray(item.tag) && item.tag.length > 0 && (
                    <img 
                      src={categoryIcon} 
                      alt="Category Icon" 
                      onClick={() => handleCategoryClick(item.tag)} 
                      style={{ cursor: 'pointer', width: '66px', height: '18px' }} 
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* 페이지네이션 컴포넌트 */}
      <div className="flex justify-center mt-4">
        <ReactPaginate
          previousLabel={
            <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
          }
          nextLabel={
            <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
          }
          breakLabel={'...'} // 페이지 구분자
          pageCount={Math.ceil(totalItems / itemsPerPage)} // 총 페이지 수 계산
          marginPagesDisplayed={2} // 양 끝에 보여줄 페이지 수
          pageRangeDisplayed={5} // 현재 페이지 근처에 보여줄 페이지 수
          onPageChange={handlePageClick} // 페이지 변경 시 호출할 핸들러
          containerClassName={'pagination flex space-x-2 items-center'} // 페이지네이션 컨테이너 스타일
          activeClassName={'text-[#3677F6] font-bold'} // 현재 선택된 페이지 스타일
          breakClassName={'text-[#A9B6CA]'} // 구분자 스타일
          disabledClassName={'text-[#C5D0DD] cursor-not-allowed'} // 비활성화 스타일
        />
        </div>
    </div>

    {/* 팝업창 */}
    {isPopupOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleClosePopup}>
        <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 p-6" onClick={(e) => e.stopPropagation()}>
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-bold">카테고리</h3>
            <button className="text-gray-500 hover:text-gray-700" onClick={handleClosePopup}>×</button>
          </div>
          <ul className="mt-4 space-y-2">
            {popupTagNames.map((path, index) => (
              <li 
                key={index} 
                className="text-blue-600 font-semibold rounded-full border border-blue-600 px-3 py-1 inline-block mr-2 mb-2"
                style={{
                  fontFamily: 'Pretendard Variable',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '14px',
                }}
              >
                {path}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )}
  </div>
  );
};

export default LearnExpression;