import axios from 'axios';
import API_BASE_URL from '../../config';

// 특정 id Tree 조회
export const fetchTreeNodes = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/tree-nodes/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching tree nodes:', error);
        throw error;
    }
};

// 모든 Tree 조회
export const fetchAllTreeNodes = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/tree-nodes`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all tree nodes:', error);
        throw error;
    }
};

// Tree 생성
export const createTreeNode = async (translations, parentId) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/tree-nodes`, {
            translations,
            parentId
        });
        return response.data;
    } catch (error) {
        console.error('트리 노드 생성 중 오류:', error);
        throw error;
    }
};

// Tree 수정
export const updateTreeNode = async (id, translations) => {
    try {
        const response = await axios.patch(`${API_BASE_URL}/tree-nodes/${id}`, {
            translations
        });
        return response.data;
    } catch (error) {
        console.error('��리 노드 수정 중 오류:', error);
        throw error;
    }
};

// Tree 삭제
export const deleteTreeNode = async (id) => {
    try {
        await axios.delete(`${API_BASE_URL}/tree-nodes/${id}`);
    } catch (error) {
        console.error('Error deleting tree node:', error);
        throw error;
    }
};

// 트리 노드 데이터 가져오기
export const getTreeNodeData = async (treeId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/tree-nodes/${treeId}`);
        return response.data;
    } catch (error) {
        console.error('트리 노드 데이터를 가져오는 데 실패했습니다:', error);
        throw error;
    }
};
