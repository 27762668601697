import React, { useContext } from "react";
import { LanguageContext } from "./LanguageContext";

const FontSelectionModal = ({ isVisible, onClose, onSelectFont }) => {
  const { translations, language } = useContext(LanguageContext);
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-[#383c4b] p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
        <h2 className="text-lg font-bold text-[#25272f] dark:text-white mb-4">
          {translations.font1}
        </h2>
        <ul className="space-y-3">
          <li>
            <button
              className="w-full p-3 rounded-lg bg-[#f5f5f5] dark:bg-[#2a2c34] text-[#25272f] dark:text-white"
              onClick={() => onSelectFont("'기본', sans-serif")}
            >
              {translations.font2}
            </button>
          </li>
          <li>
            <button
              className="w-full p-3 rounded-lg bg-[#f5f5f5] dark:bg-[#2a2c34] text-[#25272f] dark:text-white"
              onClick={() => onSelectFont("'나눔손글씨 무궁화', sans-serif")}
            >
              {translations.font3}
            </button>
          </li>
          <li>
            <button
              className="w-full p-3 rounded-lg bg-[#f5f5f5] dark:bg-[#2a2c34] text-[#25272f] dark:text-white"
              onClick={() => onSelectFont("'나눔손글씨 금은보화', sans-serif")}
            >
              {translations.font4}
            </button>
          </li>
          <li>
            <button
              className="w-full p-3 rounded-lg bg-[#f5f5f5] dark:bg-[#2a2c34] text-[#25272f] dark:text-white"
              onClick={() => onSelectFont("'나눔손글씨 또박또박', sans-serif")}
            >
              {translations.font5}
            </button>
          </li>
        </ul>
        <div className="flex justify-end mt-4">
          <button
            className="text-blue-600 dark:text-blue-400 font-medium"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FontSelectionModal;
