import React from 'react';
import trashIcon from '../../assets/admin/icons/trash.png';

const LanguageSelector = ({ 
  languages = [], 
  selectedLanguage, 
  selectedTitle, 
  onLanguageChange, 
  onTitleChange, 
  onLanguageReset,
  onAddLanguage,
  visibleLanguages
}) => {
  
  // language 값에 따라 index를 계산하는 함수
  const getLanguageIndex = (language) => {
    switch (language) {
      case 'kor':
        return 1;
      case 'eng':
        return 2;
      case 'jpn':
        return 3;
      default:
        return ''; // 혹은 원하는 기본값
    }
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    onLanguageChange(newLanguage);
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    onTitleChange(newTitle);
  };

  const handleLanguageReset = () => {
    onLanguageReset(selectedLanguage);
  };

  return (
    <div className="mb-4 relative">
      {/* 구분 1, 2, 3... */}
      <h3
        className="text-left mb-4"
        style={{
          fontFamily: 'Pretendard',
          fontSize: '18px',
          fontWeight: 700,
        }}
      >
        구분 {getLanguageIndex(selectedLanguage)}
      </h3>

      <div className="flex items-center space-x-4">
        {/* Language Section */}
        <div>
          <label
            className="block text-left mb-2.5"
            style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            Language
          </label>
          <select
            name="language"
            value={selectedLanguage || ''}
            onChange={handleLanguageChange}
            className="border rounded-md"
            style={{
              width: '119px',
              height: '48px',
              padding: '4px 14px',
              borderRadius: '12px',
              border: '1px solid #E2E8EF',
            }}
            disabled
          >
            {languages.map((language) => (
              <option key={language} value={language}>
                {language.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        {/* Title Section */}
        <div className="flex-grow">
          <label
            className="block text-left mb-2.5"
            style={{
              fontFamily: 'Pretendard Variable',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            제목
          </label>
          <input
            type="text"
            name="title"
            placeholder="Enter Connect Title"
            value={selectedTitle || ''}
            onChange={handleTitleChange}
            className="border p-2 rounded-md w-full"
            style={{
              height: '48px',
              padding: '18px 14px',
              borderRadius: '12px',
              border: '1px solid #E2E8EF',
            }}
          />
        </div>
      </div>

      {/* Trash Icon Button */}
      <button
        onClick={handleLanguageReset}
        className="absolute right-0 top-0 mt-4 mr-4"
        style={{
          width: '24px',
          height: '24px',
          cursor: 'pointer',
        }}
        disabled={!selectedLanguage}
      >
        <img src={trashIcon} alt="Delete" className="w-6 h-6" />
      </button>
    </div>
  );
};

export default LanguageSelector;