// actions.js
export const ADD_ITEMS = "ADD_ITEMS";
export const SET_ITEMS = "SET_ITEMS";
export const SET_USER_ID = "SET_USER_ID";
export const CLEAR_USER_ID = "CLEAR_USER_ID";
export const SET_KEYWORDS = "SET_KEYWORDS";
export const CLEAR_KEYWORDS = "CLEAR_KEYWORDS";
export const SET_LIS = "SET_LIS";
export const CLEAR_LIS = "CLEAR_LIS";
export const SET_TYPE = "SET_TYPE";
export const SET_MANAGEID = "SET_MANAGEID";
export const SET_IMAGEURL = "SET_IMAGEURL";
export const SET_EXID = "SET_EXID";
export const SET_SCRIPT = "SET_SCRIPT";
export const SET_ENGTITLE = "SET_ENGTITLE";
export const SET_KORTITLE = "SET_KORTITLE";
export const SET_WHERE = "SET_WHERE";
export const SET_USERINFO = "SET_USERINFO";

export const addItems = (items) => ({
  type: ADD_ITEMS,
  payload: items,
});

export const setItems = (items) => ({
  type: SET_ITEMS,
  payload: items,
});

// Action creators for userId
export const setUserId = (userId) => ({
  type: SET_USER_ID,
  payload: userId,
});

export const clearUserId = () => ({
  type: CLEAR_USER_ID,
});

// Action creators for keywords
export const setKeywords = (keywords) => ({
  type: SET_KEYWORDS,
  payload: keywords,
});

export const clearKeywords = () => ({
  type: CLEAR_KEYWORDS,
});

// Action creators for lis
export const setLis = (lis) => ({
  type: SET_LIS,
  payload: lis,
});

export const clearLis = () => ({
  type: CLEAR_LIS,
});

// Action creators for type
export const setType = (type) => ({
  type: SET_TYPE,
  payload: type,
});
export const setManageID = (manageId) => ({
  type: SET_MANAGEID,
  payload: manageId,
});
export const setImageUrl = (imageUrl) => ({
  type: SET_IMAGEURL,
  payload: imageUrl,
});
export const setExId = (exId) => ({
  type: SET_EXID,
  payload: exId,
});
export const setScript = (script) => ({
  type: SET_SCRIPT,
  payload: script,
});
export const setEngTitle = (engTitle) => ({
  type: SET_ENGTITLE,
  payload: engTitle,
});
export const setKorTitle = (korTitle) => ({
  type: SET_KORTITLE,
  payload: korTitle,
});
export const setWhere = (where) => ({
  type: SET_WHERE,
  payload: where,
});
export const setUserInfo = (userInfo) => ({
  type: SET_USERINFO,
  payload: userInfo,
});
