import React, { useEffect, useRef } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ requiredPermission }) => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const alertShown = useRef(false);

  useEffect(() => {
    return () => {
      alertShown.current = false;
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/admin" state={{ from: location }} replace />;
  }

  if (requiredPermission && (!user.menus || !user.menus[0] || !user.menus[0][requiredPermission])) {
    if (!alertShown.current) {
      alert('접속 권한이 없습니다.');
      alertShown.current = true;
    }
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
