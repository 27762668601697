import axios from 'axios';

export const generateChatResponse = async (prompt) => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const response = await fetch(`${apiUrl}/api/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt }),
    });
    
    if (!response.ok) {
      throw new Error('API 요청 실패');
    }
    
    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error('채팅 응답 생성 중 오류:', error);
    throw error;
  }
};

export default axios;
