import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import kor_eng from "../data/select_kor_eng.json";

const Persona = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selected, setSelected] = useState({
    gender: sessionStorage.getItem("onPersona1")
      ? sessionStorage.getItem("onPersona1")
      : null,
    age: sessionStorage.getItem("onPersona2")
      ? sessionStorage.getItem("onPersona2")
      : null,
    occupation: sessionStorage.getItem("onPersona3")
      ? sessionStorage.getItem("onPersona3")
      : null,
    relationshipStatus: sessionStorage.getItem("onPersona4")
      ? sessionStorage.getItem("onPersona4")
      : null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { userId, nickname, selectedOption, selectedOption2, mbti } =
    location.state || {};

  const updateProfile = async () => {
    sessionStorage.setItem("isNew", "true");
    const data = {
      userid: userId ? userId : "3670410335",
      nickname: nickname,
      nativeLanguage: selectedOption === "English" ? "ENG" : "KOR",
      targetLanguage: selectedOption2 === "English" ? "ENG" : "KOR",
      mbti: mbti,
      sex:
        selected.gender === "남성"
          ? "MALE"
          : selected.gender === "여성"
          ? "FEMALE"
          : "NOT_SPECIFIED",
      age:
        selected.age === "10대"
          ? "TEENS"
          : selected.age === "20대"
          ? "20S"
          : selected.age === "30대"
          ? "30S"
          : selected.age === "40대"
          ? "40S"
          : selected.age === "50대 이상"
          ? "50_AND_ABOVE"
          : "NOT_SPECIFIED",
      job:
        selected.occupation === "학생"
          ? "STUDENT"
          : selected.occupation === "직장인"
          ? "EMPLOYEE"
          : selected.occupation === "프리랜서"
          ? "FREELANCER"
          : selected.occupation === "기타"
          ? "OTHER"
          : "NOT_SPECIFIED",
      love:
        selected.relationshipStatus === "싱글"
          ? "SINGLE"
          : selected.relationshipStatus === "썸"
          ? "DATING"
          : selected.relationshipStatus === "연애"
          ? "IN_A_RELATIONSHIP"
          : selected.relationshipStatus === "결혼"
          ? "MARRIED"
          : selected.relationshipStatus === "기타"
          ? "OTHER"
          : "NOT_SPECIFIED",
    };

    try {
      const response = await fetch(`${apiUrl}/auth/complete-onboarding`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });

      if (response.ok) {
        const result = await response.json();

        navigate("/home");
      } else {
        // alert("Failed to update profile.");
      }
    } catch (error) {
      alert("An error occurred while updating the profile.");
    }
  };

  const updateVisit = async () => {
    try {
      const response = await fetch(`${apiUrl}/auth/log-visit`, {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });
      if (response.ok) {
      } else {
      }
    } catch {}
  };

  const handleSelect = (category, value) => {
    setSelected((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const handleStart = () => {
    const allSelected = Object.values(selected).every(
      (value) => value !== null
    );
    if (allSelected) {
      updateProfile();
    } else {
      const toastId = "persona-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("모든 옵션을 선택해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
    }
  };

  const handleGoBack = () => {
    sessionStorage.setItem("onPersona1", selected.gender);
    sessionStorage.setItem("onPersona2", selected.age);
    sessionStorage.setItem("onPersona3", selected.occupation);
    sessionStorage.setItem("onPersona4", selected.relationshipStatus);
    navigate(-1);
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] overflow-hidden">
      <div className="mb-[4vh]"></div>
      {/* Header */}
      <div className="flex items-center justify-between px-[16px] h-[50px] bg-[#fff]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
          onClick={handleGoBack}
        >
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Progress Bar */}
      <div className="w-full h-1 bg-gray-200">
        <div className="w-4/4 h-full bg-blue-500"></div>
      </div>

      {/* Content */}
      <div className="flex-1 px-[16px] py-[20px] pb-[20px] flex flex-col gap-[14px]">
        {/* Title and Description */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[18px] leading-[100%] font-sans font-bold text-[#000]">
            Persona 프로필을 설정해주세요
          </div>
          <div className="text-[14px] leading-[22px] font-sans text-[#000]">
            제공해주신 프로필 정보는
            <br />
            보다 정확한 학습 컨텐츠 추천에 사용됩니다
          </div>
        </div>

        {/* Gender */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000]">
            성별
          </div>
          <div className="flex gap-[6px]">
            {["여성", "남성", "무관"].map((gender) => (
              <div
                key={gender}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selected.gender === gender
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("gender", gender)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {gender}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Age Group */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000]">
            연령대
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {["10대", "20대", "30대", "40대", "50대 이상", "무관"].map(
              (age) => (
                <div
                  key={age}
                  className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                    selected.age === age
                      ? "border-[#3677f6] text-[#3677f6]"
                      : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                  }`}
                  onClick={() => {
                    handleSelect("age", age);
                  }}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                    {age}
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {/* Occupation */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000]">
            직업
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {["학생", "직장인", "프리랜서", "기타", "무관"].map((job) => (
              <div
                key={job}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selected.occupation === job
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("occupation", job)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {job}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Relationship Status */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000]">
            연애상태
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {["싱글", "썸", "연애", "결혼", "무관"].map((status) => (
              <div
                key={status}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selected.relationshipStatus === status
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("relationshipStatus", status)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {status}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={handleStart}
        >
          시작하기
        </button>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default Persona;
