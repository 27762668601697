import styles from "./ReactiveOuter.module.css";

const ReactiveOuter = ({ children, darkMode }) => {
  return (
    <div className={`${styles.pw} ${darkMode ? "bg-[#121418]" : ""}`}>
      {children}
    </div>
  );
};

export default ReactiveOuter;
