import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../logincomponents/Logo";
import styles from "./Splash.module.css";
import { setUserId, clearUserId } from "../actions";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { LanguageContext } from "../LanguageContext";

const Splash = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { switchLanguage } = useContext(LanguageContext);

  function decodeToken(token) {
    try {
      const decoded = jwt_decode(token);

      setUserId(decoded.userid);
      switchLanguage(decoded.nativeLanguage.toLowerCase());
      sessionStorage.setItem("native", decoded.nativeLanguage.toLowerCase());
      sessionStorage.setItem("target", decoded.targetLanguage.toLowerCase());

      return decoded;
    } catch (error) {
      return null;
    }
  }

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  useEffect(() => {
    const handleNavigation = async () => {
      await Promise.all([refreshAccessToken(), delay(2000)]);
      if (localStorage.getItem("refreshToken")) {
        try {
          const response = await fetch(`${apiUrl}/auth/log-visit`, {
            method: "GET", // or 'POST' depending on your API
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
              "Content-Type": "application/json", // Include other headers if needed
            },
          });
          decodeToken(sessionStorage.getItem("accessToken"));
          if (response.ok) {
            navigate("/home"); // Navigate to home after both tasks are done
          } else {
            navigate("/welcome");
          }
        } catch {}
      } else {
        navigate("/welcome");
      }
    };

    handleNavigation();
  }, []);

  // Function to delay for a certain number of milliseconds
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function refreshAccessToken() {
    try {
      const response = await fetch(`${apiUrl}/auth/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userid: localStorage.getItem("userid"), // Pass userId or other required params
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });

      if (response.ok) {
        const tokenData = await response.json();
        sessionStorage.setItem("accessToken", tokenData.accessToken);
        sessionStorage.setItem("autoFlag", "true");
        localStorage.setItem("refreshToken", tokenData.refreshToken);
        console.log(
          "액세스 토큰 정상적으로 저장됨",
          sessionStorage.getItem("accessToken")
        );
        console.log(
          "액세스 토큰 정상적으로 저장됨",
          localStorage.getItem("accessToken")
        );
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  return (
    <div className={`${styles.div} dark:bg-[#121418]`}>
      <Logo />
      <div className="bottom-[10vh] w-full">
        <div className={styles.easybrainSoftWrapper}>
          <div className={styles.easybrainSoft} style={{ fontSize: "18px" }}>
            ezBrain Soft
          </div>
        </div>
      </div>
    </div>
  );
};

export default Splash;
