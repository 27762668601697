import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Lang = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const optionList = ["Korean", "English"];
  const [selectedOption, setSelectedOption] = useState(
    sessionStorage.getItem("onLang1") ?? "선택해주세요"
  );
  const [selectedOption2, setSelectedOption2] = useState(
    sessionStorage.getItem("onLang2") ?? "선택해주세요"
  );
  const location = useLocation();
  const { userId, nickname } = location.state || {};

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  const handleToggle2 = () => {
    setIsOpen2((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleOptionClick2 = (option) => {
    setSelectedOption2(option);
    setIsOpen2(false);
  };

  const navigate = useNavigate();

  const handleNextClick = () => {
    if (
      selectedOption !== "선택해주세요" &&
      selectedOption2 !== "선택해주세요" &&
      selectedOption !== selectedOption2
    ) {
      sessionStorage.setItem("onLang1", selectedOption);
      sessionStorage.setItem("onLang2", selectedOption2);
      navigate("/mbti", {
        state: {
          userId,
          nickname,
          selectedOption,
          selectedOption2,
        },
      });
    } else if (selectedOption === selectedOption2) {
      const toastId = "lang-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("서로 다른 언어를 선택해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return;
    } else {
      const toastId = "lang-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("언어를 올바르게 선택해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
    }
  };

  const handleGoBack = () => {
    sessionStorage.setItem("onLang1", selectedOption);
    sessionStorage.setItem("onLang2", selectedOption2);
    navigate(-1);
  };

  const filteredOptionList1 = optionList.filter((option) => option);
  const filteredOptionList2 = optionList.filter((option) => option);
  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] overflow-hidden">
      <div className="mb-[4vh]"></div>
      {/* Header */}
      <div className="flex items-center justify-between px-[16px] h-[50px] bg-[#fff]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center p-[10px]"
          onClick={handleGoBack}
        >
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Progress Bar */}
      <div className="w-full h-1 bg-gray-200">
        <div className="w-1/2 h-full bg-blue-500"></div>
      </div>

      {/* Content */}
      <div className="flex-1 px-4 py-[20px] flex flex-col gap-5">
        <div className="flex flex-col gap-5">
          <div className="text-[18px] leading-[100%] font-sans font-bold text-[#000]">
            사용하는 언어를 알려주세요
          </div>
          <div className="text-[14px] leading-[22px] font-sans text-[#000]">
            언어 설정은 나중에 변경할 수 있어요
          </div>
        </div>

        {/* Language Selection */}
        <div className="flex flex-col gap-5">
          {/* Native Language */}
          <div className="flex flex-col gap-5">
            <div className="text-[14px] leading-[100%] font-['Pretendard_Variable'] font-bold text-[#000]">
              모국어
            </div>
            <div className="text-[14px] leading-[100%] font-['Pretendard_Variable'] text-[#000]">
              Native Language
            </div>
            <div
              className="relative w-full h-[48px] flex items-center py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px]"
              onClick={handleToggle}
            >
              <div
                className="flex-1 text-[14px] leading-[100%] font-sans font-medium"
                style={{
                  color:
                    selectedOption !== "선택해주세요" ? "black" : "#a9b6ca",
                }}
              >
                {selectedOption}
              </div>
              <button className="w-[30px] h-[30px] flex items-center justify-center">
                <img width="30" height="30" src="down.png" alt="icon" />
              </button>
              {isOpen && (
                <div className="absolute top-full left-0 w-full mt-2 bg-white border border-[#d5dce4] rounded-[12px] shadow-lg z-10">
                  <ul className="max-h-[150px] overflow-y-auto">
                    {filteredOptionList1.map((option) => (
                      <li
                        key={option}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          handleOptionClick(option);
                          handleToggle();
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Target Language */}
          <div className="flex flex-col gap-5">
            <div className="text-[14px] leading-[100%] font-['Pretendard_Variable'] font-bold text-[#000]">
              학습언어
            </div>
            <div className="text-[14px] leading-[100%] font-['Pretendard_Variable'] text-[#000]">
              Target Language
            </div>
            <div
              className="relative w-full h-[48px] flex items-center py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px]"
              onClick={handleToggle2}
            >
              <div
                className="flex-1 text-[14px] leading-[100%] font-sans font-medium"
                style={{
                  color:
                    selectedOption2 !== "선택해주세요" ? "black" : "#a9b6ca",
                }}
              >
                {selectedOption2}
              </div>
              <button className="w-[30px] h-[30px] flex items-center justify-center">
                <img width="30" height="30" src="down.png" alt="icon" />
              </button>
              {isOpen2 && (
                <div className="absolute top-full left-0 w-full mt-2 bg-white border border-[#000 ] rounded-[12px] shadow-lg z-10">
                  <ul className="max-h-[150px] overflow-y-auto">
                    {filteredOptionList2.map((option) => (
                      <li
                        key={option}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          handleOptionClick2(option);
                          handleToggle2();
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {/* <div className="w-full h-[48px] flex items-center justify-between py-3 px-4 border-[1px] border-solid border-[#d5dce4] rounded-[12px]">
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#a9b6ca]">
                선택해주세요
              </div>
              <img
                width="30"
                height="30"
                src="iconI5_75;189_11838;198_4987.png"
                alt="icon"
              />
            </div> */}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={handleNextClick}
        >
          다음
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Lang;
