import React from "react";
import { useNavigate } from "react-router-dom";

const CircleComponent = ({ startIndex = 15, count = 10 }) => {
  const navigate = useNavigate();
  return (
    <div className="relative flex items-center justify-center w-96 h-96 mx-auto my-5">
      {/* Larger, Transparent Circle with Inward Shadow */}
      <div
        className="flex flex-col items-center justify-center w-60 h-60 rounded-full border-4 border-white bg-transparent text-center"
        style={{
          boxShadow:
            "inset 0 0 30px rgba(255, 255, 255, 0.4), inset 0 0 60px rgba(255, 255, 255, 0.2)", // Inward shadow
        }}
      >
        <p className="text-[10px] font-sans text-white opacity-50">추천학습</p>

        {/* Enlarged MBTI text with Times New Roman font */}
        <h1
          className="text-[60px] text-white mt-2"
          style={{ fontFamily: "Times New Roman, serif" }}
        >
          MBTI
        </h1>

        <div
          className="text-xs font-sans text-white text-bold border border-[#fff] rounded-full px-1 py-1 mt-3 z-50"
          onClick={() => {
            navigate("/mbtistudy");
          }}
        >
          MBTI 추천학습 하러가기 →
        </div>
      </div>
      {/* Surrounding Oval Dots */}
      <div className="absolute inset-0 flex items-center justify-center">
        {[...Array(60)].map((_, i) => (
          <span
            key={i}
            className={`absolute animate-pulse ${
              i >= startIndex && i < startIndex + count
                ? "bg-white"
                : "bg-white/50"
            }`} // Brighter color for specified dots
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "90%",
              transform: `rotate(${i * 6}deg) translate(130px) rotate(-${
                i * 6
              }deg)`,
              animationDelay: `${i * 0.1}s`,
              boxShadow:
                i >= startIndex && i < startIndex + count
                  ? "0 0 8px 4px rgba(255, 255, 255, 0.8)"
                  : "none", // Additional glow for bright dots
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CircleComponent;
