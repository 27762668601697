import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi"; // Example for a modern icon

const AppInfo = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleConditions = () => {
    navigate("/conditions");
  };

  const handleTerms = () => {
    navigate("/terms");
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  return (
    // <div className="relative min-h-screen flex flex-col bg-gradient-to-b from-[#faf8f6] to-[#f0ece6] dark:bg-[#121418] overflow-hidden">
    <div className="relative min-h-screen flex flex-col bg-[#faf8f6] dark:bg-[#121418] overflow-hidden">
      {/* Header */}
      <div className="flex items-center justify-between h-[60px] bg-[#faf8f6] dark:bg-[#121418] shadow-md px-4">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center cursor-pointer"
          onClick={handleGoBack}
        >
          <FiArrowLeft size={20} color={darkMode ? "white" : ""} />
        </div>
        <div className="text-xl font-bold tracking-wide text-gray-700 dark:text-[#fff]">
          앱 정보
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center opacity-0">
          {/* Empty to balance layout */}
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 p-6 flex flex-col items-center font-sans">
        <div className="w-full max-w-3xl space-y-6">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              회사명
            </h2>
            <p className="mt-1 text-gray-500">(주)이지브레인소프트</p>
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              정책 및 약관
            </h2>
            <div className="mt-1 text-blue-500 space-x-3">
              <span
                className="cursor-pointer hover:underline"
                onClick={handleConditions}
              >
                이용약관
              </span>
              <span>|</span>
              <span
                className="cursor-pointer hover:underline"
                onClick={handleTerms}
              >
                개인정보처리방침
              </span>
            </div>
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              사업자등록번호
            </h2>
            <p className="mt-1 text-gray-500">373-81-01508</p>
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              통신판매업신고
            </h2>
            <p className="mt-1 text-gray-500">2019-서울광진-0925</p>
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              대표
            </h2>
            <p className="mt-1 text-gray-500">이종호</p>
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              담당자
            </h2>
            <p className="mt-1 text-gray-500">박정한</p>
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              연락처
            </h2>
            <p className="mt-1 text-gray-500">1522-2711</p>
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              찾아오시는 길
            </h2>
            <p className="mt-1 text-gray-500">
              서울특별시 광진구 자양로 95 도광빌딩 6층
            </p>
          </div>
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-700 dark:text-[#fff]">
              이메일
            </h2>
            <p className="mt-1 text-gray-500">ezbrainsoft@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppInfo;
