import React, { useContext } from "react";
import Btn from "./Btn";
import PropTypes from "prop-types";
import styles from "./LoginOptions.module.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { LanguageContext } from "../LanguageContext";

const LoginOptions = ({ className = "", darkMode }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { translations, language } = useContext(LanguageContext);

  const handleClick = () => {
    window.location.href = `${apiUrl}/auth/kakao`;
  };
  const handleClick2 = () => {
    window.location.href = `${apiUrl}/auth/apple`;
  };
  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleGoClick = () => {
    setisModalVisible2(true);
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  return (
    <section
      className={[
        styles.frameParent,
        className,
        darkMode ? "bg-[#121418]" : "",
      ].join(" ")}
    >
      <div className={styles.frameWrapper}>
        <div className={styles.btnParent}>
          <button className={styles.btn} onClick={handleClick}>
            <img className={styles.icon} alt="" src="/kakao-logo.svg" />
            <div className={styles.wrapper}>
              <div className={styles.div}>{translations.welcome1}</div>
            </div>
          </button>
          <button className={styles.btn1} onClick={handleClick2}>
            <img
              className={styles.appleLogoIcon}
              loading="lazy"
              alt=""
              src="/apple-logo.svg"
            />
            <div className={styles.appleIdWrapper}>
              <div className={styles.appleId}>{translations.welcome2}</div>
            </div>
          </button>
          <Btn prop={translations.welcome3} to="/login1" btnBackground="none" />
        </div>
      </div>
      {/* Modal */}
      {isModalVisible2 && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
          <div className="w-[60%] h-[20%] flex flex-col items-center justify-center gap-[10px] py-[20px] px-[34px] bg-[#e3edff] rounded-[8px] overflow-hidden animate-slideIn">
            <div className="text-[26px] font-sans font-black text-[#3677f6] whitespace-nowrap">
              🚧 준비중
            </div>
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
              조금만 기다려주세요!
            </div>
            <button
              className="mt-4 px-4 py-2 bg-[#3677f6] text-white rounded"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

LoginOptions.propTypes = {
  className: PropTypes.string,
};

export default LoginOptions;
