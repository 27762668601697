import React, { useEffect, useState } from "react";

const VipModal = ({ endDate, onClose }) => {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    // Calculate remaining days compared to the current date and endDate
    const calculateDaysLeft = () => {
      const currentDate = new Date();
      const end = new Date(endDate);
      const differenceInTime = end - currentDate; // Difference in milliseconds
      const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // Convert to days

      setDaysLeft(differenceInDays > 0 ? differenceInDays : 0); // Set to 0 if negative
    };

    calculateDaysLeft();
  }, [endDate]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded shadow-lg text-center">
        <h2 className="text-xl font-bold mb-2">VIP Membership</h2>
        <p>
          {daysLeft > 0
            ? `You have ${daysLeft} days left in your VIP membership.`
            : "Your VIP membership has expired."}
        </p>
        <button
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default VipModal;
