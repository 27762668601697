import React, { useEffect, useRef } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AdminRoute = () => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const alertShown = useRef(false);

  useEffect(() => {
    return () => {
      alertShown.current = false;
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/admin" state={{ from: location }} replace />;
  }

  if (user.userType !== 'admin') {
    if (!alertShown.current) {
      alert('접속 권한이 없습니다.');
      alertShown.current = true;
    }
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

export default AdminRoute;
