import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { LanguageContext } from "./LanguageContext";

const MyPageNotice = () => {
  const navigate = useNavigate();
  const { translations, language } = useContext(LanguageContext);
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null); // To handle errors

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  // Fetch the notices from the API
  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const response = await fetch("https://pastellapp.com/api/notices");
        if (!response.ok) {
          throw new Error("Failed to fetch notices");
        }
        const data = await response.json();
        setNotices(data.reverse()); // Assuming data is an array of notices
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchNotices();
  }, []);

  const handleNextClick = (notice) => {
    navigate("/mypagenoticecontent", {
      state: { title: notice.title, content: notice.content },
    });
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // Helper function to check if the notice is within 3 days
  const isNew = (createdAt) => {
    const createdDate = new Date(createdAt);
    const today = new Date();
    const differenceInTime = today - createdDate;
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays <= 3;
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while fetching data
  }

  if (error) {
    return <div>Error: {error}</div>; // Show error message if fetching fails
  }

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center "
          onClick={handleGoBack}
        >
          <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
        </div>
        <div className="flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
          {translations.ANNOUNCEMENTS}
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="relative w-[100%] min-h-screen bg-[#faf8f6] dark:bg-[#121418] overflow-hidden">
        <div className="absolute left-0 w-[100%] min-h-screen flex flex-col items-start justify-start bg-[#fff] dark:bg-[#121418] overflow-hidden">
          {notices.map((notice) => (
            <div
              key={notice.id}
              className="self-stretch flex flex-row items-center justify-between p-[14px] bg-[#fff] dark:bg-[#22232a] border-[solid] border-#e9ecef dark:border-[#464a5a] border border-[0_0_1px]"
              onClick={() => {
                handleNextClick(notice);
              }}
            >
              <div className="flex flex-row items-center justify-start gap-[4px]">
                <div className="text-[14px] leading-[100%] font-sans font-bold text-[#f67239] text-justify whitespace-nowrap">
                  {notice.title}
                </div>
                {isNew(notice.createdAt) && (
                  <div className="h-[20px] flex flex-col items-center justify-center p-[6px] bg-[#e9ecef] rounded-[13px]">
                    <div className="text-[10px] leading-[100%] font-sans font-medium text-[#f67239] text-justify whitespace-nowrap">
                      New
                    </div>
                  </div>
                )}
                {notice.isPinned && (
                  <div className="h-[20px] flex flex-col items-center justify-center p-[6px] bg-[#f67239] rounded-[13px]">
                    <div className="text-[10px] leading-[100%] font-sans font-medium text-[#fff] text-justify whitespace-nowrap">
                      중요!
                    </div>
                  </div>
                )}
              </div>
              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] text-right whitespace-nowrap">
                {new Date(notice.createdAt).toLocaleDateString()}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyPageNotice;
