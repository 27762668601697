import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronDownIcon } from "@heroicons/react/solid";
import axios from "axios";
import API_BASE_URL from "../config";
import plusIcon from "../assets/admin/icons/plus.png";
import xIcon from "../assets/admin/icons/x.png";
import { IoIosArrowBack } from "react-icons/io";
import kor_eng from "../data/select_kor_eng.json";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal2 from "./Modal2";
import { LanguageContext } from "./LanguageContext";

const ErrorReport = () => {
  const { translations, language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [reportData, setReportData] = useState({
    title: "",
    content: "",
    category: "",
    location: "",
    language: "kor",
    userId: localStorage.getItem("useridR"), // 실제 구현 시 로그인한 사용자의 ID를 사용해야 합니다
    files: [],
  });
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const modal2Close = () => {
    setIsModalVisible2(false);
    navigate(-1);
  };
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isLocationDropdownOpen, setIsLocationDropdownOpen] = useState(false);

  const categories = [
    translations.error11,
    translations.error12,
    translations.error13,
  ];
  const locations = [
    "MBTI",
    "PERSONA",
    "SITUATION",
    "PSYCHOLOGY",
    translations.error14,
    translations.error13,
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReportData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (category) => {
    setReportData((prev) => ({ ...prev, category }));
    setIsCategoryDropdownOpen(false);
  };

  const handleCategoryClick = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
    setIsLocationDropdownOpen(false); // 위치 드롭다운 닫기
  };

  const handleLocationChange = (location) => {
    setReportData((prev) => ({ ...prev, location }));
    setIsLocationDropdownOpen(false);
  };

  const handleLocationClick = () => {
    setIsLocationDropdownOpen(!isLocationDropdownOpen);
    setIsCategoryDropdownOpen(false); // 문의유형 드롭다운 닫기
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setReportData((prev) => ({ ...prev, files }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!reportData.title || !reportData.content) {
      const toastId = "form-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("제목과 내용을 모두 입력해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return false;
    }

    const formData = new FormData();
    Object.keys(reportData).forEach((key) => {
      if (key === "files") {
        reportData.files.forEach((file) => {
          formData.append("files", file);
        });
      } else {
        formData.append(key, reportData[key]);
      }
    });

    try {
      const response = await axios.post(
        `${API_BASE_URL}/errorreports`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsModalVisible2(true);
    } catch (error) {}
  };

  const handleImageDelete = (index) => {
    setReportData((prev) => ({
      ...prev,
      files: prev.files.filter((_, i) => i !== index),
    }));
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  return (
    <div className="min-h-screen bg-[#FAF8F6] dark:bg-[#121418] p-6 overflow-y-auto">
      {/* 헤더 */}
      <div className="fixed top-0 left-0 right-0 flex items-center h-[50px] px-[14px] py-[7px] bg-[#faf8f6] dark:bg-[#121418] z-10">
        <IoIosArrowBack
          color={darkMode ? "white" : "black"}
          size={30}
          onClick={() => navigate(-1)}
        />
        <div className="absolute left-1/2 transform -translate-x-1/2 font-sans text-[16px] font-bold dark:text-[#fff]">
          {translations.error1}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="mt-16">
        {/* 카테고리 */}
        <div className="mb-4">
          <label className="block mb-2 font-['Pretendard_Variable'] text-[14px] text-left dark:text-[#fff]">
            {translations.error2}
          </label>
          <div className="relative">
            <div
              className="bg-white dark:bg-[#22232a] dark:border-none p-[18px_14px] h-[48px] rounded-lg border border-[#D5DCE4] flex items-center justify-between cursor-pointer"
              onClick={handleCategoryClick}
            >
              <span
                className={
                  reportData.category
                    ? "text-black font-['Pretendard'] text-[14px]"
                    : "text-[#A9B6CA] font-['Pretendard'] text-[14px]"
                }
              >
                {reportData.category || translations.error8}
              </span>
              <ChevronDownIcon className="h-5 w-5 text-gray-400" />
            </div>
            {isCategoryDropdownOpen && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                {categories.map((category, index) => (
                  <div
                    key={index}
                    className="p-3 hover:bg-gray-100 cursor-pointer font-['Pretendard'] text-[14px]"
                    onClick={() => handleCategoryChange(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* 위치 */}
        <div className="mb-4">
          <label className="block mb-2 font-['Pretendard_Variable'] text-[14px] text-left dark:text-[#fff]">
            {translations.error3}
          </label>
          <div className="relative">
            <div
              className="bg-white dark:bg-[#22232a] dark:border-none p-[18px_14px] h-[48px] rounded-lg border border-[#D5DCE4] flex items-center justify-between cursor-pointer"
              onClick={handleLocationClick}
            >
              <span
                className={
                  reportData.location
                    ? "text-black font-['Pretendard'] text-[14px]"
                    : "text-[#A9B6CA] font-['Pretendard'] text-[14px]"
                }
              >
                {reportData.location || translations.error8}
              </span>
              <ChevronDownIcon className="h-5 w-5 text-gray-400" />
            </div>
            {isLocationDropdownOpen && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                {locations.map((location, index) => (
                  <div
                    key={index}
                    className="p-3 hover:bg-gray-100 cursor-pointer font-['Pretendard'] text-[14px]"
                    onClick={() => handleLocationChange(location)}
                  >
                    {location}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* 제목 */}
        <div className="mb-4">
          <label className="block mb-2 font-['Pretendard_Variable'] text-[14px] text-left dark:text-[#fff]">
            {translations.error4}
          </label>
          <input
            type="text"
            name="title"
            value={reportData.title}
            onChange={(e) =>
              setReportData((prev) => ({ ...prev, title: e.target.value }))
            }
            className="w-full h-[48px] px-[14px] py-[18px] dark:bg-[#22232a] border border-[#D5DCE4] dark:border-none rounded-[12px] font-['Pretendard'] text-[14px]"
            placeholder={translations.error9}
          />
        </div>

        {/* 내용 */}
        <div className="mb-4">
          <label className="block mb-2 font-['Pretendard_Variable'] text-[14px] text-left dark:text-[#fff]">
            {translations.error5}
          </label>
          <textarea
            name="content"
            value={reportData.content}
            onChange={(e) =>
              setReportData((prev) => ({ ...prev, content: e.target.value }))
            }
            className="w-full h-[144px] px-[14px] py-[18px] dark:bg-[#22232a] border border-[#D5DCE4] dark:border-none rounded-[12px] font-['Pretendard'] text-[14px]"
            rows="5"
            placeholder={translations.error10}
            color={darkMode ? "white" : "black"}
          ></textarea>
        </div>

        {/* 사진 첨부 */}
        <div className="mb-4">
          <label className="block mb-2 font-['Pretendard_Variable'] text-[14px] font-bold text-left dark:text-[#fff]">
            {translations.error6}
          </label>
          <div className="flex items-center justify-center w-full">
            <label className="flex items-center justify-between w-full h-[48px] px-[14px] border border-black dark:border-white rounded-[12px] cursor-pointer">
              <span className="text-[14px] dark:text-[#fff]">
                {translations.error6}
              </span>
              <img src={plusIcon} alt="추가" className="w-5 h-5" />
              <input
                type="file"
                className="hidden"
                multiple
                onChange={handleFileChange}
              />
            </label>
          </div>
        </div>

        {/* 미리보기 이미지 */}
        <div className="grid grid-cols-2 gap-4 mt-4">
          {reportData.files.map((file, index) => (
            <div
              key={index}
              className="relative w-full h-32 bg-gray-100 flex items-center justify-center"
            >
              <img
                src={URL.createObjectURL(file)}
                alt={`첨부 이미지 ${index + 1}`}
                className="object-contain h-full w-full rounded-lg bg-[#FAF8F6]"
              />
              <button
                type="button"
                className="absolute top-0 right-0 w-6 h-6 flex items-center justify-center rounded-full"
                onClick={() => handleImageDelete(index)}
              >
                <img src={xIcon} alt="삭제" className="w-6 h-6" />
              </button>
            </div>
          ))}
        </div>

        {/* 제출 버튼 */}
        <div className="sticky bottom-0 left-0 right-0 dark:bg-[#121418]">
          <button
            type="submit"
            className="w-full h-[48px] bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          >
            {translations.error7}
          </button>
        </div>
      </form>
      <ToastContainer />
      <Modal2 isVisible={isModalVisible2} onClose={modal2Close}></Modal2>
    </div>
  );
};

export default ErrorReport;
