import axios from 'axios';
import API_BASE_URL from '../../config';

// customize script 생성
export const createScript = async (scriptData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/customize_script/create`, scriptData);
      return response.data;
    } catch (error) {
      console.error('Error creating script:', error.response ? error.response.data : error.message);
      throw error;
    }
};

// situation script 생성
export const situationCreateScript = async (scriptData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/situation_script/create`, scriptData);
    return response.data;
  } catch (error) {
    console.error('Error creating script:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// psychology script 생성
export const psychologyCreateScript = async (scriptData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/psychology_script/create`, scriptData);
    return response.data;
  } catch (error) {
    console.error('Error creating script:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// 특정 situation Script 데이터 가져오기 (수정용)
export const situationFetchScript = async (scriptId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/situation_script/${scriptId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching script:', error);
    throw error;
  }
};

// 특정 psychology Script 데이터 가져오기 (수정용)
export const psychologyFetchScript = async (scriptId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/psychology_script/${scriptId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching script:', error);
    throw error;
  }
};

// 특정 customize Script 데이터 가져오기 (수정용)
export const fetchScript = async (scriptId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/customize_script/${scriptId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching script:', error);
    throw error;
  }
};

// customize Script 업데이트
export const updateScript = async (scriptId, scriptData) => {
  try {
    await axios.put(`${API_BASE_URL}/customize_script/${scriptId}`, scriptData);
  } catch (error) {
    console.error('Error updating script:', error);
    throw error;
  }
};

// situation Script 업데이트
export const situationUpdateScript = async (scriptId, scriptData) => {
  try {
    await axios.put(`${API_BASE_URL}/situation_script/${scriptId}`, scriptData);
  } catch (error) {
    console.error('Error updating script:', error);
    throw error;
  }
};


// psychology Script 업데이트
export const psychologyUpdateScript = async (scriptId, scriptData) => {
  try {
    await axios.put(`${API_BASE_URL}/psychology_script/${scriptId}`, scriptData);
  } catch (error) {
    console.error('Error updating script:', error);
    throw error;
  }
};


// customize 여러 스크립트를 한 번에 저장
export const saveScript = async (scriptData) => {
  try {
    // 각 난이도별 스크립트를 저장하는 API 호출
    for (const difficulty in scriptData) {
      if (scriptData.hasOwnProperty(difficulty)) {
        const data = scriptData[difficulty];
        await updateScript(data.scriptId, {
          language: data.language,
          background: data.background,
          conversations: data.conversations,
          note: data.note,
          studyPoints: data.studyPoints,
          quiz: data.quiz,
          type: difficulty,
        });
      }
    }
  } catch (error) {
    console.error('Error saving script:', error);
    throw error;
  }
};

// situation 여러 스크립트를 한 번에 저장
export const situationSaveScript = async (scriptData) => {
  try {
    // 각 난이도별 스크립트를 저장하는 API 호출
    for (const difficulty in scriptData) {
      if (scriptData.hasOwnProperty(difficulty)) {
        const data = scriptData[difficulty];
        await situationUpdateScript(data.scriptId, {
          language: data.language,
          background: data.background,
          conversations: data.conversations,
          note: data.note,
          studyPoints: data.studyPoints,
          quiz: data.quiz,
          type: difficulty,
        });
      }
    }
  } catch (error) {
    console.error('Error saving script:', error);
    throw error;
  }
};

// psychology 여러 스크립트를 한 번에 저장
export const psychologySaveScript = async (scriptData) => {
  try {
    // 각 난이도별 스크립트를 저장하는 API 호출
    for (const difficulty in scriptData) {
      if (scriptData.hasOwnProperty(difficulty)) {
        const data = scriptData[difficulty];
        await psychologyUpdateScript(data.scriptId, {
          language: data.language,
          background: data.background,
          conversations: data.conversations,
          note: data.note,
          studyPoints: data.studyPoints,
          quiz: data.quiz,
          type: difficulty,
        });
      }
    }
  } catch (error) {
    console.error('Error saving script:', error);
    throw error;
  }
};

// customize 스크립트 데이터를 가져오는 함수
export const fetchScriptData = async (scriptId) => {
    try {
      if (!scriptId) return null;
      const response = await axios.get(`${API_BASE_URL}/customize_script/${scriptId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching script data for scriptId ${scriptId}:`, error);
      return null;
    }
};

// situation 스크립트 데이터를 가져오는 함수
export const situationFetchScriptData = async (scriptId) => {
  try {
    if (!scriptId) return null;
    const response = await axios.get(`${API_BASE_URL}/situation_script/${scriptId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching script data for scriptId ${scriptId}:`, error);
    return null;
  }
};

// psychology 스크립트 데이터를 가져오는 함수
export const psychologyFetchScriptData = async (scriptId) => {
  try {
    if (!scriptId) return null;
    const response = await axios.get(`${API_BASE_URL}/psychology_script/${scriptId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching script data for scriptId ${scriptId}:`, error);
    return null;
  }
};

// customize 스크립트 삭제
export const deleteScript = async (scriptId) => {
  try {
    await axios.delete(`${API_BASE_URL}/customize_script/${scriptId}`);
  } catch (error) {
    console.error('Error deleting script:', error);
    throw error;
  }
};

// situation 스크립트 삭제
export const situationDeleteScript = async (scriptId) => {
  try {
    await axios.delete(`${API_BASE_URL}/situation_script/${scriptId}`);
  } catch (error) {
    console.error('Error deleting script:', error);
    throw error;
  }
};

// psychology 스크립트 삭제
export const psychologyDeleteScript = async (scriptId) => {
  try {
    await axios.delete(`${API_BASE_URL}/psychology_script/${scriptId}`);
  } catch (error) {
    console.error('Error deleting script:', error);
    throw error;
  }
};