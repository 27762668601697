import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Navigation from '../../components/admin/Navigation';
import API_BASE_URL from '../../config';

const AdminEditor = () => {
  const navigate = useNavigate();
  const { userid: urlUserId } = useParams();
  const [adminData, setAdminData] = useState({
    id: '',
    userid: '',
    username: '',
    password: '',
    passwordConfirm: '',
    createdAt: '',
  });

  const [permissions, setPermissions] = useState({
    studyOverview: false,
    expressionOverview: false,
    expression: false,
    customize: false,
    situation: false,
    psychology: false,
    userManagement: false,
    productManagement: false,
    paymentManagement: false,
    faqManagement: false,
    noticeManagement: false,
    errorManagement: false,
    appSettings: false,
  });

  const isEditMode = !!urlUserId;

  useEffect(() => {
    if (isEditMode) {
      fetchAdminData();
    }
  }, [urlUserId]);

  const fetchAdminData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/auth/manager/finduser/${urlUserId}`);
      const { id, userid, username, createdAt, menus } = response.data;
      setAdminData({
        id,
        userid,
        username,
        password: '',
        passwordConfirm: '',
        createdAt,
      });
      
      if (menus && menus.length > 0) {
        setPermissions(menus[0]);
      }
    } catch (error) {
      console.error('관리자 데이터를 불러오는 데 실패했습니다:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminData(prev => ({ ...prev, [name]: value }));
  };

  const handlePermissionChange = (permission) => {
    setPermissions(prev => ({ ...prev, [permission]: !prev[permission] }));
  };

  const handleSubmit = async () => {
    if (adminData.password !== adminData.passwordConfirm) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    const adminPayload = {
      userid: adminData.userid,
      username: adminData.username,
      password: adminData.password,
      ...permissions,
    };

    try {
      if (isEditMode) {
        // 기존 정보 수정
        await axios.put(`${API_BASE_URL}/auth/manage/${urlUserId}`, adminPayload);
        
        // 비밀번호가 입력된 경우에만 비밀번호 변경 API 호출
        if (adminData.password) {
          await axios.put(`${API_BASE_URL}/auth/reset-password`, {
            userid: adminData.userid,
            newPassword: adminData.password
          });
        }
        
        alert('관리자 정보가 수정되었습니다.');
      } else {
        // 새 관리자 생성 시에는 기존 로직 유지
        await axios.post(`${API_BASE_URL}/auth/manage-signup`, {
          ...adminPayload,
          password: adminData.password
        });
        alert('새 관리자가 생성되었습니다.');
      }
      navigate('/admin-list');
    } catch (error) {
      console.error('관리자 정보 저장 중 오류 발생:', error);
      alert('관리자 정보 저장에 실패했습니다.');
    }
  };

  const renderPermissionButton = (category, label, subcategory = null) => {
    const isActive = subcategory ? permissions[subcategory] : permissions[category];
    return (
      <button
        onClick={() => handlePermissionChange(subcategory || category)}
        className={`mb-2 mr-2 ${isActive ? 'bg-[#E3EDFF] border-[#3677F6]' : 'bg-white border-[#E2E8EF]'}`}
        style={{
          height: '42px',
          padding: '14px 24px',
          borderRadius: '59px',
          border: '1px solid',
          fontSize: '14px',
          fontWeight: 500,
          color: isActive ? '#3677F6' : '#666B81',
          transition: 'all 0.3s ease',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      <div className="flex justify-between items-center mb-5">
        <h1 
          className="text-2xl font-bold text-left"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          {isEditMode ? '관리자 계정 수정' : '관리자 계정 생성'}
        </h1>
        <div className="flex space-x-4">
          <button
            onClick={() => navigate('/admin-list')}
            className="w-[100px] h-[48px] bg-[#666B81] text-white"
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 700,
              borderRadius: '12px'
            }}
          >
            목록가기
          </button>
          <button 
            onClick={handleSubmit}
            className="w-[100px] h-[48px] bg-[#3677F6] text-white"
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 700,
              borderRadius: '12px'
            }}
          >
            저장하기
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '0E111F',
          }}
        >
          구분
        </h2>
        <div className="space-y-4">
          <div className="flex gap-4">
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                ID
              </label>
              <input
                type="text"
                name="userid"
                value={adminData.userid}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                이름
              </label>
              <input
                type="text"
                name="username"
                value={adminData.username}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                비밀번호
              </label>
              <input
                type="password"
                name="password"
                value={adminData.password}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                비밀번호 확인
              </label>
              <input
                type="password"
                name="passwordConfirm"
                value={adminData.passwordConfirm}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                생성일시
              </label>
              <input
                type="text"
                name="createdAt"
                value={adminData.createdAt}
                readOnly
                className="w-full bg-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  border: 'none',
                }}
              />
            </div>
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                유저 코드
              </label>
              <input
                type="text"
                name="id"
                value={adminData.id}
                readOnly
                className="w-full bg-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  border: 'none',
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '0E111F',
          }}
        >
          권한 설정
        </h2>
        <div className="space-y-6">
          <div className="flex gap-2">
            <div>
              <h3 className="font-bold mb-3.5">작업</h3>
              <div className="flex flex-wrap gap-1.5 border-r border-[#E2E8EF] pr-4">
                {renderPermissionButton('work', 'OVERVIEW', 'studyOverview')}
                {renderPermissionButton('work', 'CUSTOMIZE', 'customize')}
                {renderPermissionButton('work', 'SITUATION', 'situation')}
                {renderPermissionButton('work', 'PSYCHOLOGY', 'psychology')}
              </div>
            </div>
            <div className="pl-4">
              <h3 className="font-bold mb-3.5">표현하기</h3>
              <div className="flex flex-wrap gap-1.5">
                {renderPermissionButton('express', 'OVERVIEW', 'expressionOverview')}
                {renderPermissionButton('express', 'EXPRESSION', 'expression')}
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <h3 className="font-bold mb-3.5">회원관리</h3>
              <div className="flex flex-wrap gap-1.5 border-r border-[#E2E8EF] pr-4">
                {renderPermissionButton('userManagement', '작업관리')}
              </div>
            </div>
            <div className="pl-4">
              <h3 className="font-bold mb-3.5">상품관리</h3>
              <div className="flex flex-wrap gap-1.5 border-r border-[#E2E8EF] pr-4">
                {renderPermissionButton('productManagement', '상품관리')}
              </div>
            </div>
            <div className="pl-4">
              <h3 className="font-bold mb-3.5">결제관리</h3>
              <div className="flex flex-wrap gap-1.5">
                {renderPermissionButton('paymentManagement', '결제관리')}
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <h3 className="font-bold mb-3.5">CS관리</h3>
              <div className="flex flex-wrap gap-1.5 border-r border-[#E2E8EF] pr-4">
                {renderPermissionButton('cs', '공지사항', 'noticeManagement')}
                {renderPermissionButton('cs', 'FAQ', 'faqManagement')}
                {renderPermissionButton('cs', '오류신고', 'errorManagement')}
              </div>
            </div>
            <div className="pl-4">
              <h3 className="font-bold mb-3.5">앱설정</h3>
              <div className="flex flex-wrap gap-1.5">
                {renderPermissionButton('appSettings', '앱설정')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditor;
