import React, { useState, useContext, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import kor_eng from "../data/select_kor_eng.json";

const LanguageSwitcher = () => {
  const { language, switchLanguage } = useContext(LanguageContext); // Destructure `language` and `switchLanguage`
  const [modalText, setModalText] = useState(
    language === sessionStorage.getItem("native") ? false : true
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang === "eng" ? "English" : "Korean");
    switchLanguage(lang); // Pass the language to the switchLanguage function
    setModalOpen(true);
    setTimeout(() => setModalOpen(false), 700); // Auto close modal after 700ms
  };

  useEffect(() => {
    // Initialize selectedLanguage from context
    setSelectedLanguage(language === "kor" ? "Korean" : "English");
  }, [language]);

  return (
    <div>
      {!modalText ? (
        <div
          className="flex items-center justify-center w-[36px] h-[36px] border-2 border-solid bg-[#25272f] border-[#25272f] rounded-full cursor-pointer"
          onClick={() => {
            handleLanguageChange(sessionStorage.getItem("target"));
            setModalText(true);
          }} // Switch to English
        >
          <span className="text-[16px] font-sans font-semibold text-[#fff]">
            N
          </span>
        </div>
      ) : (
        <div
          className="flex items-center justify-center w-[36px] h-[36px] border-2 border-solid border-[#25272f] rounded-full cursor-pointer"
          onClick={() => {
            handleLanguageChange(sessionStorage.getItem("native"));
            setModalText(false);
          }} // Switch to Korean
        >
          <span className="text-[16px] font-sans font-semibold text-[#25272f]">
            T
          </span>
        </div>
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60 transition-opacity duration-300 ease-in-out">
          <div className="bg-white rounded-xl p-6 w-[320px] shadow-xl transform transition-transform duration-300 ease-in-out scale-100 hover:scale-105">
            <h2 className="text-lg font-medium text-gray-800 text-center">
              Changed to{" "}
              <span className="font-semibold text-indigo-600">
                {!modalText ? "Native" : "Target"}
              </span>
            </h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
