import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./user/store";
import { LanguageProvider } from "./user/LanguageContext";
import { DifficultyProvider } from "./user/DifficultyContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DifficultyProvider>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </DifficultyProvider>
    </PersistGate>
  </Provider>
);
