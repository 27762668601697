import axios from 'axios';
import API_BASE_URL from '../../config';

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
});

const handleApiError = (error) => {
  if (error.response) {
    // 서버가 2xx 범위를 벗어난 상태 코드로 응답한 경우
    console.error('Response error:', error.response.data);
    console.error('Status code:', error.response.status);
    console.error('Headers:', error.response.headers);
  } else if (error.request) {
    // 요청이 전송되었지만 응답을 받지 못한 경우
    console.error('Request error:', error.request);
  } else {
    // 요청 설정 중 오류가 발생한 경우
    console.error('Error message:', error.message);
  }
  console.error('Error config:', error.config);
  throw error;
};

// 상품 조회
export const getProducts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// ID로 상품 조회
export const getProductById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 상품 추가
export const addProduct = async (product) => {
  try {
    const response = await api.post('/products', product);
    return response.data;
  } catch (error) {
    console.error('Error in addProduct:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
};

// 상품 업데이트
export const updateProduct = async (productId, updatedData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/products/${productId}`, updatedData);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 상품 삭제
export const deleteProduct = async (productId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/products/${productId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 상품 페이지네이션
export const getProductsByPage = async (page, limit) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products`, {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 상품 필터링
export const getProductsByFilter = async (page, limit, search, category, startDate, endDate) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products`, {
      params: {
        page,
        limit,
        search,
        category,
        startDate,
        endDate
      }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 여러 상품 삭제
export const deleteProducts = async (ids) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/products/multidelete`,{ ids });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

// 상품 편집을 위한 데이터 가져오기
export const getProductForEdit = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products/${id}`);
    return response.data;
  } catch (error) {
    console.error('상품 데이터 가져오기 실패:', error);
    throw error;
  }
};
