import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserId, clearUserId } from "../actions";

const SocialKakao = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const account = urlParams.get("account");
    const accessToken = urlParams.get("accessToken");
    const refreshToken = urlParams.get("refreshToken");
    const onProfile = urlParams.get("onprofile");
    const onBoard = urlParams.get("onboarding");
    const userId = urlParams.get("id");
    const apiUrl = process.env.REACT_APP_API_URL;
    const updateVisit = async () => {
      try {
        const response = await fetch(`${apiUrl}/auth/log-visit`, {
          method: "GET", // or 'POST' depending on your API
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            "Content-Type": "application/json", // Include other headers if needed
          },
        });
        if (response.ok) {
        } else {
        }
      } catch {}
    };

    if (accessToken) {
      sessionStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      updateVisit();
      navigate("/home");
    } else if (onProfile === "false" || account === "false") {
      sessionStorage.setItem("passOnboard", onBoard);
      sessionStorage.setItem("passUserId", userId);
      navigate("/social-login");
    } else if (onBoard === "false") {
      navigate("/nickname", {
        state: {
          userId,
        },
      });
    }
  }, []);

  return <div>로그인 실패</div>;
};

export default SocialKakao;
