import axios from 'axios';
import API_BASE_URL from '../../config';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 결제 상태 업데이트 함수 추가
export const updatePaymentStatus = async (tid, status) => {
  try {
    const response = await axiosInstance.put(`${API_BASE_URL}/payment/status`, {
      tid: tid,
      status: status
    });
    return response.data;
  } catch (error) {
    console.error('결제 상태 업데이트 오류:', error);
    throw error;
  }
};

export default axiosInstance;
