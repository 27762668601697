import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from '../../components/admin/Navigation';
import { getCashById } from '../../api/admin/cash';
import { updatePaymentStatus } from '../../utils/admin/axiosConfig';

const CashEditor = () => {
  const navigate = useNavigate();
  const { cashId } = useParams();
  const [paymentData, setPaymentData] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const data = await getCashById(cashId);
        setPaymentData(data);
      } catch (error) {
        console.error('Error fetching payment data:', error);
      }
    };

    fetchPaymentData();
  }, [cashId]);

  const toggleDropdown = (name) => {
    setOpenDropdown(openDropdown === name ? null : name);
  };

  const handlePaymentStatusChange = async (value) => {
    try {
      await updatePaymentStatus(paymentData.tid, value);
      setPaymentData(prevData => ({
        ...prevData,
        status: value
      }));
      alert('결제 상태가 성공적으로 업데이트되었습니다.');
    } catch (error) {
      console.error('결제 상태 업데이트 실패:', error);
      alert('결제 상태 업데이트에 실패했습니다.');
    }
  };

  if (!paymentData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-[#FAF8F6] pr-6 pl-6 pt-6 pb-20">
      <Navigation />
      
      {/* 상단 버튼 */}
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold text-left" style={{fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700}}>
          결제상세
        </h1>
        <button
          onClick={() => navigate('/cash')}
          className="w-[100px] h-[48px] bg-[#666B81] text-white"
          style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, borderRadius: '18px 14px 18px 14px'}}
        >
          목록가기
        </button>
      </div>

      {/* 상품정보 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
          상품정보
        </h2>
        <div className="grid grid-cols-3 gap-8">
          <InfoField label="상품명" value={paymentData.goodsName} />
          <InfoField label="주문번호" value={paymentData.tid} />
          <InfoField label="주문금액" value={paymentData.amount} />
        </div>
      </div>

      {/* 구매자 정보 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
          구매자 정보
        </h2>
        <div className="grid grid-cols-3 gap-8">
          <InfoField label="USERID" value={paymentData.user.userid} />
          <InfoField label="이름" value={paymentData.user.username} />
          <InfoField label="휴대폰 번호" value={paymentData.user.phoneNumber} />
        </div>
      </div>

      {/* 결제 정보 */}
      <div className="bg-white rounded-lg p-6 shadow-md">
        <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
          결제 정보
        </h2>
        <div className="grid grid-cols-3 gap-8">
          <InfoField label="주문금액" value={paymentData.amount} />
          <InfoField label="결제수단" value={paymentData.payMethod} />
          <InfoField 
            label="결제일시" 
            value={paymentData.status === '결제 실패' ? '-' : new Date(paymentData.paidAt).toLocaleString()} 
          />
          <SelectField 
            label="결제상태" 
            name="status" 
            value={paymentData.status} 
            options={['결제 성공', '환불 완료', '결제 실패']}
            isOpen={openDropdown === 'status'}
            onToggle={() => toggleDropdown('status')}
            onChange={(value) => handlePaymentStatusChange(value)}
          />
        </div>
      </div>
    </div>
  );
};

const InfoField = ({ label, value }) => (
  <div>
    <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
      {label}
    </label>
    <div className="bg-[#E2E8EF] flex items-center" style={{height: '48px', padding: '18px 14px', borderRadius: '12px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#000000'}}>
      {value}
    </div>
  </div>
);

const SelectField = ({ label, name, value, options, isOpen, onToggle, onChange }) => (
  <div>
    <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
      {label}
    </label>
    <div className="relative">
      <button
        onClick={onToggle}
        className="w-full flex items-center justify-between rounded-[12px] border border-[#E2E8EF]"
        style={{height: '48px', padding: '18px 14px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#000000'}}
      >
        {value}
        <span className="ml-2">▼</span>
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg">
          {options.map((option) => (
            <div
              key={option}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                onChange(option);
                onToggle();
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);

export default CashEditor;
