import React, { useState, useEffect } from 'react';
import ExpressionTreeNodeSelector from './ExpressionTreeNodeSelector';

const ExpressionManageSection = ({ 
  manageData, 
  onManageChange, 
  categories, 
  onTagsChange, 
  onLinkChange, 
  mode, 
  setManageData 
}) => {
  const [linkError, setLinkError] = useState('');
  const [translationData, setTranslationData] = useState(null);

  // translationData 로드
  useEffect(() => {
    const loadTranslationData = async () => {
      try {
        const response = await fetch('/select_kor_eng.json');
        const data = await response.json();
        setTranslationData(data);
      } catch (error) {
        console.error('Error loading translation data:', error);
      }
    };
  
    loadTranslationData();
  }, []);

  // 선택된 한글 값을 영어로 변환하여 저장하는 함수
  const handleManageInputChange = (e) => {
    const { name, value } = e.target;

    // 선택한 값은 한글이므로 영어로 변환해서 저장해야 함
    let englishValue = value;

    // translationData를 통해 한글 -> 영어로 변환
    if (translationData && translationData.expression) {
      englishValue = translationData.expression[value] || value; // 한글 -> 영어 변환
    }

    // 변환된 영어 값을 저장
    onManageChange({ [name]: englishValue });
  };

  // 영어 값을 한국어로 변환하여 화면에 표시하는 함수
  const getKoreanLabel = (englishValue) => {
    if (translationData && translationData.expression) {
      return Object.keys(translationData.expression).find(key => translationData.expression[key] === englishValue) || englishValue;
    }
    return englishValue;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setManageData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // USED 상태 업데이트 함수
  const handleUsedChange = (e) => {
    onManageChange({
      used: e.target.value === '출력'
    });
  };

  // ACADEMIC ENGLISH 상태 업데이트 함수
  const handleAcademicEnglishChange = (e) => {
    onManageChange({
      academic_english: e.target.value === 'Yes'
    });
  };

  const handleLinkChange = (e) => {
    const { value } = e.target;
    setLinkError('');
    onLinkChange(value);
  };

  const handleLinkBlur = (e) => {
    const { value } = e.target;
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i'
    );
  
    if (!value || urlPattern.test(value)) {
      setLinkError('');
    } else {
      setLinkError('올바른 URL 주소를 복사하여 붙여 넣으세요.');
    }
  };

  return (
    <div>
      {/* 제목 표시 */}
      <h1
        className="font-bold uppercase"
        style={{
          width: '140px',
          height: '28px',
          fontFamily: 'Pretendard',
          fontSize: '24px',
          fontWeight: 700,
          textAlign: 'left',
        }}
      >
        EXPRESSION
      </h1>

      {/* manage 영역 */}
      <div
        className="bg-white p-5 rounded-lg shadow-md"
        style={{
          margin: '26px 0px 20px 0', // 위 26px, 아래 20px 여백을 줍니다.
        }}  
      >
        {/* COVER Section */}
        <div className="flex justify-between">
          <div className="flex-grow">
            <label 
              className="block"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontWeight: 700,
                textAlign: 'left',
              }}
            >
              COVER
            </label>
            <div className="mt-4 flex items-center space-x-6">
              {/* COVER TITLE */}
              <div className='flex-grow'>  
                <label
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  관리자 제목
                </label>
                <input
                  type="text"
                  name="title"
                  placeholder="표제를 입력해 주세요."
                  value={manageData.title}
                  onChange={handleInputChange}
                  className="border h-[48px] w-full"
                  style={{
                    padding: '18px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                  }}
                />
              </div>
              
              {/* USED Section */}
              <div className="w-48">
                <label
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  출력 여부
                </label>
                <select
                  value={manageData.used ? '출력' : '비출력'}
                  onChange={handleUsedChange}
                  className="border h-[48px] w-full"
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="출력">출력</option>
                  <option value="비출력">비출력</option>
                </select>
              </div>

              {/* 시리얼 넘버 */}
              <div className="w-48">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  시리얼 넘버
                </label>
                <input
                  type="text"
                  name="serialnumber"  // 여기에 적절한 name 속성이 필요합니다.
                  value={manageData.serialnumber || ''}
                  onChange={handleInputChange}
                  className="border h-[48px] w-full bg-gray-200"
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#666B81',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
            
        {/* TAG Section */}
        <div className='mt-4'>
          <label
            className='block'
            style={{
              fontFamily: 'Pretendard',
              fontSize: '18px',
              fontWeight: 700,
              textAlign: 'left',
            }}
          >
            TAG
          </label>
          
          <div className="mt-4 flex items-center space-x-6">
            {/* 표현 카테고리 */}
            <div className='w-1/6'>
              <label className="block mb-2.5" style={{ fontFamily: 'Pretendard Variable', fontSize: '14px', fontWeight: 700, textAlign: 'left' }}>
                표현 카테고리
              </label>
              {translationData && translationData.expression ? (
                <select
                  name="category" // 카테고리 항목
                  value={getKoreanLabel(manageData.category) || ''}  // 영어 값을 한국어로 변환하여 표시
                  onChange={handleManageInputChange}  // 선택한 값을 영어로 변환하여 저장
                  className="border h-[48px] w-full"
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="">선택</option>
                  {Object.keys(translationData.expression).map((koreanValue, index) => (
                    <option key={index} value={koreanValue}>
                      {koreanValue} {/* 한국어로 표현 */}
                    </option>
                  ))}
                </select>
              ) : (
                <div>Loading...</div>
              )}
            </div>
            
            {/* ACADEMIC ENGLISH Section */}
            <div className="w-1/6">
              <label 
                className="block mb-2.5"
                style={{
                  fontFamily: 'Pretendard Variable',
                  fontSize: '14px',
                  fontWeight: 700,
                  textAlign: 'left',
                }}
              >
                Academic English
              </label>
              <select
                value={manageData.academic_english ? 'Yes' : 'No'}
                onChange={handleAcademicEnglishChange}
                className="border h-[48px] w-full"
                style={{
                  padding: '4px 14px',
                  borderRadius: '12px',
                  border: '1px solid #E2E8EF',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          {/* Tree Node Selector 추가 */}
          <div className="mt-4">
            <ExpressionTreeNodeSelector
              mode={mode}
              manageData={manageData}
              onTagsChange={onTagsChange}
            />
          </div>
        </div>

        {/* FILE Section */}
        <div className='mt-4'>
          <h2 className="text-2xl font-bold mb-4">FILE</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-semibold mb-2.5">Link</label>
              <input
                type="text"
                name="link"
                placeholder="링크를 입력하세요"
                value={manageData.link}
                onChange={handleLinkChange}
                onBlur={handleLinkBlur}
                className={`border p-2 rounded-md w-full h-[48px] ${linkError ? 'border-red-500' : ''}`}
                style={{
                  padding: '4px 14px',
                  borderRadius: '12px',
                  border: '1px solid #E2E8EF',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
              {linkError && <p className="text-red-500 text-sm mt-1">{linkError}</p>}
            </div>
            <div>
              <label className="block text-sm font-semibold mb-2.5">Memo</label>
              <input
                type="text"
                name="memo"
                placeholder="MEMO를 입력하세요"
                value={manageData.memo}
                onChange={handleInputChange}
                className="border p-2 rounded w-full h-[48px]"
                style={{
                  padding: '4px 14px',
                  borderRadius: '12px',
                  border: '1px solid #E2E8EF',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpressionManageSection;