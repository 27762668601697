import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/admin/images/logo/logo.png';

const Navigation = () => {
  const [jsonOpen, setJsonOpen] = useState(false);
  const [learnOpen, setLearnOpen] = useState(false);
  const [expressOpen, setExpressOpen] = useState(false);
  const [csOpen, setCsOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // 경로가 변경되었을 때
    if (location.pathname.startsWith('/inputJson') || location.pathname.startsWith('/expressJson')) {
      setJsonOpen(true);
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
      setAdminOpen(false);
    } else if (
      location.pathname.startsWith('/customize') ||
      location.pathname.startsWith('/situation') ||
      location.pathname.startsWith('/psychology') ||
      location.pathname.startsWith('/learn-overview')
    ) {
      setJsonOpen(false);
      setLearnOpen(true);
      setExpressOpen(false);
      setCsOpen(false);
      setAdminOpen(false);
    } else if (location.pathname.startsWith('/expression') || location.pathname.startsWith('/express-overview')) {
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(true);
      setCsOpen(false);
      setAdminOpen(false);
    } else if (location.pathname.startsWith('/notice') || location.pathname.startsWith('/faq') || location.pathname.startsWith('/error')) {
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(true);
      setAdminOpen(false);
    } else if (location.pathname.startsWith('/admin-meta') || location.pathname.startsWith('/admin-list') || location.pathname.startsWith('/admin-editor')) {
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
      setAdminOpen(true);
    } else {
      // 모든 메뉴를 닫음
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
      setAdminOpen(false);
    }
  }, [location.pathname]);

  const toggleJsonMenu = () => {
    setJsonOpen(!jsonOpen);
    if (!jsonOpen) {
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
      setAdminOpen(false);
    }
  };

  const toggleLearnMenu = () => {
    setLearnOpen(!learnOpen);
    if (!learnOpen) {
      setJsonOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
      setAdminOpen(false);
    }
  };

  const toggleExpressMenu = () => {
    setExpressOpen(!expressOpen);
    if (!expressOpen) {
      setJsonOpen(false);
      setLearnOpen(false);
      setCsOpen(false);
      setAdminOpen(false);
    }
  };

  const toggleCsMenu = () => {
    setCsOpen(!csOpen);
    if (!csOpen) {
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(false);
      setAdminOpen(false);
    }
  };

  const toggleAdminMenu = () => {
    setAdminOpen(!adminOpen);
    if (!adminOpen) {
      setJsonOpen(false);
      setLearnOpen(false);
      setExpressOpen(false);
      setCsOpen(false);
    }
  };

  const closeMenus = () => {
    setJsonOpen(false);
    setLearnOpen(false);
    setExpressOpen(false);
    setCsOpen(false);
    setAdminOpen(false);
  };

  const isActive = (paths) => paths.some(path => location.pathname.startsWith(path));

  const getMenuClass = (paths) => {
    const isSelected = isActive(paths);
    return {
      color: isSelected ? 'text-selectedBlue' : 'hover:text-customBlue',
      fontWeight: isSelected ? '700' : '500',
    };
  };

  return (
    <>
      <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-50" style={{ height: '68px' }}>
        <div className="container flex items-center" style={{ padding: '0 0 0 32px' }}>
          <NavLink to="/dashboard" className="flex items-center mr-8" onClick={closeMenus}>
            <img 
              src={logo} 
              alt="파스텔라 로고" 
              className="h-8 w-auto" 
              style={{ maxWidth: '100px', height: '30px', objectFit: 'contain' }} 
            />
          </NavLink>
          <div className="flex items-center space-x-4 overflow-x-auto" style={{ gap: '20px' }}>
            {/* 홈 메뉴 추가 */}
            <NavLink
              to="/dashboard"
              className={`flex items-center whitespace-nowrap ${getMenuClass(['/dashboard']).color}`}
              onClick={closeMenus}
              style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
            >
              홈
            </NavLink>
            <div className="relative flex items-center">
              <button
                onClick={toggleJsonMenu}
                className={`flex items-center whitespace-nowrap ${getMenuClass(['/inputJson', '/expressJson']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                JSON
              </button>
              {/* 드롭다운 메뉴 */}
              {jsonOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/inputJson"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/inputJson']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/inputJson']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      학습하기
                    </NavLink>
                    <NavLink
                      to="/expressJson"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/expressJson']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/expressJson']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      표현하기
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            <div className="relative flex items-center">
              <button
                onClick={toggleLearnMenu}
                className={`flex items-center whitespace-nowrap ${getMenuClass(['/customize', '/situation', '/psychology', '/learn-overview']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                학습하기
              </button>
              {/* 드롭다운 메뉴 */}
              {learnOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/learn-overview"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/learn-overview']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/learn-overview']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      OVERVIEW
                    </NavLink>
                    <NavLink
                      to="/customize"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/customize']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/customize']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      CUSTOMIZE
                    </NavLink>
                    <NavLink
                      to="/situation"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/situation']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/situation']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      SITUATION
                    </NavLink>
                    <NavLink
                      to="/psychology"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/psychology']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/psychology']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      PSYCHOLOGY
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            <div className="relative flex items-center">
              <button
                onClick={toggleExpressMenu}
                className={`flex items-center whitespace-nowrap ${getMenuClass(['/expression', '/express-overview']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                표현하기
              </button>
              {/* 드롭다운 메뉴 */}
              {expressOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/express-overview"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/express-overview']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/express-overview']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      OVERVIEW
                    </NavLink>
                    <NavLink
                      to="/expression"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/expression']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/expression']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      EXPRESSION
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            {/* 회원 관리 메뉴 추가 */}
            <NavLink
              to="/member"
              className={`flex items-center whitespace-nowrap ${getMenuClass(['/member']).color}`}
              onClick={closeMenus}
              style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
            >
              회원 관리
            </NavLink>
            {/* 상품 관리 메뉴 추가 */}
            <NavLink
              to="/product"
              className={`flex items-center whitespace-nowrap ${getMenuClass(['/product']).color}`}
              onClick={closeMenus}
              style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
            >
              상품 관리
            </NavLink>
            {/* 결제 관리 메뉴 추가 */}
            <NavLink
              to="/cash"
              className={`flex items-center whitespace-nowrap ${getMenuClass(['/cash']).color}`}
              onClick={closeMenus}
              style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
            >
              결제 관리
            </NavLink>
            {/* CS 관리 메뉴 */}
            <div className="relative flex items-center">
              <button
                onClick={toggleCsMenu}
                className={`flex items-center whitespace-nowrap ${getMenuClass(['/notice', '/faq', '/error']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                CS 관리
              </button>
              {/* 드롭다운 메뉴 */}
              {csOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/notice"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/notice']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/notice']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      공지사항
                    </NavLink>
                    <NavLink
                      to="/faq"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/faq']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/faq']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      FAQ
                    </NavLink>
                    <NavLink
                      to="/error"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/error']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/error']).fontWeight,
                        lineHeight: '14px',
                      }}
                    >
                      오류신고
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            {/* 최고 관리자 메뉴 */}
            <div className="relative flex items-center">
              <button
                onClick={toggleAdminMenu}
                className={`flex items-center whitespace-nowrap ${getMenuClass(['/admin-meta', '/admin-list', '/admin-editor']).color}`}
                style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
              >
                최고 관리자
              </button>
              {/* 드롭다운 메뉴 */}
              {adminOpen && (
                <div
                  className="fixed left-0 right-0 bg-white text-gray-800 shadow-lg z-40"
                  style={{
                    top: '68px',
                    height: '45px',
                    padding: '0 30px',
                    gap: '36px',
                    border: '1px solid #e5e7eb',
                  }}
                >
                  <div 
                    className="container flex items-center h-full"
                    style={{
                      color: '#666B81',
                    }}
                  >
                    <NavLink
                      to="/admin-meta"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/admin-meta']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/admin-meta']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      메타정보 관리
                    </NavLink>
                    <NavLink
                      to="/admin-list"
                      className={`flex items-center whitespace-nowrap ${getMenuClass(['/admin-list', '/admin-editor']).color}`}
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        fontFamily: 'Pretendard',
                        fontSize: '14px',
                        fontWeight: getMenuClass(['/admin-list', '/admin-editor']).fontWeight,
                        lineHeight: '14px',
                        marginRight: '36px',
                      }}
                    >
                      관리자 계정
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
            
            {/* 앱 설정 메뉴 추가 */}
            <NavLink
              to="/app-editor"
              className={`flex items-center whitespace-nowrap ${getMenuClass(['/app-editor']).color}`}
              onClick={closeMenus}
              style={{ fontFamily: 'Pretendard', fontSize: '16px', fontWeight: '700', lineHeight: '68px' }}
            >
              앱 설정
            </NavLink>
          </div>
        </div>
      </nav>
    {/* 컨텐츠를 아래로 밀기 */}
    <div style={{ marginTop: jsonOpen || learnOpen || expressOpen || csOpen || adminOpen ? '113px' : '69px' }} />
    </>
  );
};

export default Navigation;