import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./Field.module.css";

const Field = ({
  className = "",
  disabled = false,
  readOnly = false,
  fieldBorder,
  fieldBackgroundColor,
  prop,
  prop1,
  divColor,
  divMinWidth,
  divDisplay,
  onChange,
  error,
  type = "text",
  value = "", // value prop 추가
}) => {
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = (event) => {
    setInternalValue(event.target.value);
    if (onChange) onChange(event.target.value);
  };

  const fieldStyle = useMemo(() => {
    return {
      border: fieldBorder,
      backgroundColor: fieldBackgroundColor,
    };
  }, [fieldBorder, fieldBackgroundColor]);

  const div1Style = useMemo(() => {
    return {
      color: divColor,
      minWidth: divMinWidth,
      display: divDisplay,
    };
  }, [divColor, divMinWidth, divDisplay]);

  return (
    <div className={styles.textField}>
      <b className={styles.b}>{prop1}</b>
      <input
        style={fieldStyle}
        placeholder={prop}
        type={type}
        value={value}
        onChange={handleChange}
        className={`${styles.field} ${error ? styles.fieldWithError : ""}`}
        disabled={disabled}
        readOnly={readOnly}
      />
      {error && (
        <div className={styles.errorIcon}>
          <span className={styles.alertIcon}>⚠️</span>
        </div>
      )}
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  prop: PropTypes.string,

  /** Style props */
  fieldBorder: PropTypes.any,
  fieldBackgroundColor: PropTypes.any,
  divColor: PropTypes.any,
  divMinWidth: PropTypes.any,
  divDisplay: PropTypes.any,
  onChange: PropTypes.func, // New prop to handle input changes
  value: PropTypes.string, // value prop 추가
};

export default Field;
