import styles from "./FormTop.module.css";

const FormTop = ({ children, darkMode }) => {
  return (
    <section
      className={`${styles.frameParent} ${darkMode ? "bg-[#121418]" : ""}`}
    >
      {children}
    </section>
  );
};

export default FormTop;
