import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "./actions";
import { IoIosArrowBack } from "react-icons/io";
import { LanguageContext } from "./LanguageContext"; // Import the Language context

const PersonaSetting = () => {
  const { translations, language } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_API_URL;
  const userInfo = useSelector((state) => state.userInfo);
  const [selected, setSelected] = useState({
    gender: null,
    age: null,
    occupation: null,
    relationshipStatus: null,
  });

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  // Pre-select options based on userInfo.mbti
  useEffect(() => {
    if (userInfo.mbti) {
      if (language === "kor") {
        setSelected({
          gender:
            userInfo.sex === "MALE"
              ? "남성"
              : userInfo.sex === "FEMALE"
              ? "여성"
              : "무관",
          age:
            userInfo.age === "TEENS"
              ? "10대"
              : userInfo.age === "20S"
              ? "20대"
              : userInfo.age === "30S"
              ? "30대"
              : userInfo.age === "40S"
              ? "40대"
              : userInfo.age === "50_AND_ABOVE"
              ? "50대 이상"
              : "무관",
          occupation:
            userInfo.job === "STUDENT"
              ? "학생"
              : userInfo.job === "EMPLOYEE"
              ? "직장인"
              : userInfo.job === "FREELANCER"
              ? "프리랜서"
              : userInfo.job === "OTHER"
              ? "기타"
              : "무관",
          relationshipStatus:
            userInfo.love === "SINGLE"
              ? "싱글"
              : userInfo.love === "DATING"
              ? "썸"
              : userInfo.love === "IN_A_RELATIONSHIP"
              ? "연애"
              : userInfo.love === "MARRIED"
              ? "결혼"
              : userInfo.love === "OTHER"
              ? "기타"
              : "무관",
        });
      } else {
        setSelected({
          gender:
            userInfo.sex === "MALE"
              ? "Male"
              : userInfo.sex === "FEMALE"
              ? "Female"
              : "Not Specified",
          age:
            userInfo.age === "TEENS"
              ? "Teens"
              : userInfo.age === "20S"
              ? "20s"
              : userInfo.age === "30S"
              ? "30s"
              : userInfo.age === "40S"
              ? "40s"
              : userInfo.age === "50_AND_ABOVE"
              ? "50 above"
              : "Not Specified",
          occupation:
            userInfo.job === "STUDENT"
              ? "Student"
              : userInfo.job === "EMPLOYEE"
              ? "Employee"
              : userInfo.job === "FREELANCER"
              ? "Freelancer"
              : userInfo.job === "OTHER"
              ? "Other"
              : "Not Specified",
          relationshipStatus:
            userInfo.love === "SINGLE"
              ? "Single"
              : userInfo.love === "DATING"
              ? "Dating"
              : userInfo.love === "IN_A_RELATIONSHIP"
              ? "Relationship"
              : userInfo.love === "MARRIED"
              ? "Married"
              : userInfo.love === "OTHER"
              ? "Other"
              : "Not Specified",
        });
      }
    }
  }, [userInfo]);

  const navigate = useNavigate();

  const handleSelect = (category, value) => {
    setSelected((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const fetchInfo = async (accessToken) => {
    try {
      const response = await fetch(`${apiUrl}/auth/user-info`, {
        method: "GET", // Use DELETE method
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json",
        },
        // You can include a body if required by the API, but often DELETE requests don't have a body
        // body: JSON.stringify({ someData: "value" }),
      });

      if (response.ok) {
        // navigate("/welcome");
        const result = await response.json();
        dispatch(setUserInfo(result));
        //
        // Handle successful deletion, e.g., redirect or show a message
      } else {
        // Handle error response
      }
    } catch (error) {
      // Handle request error
    }
  };

  const updateProfile = async () => {
    const data = {
      userid: userInfo.userid,
      nickname: userInfo.nickname,
      nativeLanguage: userInfo.nativeLanguage,
      targetLanguage: userInfo.targetLanguage,
      mbti: userInfo.mbti,
      sex:
        selected.gender === "남성" || selected.gender === "Male"
          ? "MALE"
          : selected.gender === "여성" || selected.gender === "Female"
          ? "FEMALE"
          : "NOT_SPECIFIED",
      age:
        selected.age === "10대" || selected.age === "Teens"
          ? "TEENS"
          : selected.age === "20대" || selected.age === "20s"
          ? "20S"
          : selected.age === "30대" || selected.age === "30s"
          ? "30S"
          : selected.age === "40대" || selected.age === "40s"
          ? "40S"
          : selected.age === "50대 이상" || selected.age === "50 above"
          ? "50_AND_ABOVE"
          : "NOT_SPECIFIED",
      job:
        selected.occupation === "학생" || selected.occupation === "Student"
          ? "STUDENT"
          : selected.occupation === "직장인" ||
            selected.occupation === "Employee"
          ? "EMPLOYEE"
          : selected.occupation === "프리랜서" ||
            selected.occupation === "Freelancer"
          ? "FREELANCER"
          : selected.occupation === "기타" || selected.occupation === "Other"
          ? "OTHER"
          : "NOT_SPECIFIED",
      love:
        selected.relationshipStatus === "싱글" ||
        selected.relationshipStatus === "Single"
          ? "SINGLE"
          : selected.relationshipStatus === "썸" ||
            selected.relationshipStatus === "Dating"
          ? "DATING"
          : selected.relationshipStatus === "연애" ||
            selected.relationshipStatus === "Relationship"
          ? "IN_A_RELATIONSHIP"
          : selected.relationshipStatus === "결혼" ||
            selected.relationshipStatus === "Married"
          ? "MARRIED"
          : selected.relationshipStatus === "기타" ||
            selected.relationshipStatus === "Other"
          ? "OTHER"
          : "NOT_SPECIFIED",
    };

    try {
      const response = await fetch(`${apiUrl}/auth/complete-onboarding`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });

      if (response.ok) {
        const result = await response.json();

        fetchInfo(accessToken);
        if (sessionStorage.getItem("wherepersona") === "study") {
          sessionStorage.removeItem("wherepersona");
          navigate("/personastudy");
        } else {
          navigate("/accountinfo");
        }
      } else {
        // alert("Failed to update profile.");
      }
    } catch (error) {
      alert("An error occurred while updating the profile.");
    }
  };

  const handleStart = () => {
    const allSelected = Object.values(selected).every(
      (value) => value !== null
    );
    if (allSelected) {
      navigate("/accountinfo", { state: { selected } });
    } else {
      toast.error("모든 옵션을 선택해 주세요.");
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center"
          onClick={handleGoBack}
        >
          <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
          {translations.personasettings1}
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 px-[16px] py-[20px] pb-[20px] flex flex-col gap-[14px]">
        {/* Title and Description */}

        {/* Gender */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
            {translations.personasettings2}
          </div>
          <div className="flex gap-[6px]">
            {[
              translations.personarow1t1,
              translations.personarow1t2,
              translations.personanot,
            ].map((gender) => (
              <div
                key={gender}
                className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                  selected.gender === gender
                    ? darkMode
                      ? "bg-[#fff] border-[#fff] text-[#000]"
                      : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                    : darkMode
                    ? " border-[#e9ecef] text-[#fff]"
                    : " border-[#e9ecef] text-[#000]"
                }`}
                onClick={() => handleSelect("gender", gender)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {gender}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Age Group */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
            {translations.personasettings3}
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {[
              translations.personarow2t1,
              translations.personarow2t2,
              translations.personarow2t3,
              translations.personarow2t4,
              translations.personarow2t5,
              translations.personanot,
            ].map((age) => (
              <div
                key={age}
                className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                  selected.age === age
                    ? darkMode
                      ? "bg-[#fff] border-[#fff] text-[#000]"
                      : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                    : darkMode
                    ? " border-[#e9ecef] text-[#fff]"
                    : " border-[#e9ecef] text-[#000]"
                }`}
                onClick={() => handleSelect("age", age)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {age}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Occupation */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
            {translations.personasettings4}
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {[
              translations.personarow3t1,
              translations.personarow3t2,
              translations.personarow3t3,
              translations.personarow3t4,
              translations.personanot,
            ].map((job) => (
              <div
                key={job}
                className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                  selected.occupation === job
                    ? darkMode
                      ? "bg-[#fff] border-[#fff] text-[#000]"
                      : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                    : darkMode
                    ? " border-[#e9ecef] text-[#fff]"
                    : " border-[#e9ecef] text-[#000]"
                }`}
                onClick={() => handleSelect("occupation", job)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {job}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Relationship Status */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
            {translations.personasettings5}
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {[
              translations.personarow4t1,
              translations.personarow4t2,
              translations.personarow4t3,
              translations.personarow4t4,
              translations.personanot,
            ].map((status) => (
              <div
                key={status}
                className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                  selected.relationshipStatus === status
                    ? darkMode
                      ? "bg-[#fff] border-[#fff] text-[#000]"
                      : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                    : darkMode
                    ? " border-[#e9ecef] text-[#fff]"
                    : " border-[#e9ecef] text-[#000]"
                }`}
                onClick={() => handleSelect("relationshipStatus", status)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {status}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={updateProfile}
        >
          {translations.acsettings9}
        </button>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default PersonaSetting;
