import React, { useEffect } from 'react';
import trashIcon from '../../assets/admin/icons/trash.png';

const QuizEditor = ({ quizzes = [], onQuizzesChange, scriptId, quizTypes = [], onRemoveQuiz, type }) => {
  useEffect(() => {

    const filteredquizzes = quizzes.filter(sp => sp.scriptId === scriptId);

    // If quizzes is empty, initialize with one default quiz
    if (filteredquizzes.length === 0 &&  quizzes.length === 0) {
      onQuizzesChange([{ type: 'blank', question: '', result: '', explanation: '', scriptId: scriptId }]);
    }
  }, [quizzes, onQuizzesChange, scriptId]);

  const handleQuizChange = (index, field, value) => {
    const updatedQuizzes = [...quizzes];
    updatedQuizzes[index] = {
      ...updatedQuizzes[index],
      [field]: value,
      scriptId,
    };
    onQuizzesChange(updatedQuizzes);
  };

  const handleAddQuiz = () => {
    if (quizzes.length < 3) { // 최대 3개의 퀴즈만 추가 가능
      onQuizzesChange([...quizzes, { type: '', question: '', result: '', explanation: '', scriptId }]);
    }
  };

  const handleRemoveQuizClick = (index) => {
    if (onRemoveQuiz) {
      onRemoveQuiz(index);
    } else {
      console.error("onRemoveQuiz is not a function");
    }
  };

  return (
    <div className="mb-4">
      <label
        className="block mb-1"
        style={{
          fontFamily: 'Pretendard Variable',
          fontSize: '14px',
          fontWeight: 700,
          textAlign: 'left',
        }}
      >
        Quiz
      </label>
      <div className="space-y-4">
        {quizzes.map((quiz, index) => (
          <div key={index} className="space-y-2">
            {/* 퀴즈 부분 */}
            <div className="flex items-center space-x-4">
              <label
                className="block w-[36px]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                {`퀴즈${index + 1}`}
              </label>
              <select
                value={quiz.type || ''}
                onChange={(e) => handleQuizChange(index, 'type', e.target.value)}
                className="border p-2 rounded-md"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  height: '48px',
                }}
              >
                <option value="" disabled>
                  구분
                </option>
                {quizTypes.map((option, idx) => (
                  <option key={idx} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="문제를 입력하세요"
                value={quiz.question || ''}
                onChange={(e) => handleQuizChange(index, 'question', e.target.value)}
                className="border p-2 rounded-md flex-grow"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  height: '48px',
                }}
              />
            </div>

            {/* 해설 및 답변 부분 */}
            <div className="flex items-center space-x-4">
              <label
                className="block w-[36px]"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  textAlign: 'left',
                }}
              >
                {`해설${index + 1}`}
              </label>
              <textarea
                placeholder="해설을 입력하세요"
                value={quiz.explanation}
                onChange={(e) => handleQuizChange(index, 'explanation', e.target.value)}
                className="border p-2 rounded-md flex-grow"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  height: '48px',
                }}
              />
              <input
                type="text"
                placeholder={`답변${index + 1}`}
                value={quiz.result}
                onChange={(e) => handleQuizChange(index, 'result', e.target.value)}
                className="border p-2 rounded-md"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  height: '48px',
                }}
              />
              {quizzes.length > 1 && (
                <button
                  type="button"
                  className="p-2 rounded-md"
                  onClick={() => handleRemoveQuizClick(index)}
                >
                  <img src={trashIcon} alt="Delete" className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
        ))}
        {/* 퀴즈 추가하기 버튼 */}
        {quizzes.length < 3 && (
          <div className="flex items-center space-x-4">
            <label
              className="block w-[36px]"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                textAlign: 'left',
              }}
            >
              {`퀴즈${quizzes.length + 1}`}
            </label>
            <button
              type="button"
              className="p-2 rounded-md flex-grow border border-[#A9B6CA] text-[#666B81] text-left"
              onClick={handleAddQuiz}
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 700,
              }}
            >
              퀴즈 추가하기
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizEditor;