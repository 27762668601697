import React, { useState, useEffect } from 'react';
import Navigation from '../../components/admin/Navigation';
import { PlusIcon, XIcon } from '@heroicons/react/solid';
import { fetchTreeNodes, createTreeNode, updateTreeNode, deleteTreeNode } from '../../api/admin/treeNode';

const AdminMeta = () => {
  const [selectedCategory, setSelectedCategory] = useState('정적정보');
  const categories = ['정적정보', 'CUSTOMIZE', 'SITUATION', 'PSYCHOLOGY', 'EXPRESSION'];
  const [treeData, setTreeData] = useState(null);

  // JSON을 트리 데이터 구조로 변환하는 함수
  const convertJsonToTreeData = (json) => {
    return Object.entries(json).map(([key, value]) => ({
      id: key,
      translations: [{ language: 'kor', name: key }],
      children: Object.entries(value).map(([subKey, subValue]) => ({
        id: `${key}-${subKey}`,
        translations: [{ language: 'kor', name: `${subKey}: ${subValue}` }],
      })),
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const categoryId = categories.indexOf(selectedCategory);
      try {
        if (selectedCategory === '정적정보') {
          const response = await fetch('/select_kor_eng.json');
          const staticData = await response.json();
          const convertedData = convertJsonToTreeData(staticData);
          setTreeData({ children: convertedData });
        } else {
          const data = await fetchTreeNodes(categoryId);
          setTreeData(data);
        }
      } catch (error) {
        console.error('트리 노드를 가져오는 중 오류 발생:', error);
      }
    };
    fetchData();
  }, [selectedCategory]);

  // 여기에 트리 노드 관리 함수들을 추가합니다.

  const handleAddTreeNode = async (parentId, korName) => {
    const categoryId = categories.indexOf(selectedCategory);
    // '정적정보'일 경우 빈 값으로 처리
    if (selectedCategory === '정적정보' && !parentId) {
      return;
    }
    try {
      const enName = prompt('영어 이름을 입력하세요:');
      const newNode = await createTreeNode([
        { language: 'eng', name: enName },
        { language: 'kor', name: korName }
      ], parentId || categoryId);
      
      setTreeData(prevData => {
        const updateChildren = (children) => {
          if (!children) return [];
          return children.map(child => {
            if (child.id === parentId) {
              return {
                ...child,
                children: [...(child.children || []), newNode]
              };
            } else if (child.children) {
              return {
                ...child,
                children: updateChildren(child.children)
              };
            }
            return child;
          });
        };

        if (!parentId) {
          return {
            ...prevData,
            children: [...(prevData.children || []), newNode]
          };
        } else {
          return {
            ...prevData,
            children: updateChildren(prevData.children)
          };
        }
      });
    } catch (error) {
      console.error('트리 노드 추가 중 오류 발생:', error);
    }
  };

  const handleUpdateTreeNode = async (id, korName) => {
    try {
      const enName = prompt('새 영어 이름을 입력하세요:');
      const updatedNode = await updateTreeNode(id, [
        { language: 'eng', name: enName },
        { language: 'kor', name: korName }
      ]);
      setTreeData(prevData => ({
        ...prevData,
        children: prevData.children.map(child => 
          child.id === id ? updatedNode : child
        )
      }));
    } catch (error) {
      console.error('트리 노드 수정 중 오류 발생:', error);
    }
  };

  const handleDeleteTreeNode = async (id) => {
    const confirmDelete = window.confirm('정말로 삭제하시겠습니까?');
    if (!confirmDelete) return;

    try {
      await deleteTreeNode(id);
      setTreeData(prevData => {
        const removeNode = (children) => {
          if (!children) return [];
          return children.filter(child => {
            if (child.id === id) {
              return false;
            }
            if (child.children) {
              child.children = removeNode(child.children);
            }
            return true;
          });
        };

        return {
          ...prevData,
          children: removeNode(prevData.children)
        };
      });
    } catch (error) {
      console.error('트리 노드 삭제 중 오류 발생:', error);
    }
  };

  const renderTreeNodes = (nodes, depth = 0) => {
    if (!nodes) return null;

    if (selectedCategory === '정적정보') {
      return (
        <div className="overflow-x-auto whitespace-nowrap pb-4">
          <div className="inline-flex gap-2.5">
            {nodes.map(node => (
              <div key={node.id} className="w-[374px] h-[374px] border rounded-lg p-4 inline-block">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-bold">
                    {node.translations.find(t => t.language === 'kor')?.name || '이름 없음'}
                  </h3>
                </div>
                <div className="overflow-y-auto h-[300px]">
                  <div className="flex flex-wrap gap-2.5">
                    {node.children && node.children.map(child => (
                      <div key={child.id} className="inline-flex items-center justify-between bg-[#E5EDFD] rounded-lg h-[32px] px-4 min-w-[100px] max-w-full">
                        <h3 className="text-[#3677F6] text-xs font-medium truncate mr-2">{child.translations.find(t => t.language === 'kor')?.name || '이름 없음'}</h3>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    if (depth === 1) {
      return (
        <div className="overflow-x-auto whitespace-nowrap pb-4">
          <div className="inline-flex gap-2.5">
            {nodes.map(node => (
              <div key={node.id} className="w-[374px] h-[374px] border rounded-lg p-4 inline-block">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-bold">
                    {node.translations.find(t => t.language === 'kor')?.name || '이름 없음'}
                  </h3>
                  <div className="flex space-x-2">
                    <button
                      className="text-blue-600 text-sm"
                      onClick={() => handleUpdateTreeNode(node.id, prompt('새 이름을 입력하세요:', node.translations.find(t => t.language === 'kor')?.name || '이름 없음'))}
                    >
                      수정
                    </button>
                    <button
                      className="text-red-600 text-sm"
                      onClick={() => handleDeleteTreeNode(node.id)}
                    >
                      삭제
                    </button>
                  </div>
                </div>
                <div className="overflow-y-auto h-[250px]">
                  <div className="flex flex-wrap gap-2.5">
                    {node.children && renderTreeNodes(node.children, depth + 1)}
                  </div>
                </div>
                {/* 3뎁스 노드 추가 버튼 */}
                <button
                  className="w-full py-2 text-[#383C4B] border border-[#666B81] rounded-lg"
                  onClick={() => handleAddTreeNode(node.id, prompt('새 노드의 이름을 입력하세요:'))}
                >
                  추가하기 +
                </button>
              </div>
            ))}
          </div>
        </div>
      );
    }

    if (depth === 2) {
      return (
        <>
          {nodes.map(node => (
            <div key={node.id} className="inline-flex flex-col items-start bg-[#3677F6] rounded-lg p-2 min-w-[120px] max-w-full">
              <div className="flex items-center justify-between w-full">
                <h3 className="text-white text-sm font-medium truncate mr-2">{node.translations.find(t => t.language === 'kor')?.name || '이름 없음'}</h3>
                <div className="flex items-center">
                  <button
                    className="text-white flex-shrink-0"
                    onClick={() => handleDeleteTreeNode(node.id)}
                  >
                    X
                  </button>
                </div>
              </div>
              {node.children && node.children.length > 0 && (
                <div className="mt-2 pl-2">
                  {renderTreeNodes(node.children, depth + 1)}
                </div>
              )}
            </div>
          ))}
        </>
      );
    }

    // 3뎁스 노드 렌더링
    if (depth === 3) {
      return (
        <>
          {nodes.map(node => (
            <div key={node.id} className="inline-flex items-center justify-between bg-[#E5EDFD] rounded-lg h-[32px] px-4 min-w-[100px] max-w-full">
              <h3 className="text-[#3677F6] text-xs font-medium truncate mr-2">{node.translations.find(t => t.language === 'kor')?.name || '이름 없음'}</h3>
              <button
                className="text-[#3677F6] flex-shrink-0"
                onClick={() => handleDeleteTreeNode(node.id)}
              >
                X
              </button>
            </div>
          ))}
        </>
      );
    }

    return nodes.map(node => (
      <div key={node.id} className="mb-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-bold text-xl mb-2">{node.translations.find(t => t.language === 'kor')?.name || '이름 없음'}</h3>
          <div className="flex space-x-2">
            <button
              className="text-blue-600 text-sm"
              onClick={() => handleUpdateTreeNode(node.id, prompt('새 이름을 입력하세요:', node.translations.find(t => t.language === 'kor')?.name || '이름 없음'))}
            >
              수정
            </button>
            <button
              className="text-red-600 text-sm"
              onClick={() => handleDeleteTreeNode(node.id)}
            >
              삭제
            </button>
          </div>
        </div>
        {node.children && node.children.length >= 0 && (
          <div className="overflow-x-auto pb-6 border-b border-[#E2E8EF]">
            <div className="flex">
              <div className="pl-4 flex-1 min-w-[374px]">
                {renderTreeNodes(node.children, depth + 1)}
                {/* 2뎁스 노드 추가 버튼 */}
                <div className="w-[374px] h-[374px] border-2 border-dashed rounded-lg flex items-center justify-center inline-block">
                  <button
                    className="text-blue-600"
                    onClick={() => handleAddTreeNode(node.id, prompt('새 노드의 이름을 입력하세요:'))}
                  >
                    추가하기 +
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      
      <div className="bg-white rounded-lg p-6 shadow-md">
        {/* 카테고리 선택 버튼들 */}
        <div className="flex mb-6 border-b border-[#E2E8EF]">
          {categories.map(category => (
            <button
              key={category}
              className={`mr-4 px-4 py-2 ${selectedCategory === category ? 'text-[#0E111F] font-bold text-left border-b-2 border-blue-600' : 'text-gray-500'}`}
              style={selectedCategory === category ? { fontFamily: 'Pretendard', fontSize: '18px', fontWeight: '700', textAlign: 'left' } : {}}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* 트리 노드 렌더링 */}
        <div className="grid grid-cols-1">
          {renderTreeNodes(treeData?.children)}
        </div>

        {/* 새 노드 추가 버튼 (정적정보일 때는 숨김) */}
        {selectedCategory !== '정적정보' && (
          <div className="mt-6">
            <button
              className="w-full py-3 text-[#383C4B] rounded-lg border border-[#666B81] font-bold text-left px-4 flex items-center"
              style={{
                fontFamily: 'Pretendard, sans-serif',
                fontSize: '18px',
              }}
              onClick={() => handleAddTreeNode(null, prompt('새 노드의 이름을 입력하세요:'))}
            >
              추가하기
              <PlusIcon className="w-5 h-5 mr-2" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminMeta;
