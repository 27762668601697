import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GnbExpress from "./components/GnbExpress";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { LanguageContext } from "./LanguageContext";
import { CgSortAz } from "react-icons/cg";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary
import RingLoader from "react-spinners/RingLoader";

const Express = () => {
  // 카테고리 번역을 위한 객체 추가
  const categoryTranslations = {
    expression: {
      "일상 표현": "Daily Expression",
      "비즈니스 표현": "Business Expression",
      // 필요한 다른 카테고리 추가
    },
  };
  const { language, translations } = useContext(LanguageContext);
  const displayLanguage = language === "kor" ? "eng" : "kor"; // 표시 언어를 반대로 설정
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectKorEng, setSelectKorEng] = useState({});
  const [selectedGender, setSelectedGender] = useState([]);
  const observer = useRef();
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: "latest",
    direction: "ascending",
  });
  const [isAcademicEnglishOn, setIsAcademicEnglishOn] = useState(
    sessionStorage.getItem("ae") === "true"
  );

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchData = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/expressions/managerFilter?page=${page}&limit=10${
          selectedGender
            ? `&category=${
                selectedGender.length > 1
                  ? selectedGender.join(",")
                  : selectedGender.length > 0
                  ? selectedGender[0]
                  : ""
              }`
            : ""
        }&academic_english=${isAcademicEnglishOn ? "true" : "false"}`
      );

      const result = await response.json();
      console.log("선택입니다 ", selectedGender);
      if (result.data.length > 0) {
        setData((prevData) => {
          const newData = [...prevData, ...result.data];
          const uniqueData = newData.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
          return sortData(uniqueData);
        });
        setHasMore(result.data.length === 10);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error("데이터를 가져오는 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  }, [page, isAcademicEnglishOn, selectedGender]);

  const sortData = useCallback(
    (dataToSort) => {
      const sortedData = [...dataToSort];
      if (sortConfig.key === "latest") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? a.id - b.id
            : b.id - a.id;
        });
      } else if (sortConfig.key === "alphabetical") {
        sortedData.sort((a, b) => {
          const aTitle = getExpressionByLanguage(
            a.expressions,
            displayLanguage
          ).expressive_language;
          const bTitle = getExpressionByLanguage(
            b.expressions,
            displayLanguage
          ).expressive_language;
          return sortConfig.direction === "ascending"
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle);
        });
      }
      return sortedData;
    },
    [sortConfig, displayLanguage]
  );

  const fetchSelectKorEng = async () => {
    try {
      const response = await fetch("/select_kor_eng.json");
      const result = await response.json();

      setSelectKorEng(result);
    } catch (error) {
      toast.error(
        "select_kor_eng.json 파일을 불러오는 중 오류가 발생했습니다."
      );
    }
  };

  useEffect(() => {
    fetchData();
    fetchSelectKorEng();
  }, [fetchData]);

  const formatEnglishCategory = (category) => {
    return category
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getCategoryName = (category) => {
    if (selectKorEng.expression) {
      const entries = Object.entries(selectKorEng.expression);
      for (const [kor, eng] of entries) {
        if (eng === category) {
          return language === "kor" ? kor : formatEnglishCategory(eng);
        }
      }
    }
    return language === "kor" ? category : formatEnglishCategory(category);
  };

  const handleItemClick = (expressionId) => {
    navigate(`/vocablist/${expressionId}`);
  };

  // 언어에 따른 표현을 찾는 함수
  const getExpressionByLanguage = (expressions, lang) => {
    return expressions.find((exp) => exp.language === lang) || expressions[0];
  };

  // overlay
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = [
    "FIXED_EXPRESSIONS",
    "IDIOMS",
    "PHRASAL_VERBS",
    "WORDS",
    "GRAMMATICAL_STRUCTURES",
    "PROVERBS",
    "IDIOMATIC_EXPRESSIONS",
    "SLANG",
    "NEOLOGISMS",
    "ABBREVIATIONS",
  ];

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) => {
        if (prevSelectedGender.includes(value)) {
          // Remove value if it's already selected
          return prevSelectedGender.filter((item) => item !== value);
        } else {
          // Add value if it's not selected
          return [...prevSelectedGender, value];
        }
      });
    }
  };

  const handleComplete = () => {
    setPage(1); // 페이지를 1로 리셋
    setData([]); // 기존 데이터 초기화
    setHasMore(true); // hasMore 리셋
    toggleOverlay();
    fetchData(); // 새로운 필터로 데이터 가져오기
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleAcademicEnglish = () => {
    setIsAcademicEnglishOn((prev) => !prev);
    sessionStorage.setItem("ae", !isAcademicEnglishOn);
    setPage(1);
    setData([]);
    setHasMore(true);
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  useEffect(() => {
    setData((prevData) => sortData(prevData));
  }, [sortConfig, sortData]);

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  const handleSwipeLeft = () => {
    navigate("/ai");
  };

  const handleSwipeRight = () => {
    navigate("/study");
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);

  //overlay end
  return (
    <div
      id="swipe-container"
      className="flex justify-center w-full bg-[#faf8f6] dark:bg-[#121418]"
    >
      <div
        className="relative w-[100%] bg-[#faf8f6] dark:bg-[#121418]  overflow-y-auto"
        style={{
          height: "100vh",
        }}
      >
        <GnbExpress />
        <div className="relative w-full h-full flex justify-center">
          <div className="flex-grow relative w-full">
            {/* 헤더 추가 */}
            <div className="fixed top-0 left-0 right-0 flex items-center h-[50px] px-[14px] py-[7px] bg-[#faf8f6] dark:bg-[#121418] z-10">
              <div className="absolute left-1/2 transform -translate-x-1/2 font-sans text-[16px] font-bold cursor-pointer dark:text-[#fff]">
                {translations.EP1}
              </div>
              <div className="absolute right-[16px] flex items-center">
                <div
                  className="w-[30px] h-[30px] mr-[8px] flex items-center justify-center"
                  onClick={toggleOverlay}
                >
                  <CgSortAz
                    size={30}
                    className=""
                    color={darkMode ? "white" : "black"}
                  ></CgSortAz>
                </div>
                {darkMode ? (
                  <LanguageSwitcher2></LanguageSwitcher2>
                ) : (
                  <LanguageSwitcher></LanguageSwitcher>
                )}
              </div>
            </div>

            {/* 정렬 옵션 */}
            <div className="mt-[60px] px-[16px] flex justify-between items-center">
              {/* Academic English 버튼 */}
              <div
                className={`rounded-[11px] h-[18px] px-[6px] py-[8px] items-center justify-center flex cursor-pointer ${
                  isAcademicEnglishOn ? "bg-[#F67239]" : "bg-[#E2E8EF]"
                }`}
                onClick={toggleAcademicEnglish}
              >
                <span
                  className={`text-[10px] font-bold ${
                    isAcademicEnglishOn ? "text-[#fff]" : "text-[#383C4B]"
                  }`}
                >
                  Academic English {isAcademicEnglishOn ? "ON" : "OFF"}
                </span>
              </div>

              {/* 정렬 옵션 */}
              <div className="flex flex-row items-center gap-[5px]">
                <div
                  className={`text-[10px] leading-[100%] font-sans font-medium ${
                    sortConfig.key === "latest"
                      ? "text-[#3677f6]"
                      : "text-[#666b81]"
                  } text-right whitespace-nowrap cursor-pointer`}
                  onClick={() => handleSort("latest")}
                >
                  {translations.ORDER_DATE}{" "}
                  {sortConfig.key === "latest" &&
                    (sortConfig.direction === "ascending" ? "↓" : "↑")}
                </div>
                <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
                <div
                  className={`text-[10px] leading-[100%] font-sans font-medium ${
                    sortConfig.key === "alphabetical"
                      ? "text-[#3677f6]"
                      : "text-[#666b81]"
                  } whitespace-nowrap cursor-pointer`}
                  onClick={() => handleSort("alphabetical")}
                >
                  {translations.ORDER_AL}{" "}
                  {sortConfig.key === "alphabetical" &&
                    (sortConfig.direction === "ascending" ? "↓" : "↑")}
                </div>
              </div>
            </div>

            {/* 본문 */}
            <div className="mt-[10px] pb-[20px]">
              {data.map((item, index) => (
                <div
                  key={`${item.id}-${index}`}
                  ref={index === data.length - 1 ? lastElementRef : null}
                  className="mb-[16px] mx-[16px] p-[16px] bg-white dark:bg-[#22232a] rounded-lg shadow-sm"
                  onClick={() => handleItemClick(item.id)}
                >
                  {/* Academic English 및 카테고리 */}
                  <div className="flex flex-wrap gap-[6px] mb-[8px]">
                    {item.academic_english && (
                      <div className="bg-[#FFEEE7] rounded-[11px] h-[18px] px-[6px] py-[4px] items-center justify-center flex">
                        <span className="text-[10px] font-medium text-[#F67239]">
                          Academic English
                        </span>
                      </div>
                    )}
                    {item.category && (
                      <div className="bg-[#E2E8EF] rounded-[11px] h-[18px] px-[6px] py-[4px] items-center justify-center flex">
                        <span className="text-[10px] font-medium text-[#383C4B]">
                          {getCategoryName(item.category)}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* 표현 및 의미 */}
                  <div className="text-left">
                    <h2 className="font-[Pretendard] font-[700] text-[14px] dark:text-[#fff]">
                      {
                        getExpressionByLanguage(
                          item.expressions,
                          displayLanguage
                        ).expressive_language
                      }
                    </h2>
                    <p className="font-[Pretendard] font-[500] text-[14px] dark:text-[#fff]">
                      {
                        getExpressionByLanguage(item.expressions, language)
                          .expressive_language_mean
                      }
                    </p>
                  </div>

                  {/* 태그 */}
                  {item.tags && item.tags.length > 0 && (
                    <div className="font-[Pretendard] font-[500] text-[12px] text-[#A9B6CA] mt-[8px]">
                      {item.tags.join(", ")}
                    </div>
                  )}
                </div>
              ))}
              {loading && (
                <div className="flex justify-center items-center h-screen">
                  <RingLoader color="#2986dd" size={75} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Overlay */}
      <div
        ref={overlayRef}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "80%",
          zIndex: 100,
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
        }}
      >
        {/* Title of the overlay */}
        <div className="p-4">
          <h2 className="text-lg font-bold">{translations.filter1}</h2>
        </div>
        {/* Content of the overlay */}
        <div className="flex flex-wrap gap-[6px] justify-center">
          {typeList.map((gender) => (
            <div
              key={gender}
              className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                selectedGender.includes(gender)
                  ? "border-[#3677f6] text-[#3677f6]"
                  : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
              }`}
              onClick={() => handleSelect("gender", gender)}
              style={{ flexBasis: "calc(33.333% - 6px)" }}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                {getCategoryName(gender)}
              </div>
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className="fixed bottom-0 w-full">
          <div className="flex-none pb-[20px] px-[16px]">
            <button
              className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
              onClick={handleComplete}
            >
              {translations.filter2}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Express;
