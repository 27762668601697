import PropTypes from "prop-types";
import { useState } from "react";
import styles from "./PhoneVerification.module.css";
import { useNavigate } from "react-router-dom";

const PhoneVerification = ({
  className = "",
  disabled,
  value,
  darkMode,
  email,
  text1,
  text2,
}) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  // Function to format phone number
  const formatPhoneNumber = (value) => {
    // Remove non-digit characters
    const cleaned = value.replace(/\D/g, "");
    // Split into parts
    const match = cleaned.match(/(\d{1,3})(\d{0,4})?(\d{0,4})?/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join("-");
    }
    return value;
  };
  // Function to format verif number
  const formatVerificationNumber = (value) => {
    // Remove non-digit characters
    const cleaned = value.replace(/\D/g, "");
    // Split into parts
    // const match = cleaned.match(/(\d{1,3})(\d{0,4})?(\d{0,4})?/);
    // if (match) {
    //   return [match[1], match[2], match[3]].filter(Boolean).join("-");
    // }
    return cleaned;
  };

  // Handle phone number input change
  const handlePhoneNumberChange = (event) => {
    let value = event.target.value;
    // Sanitize input to remove any non-digit characters
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    // Format the number and update state
    setPhoneNumber(formatPhoneNumber(sanitizedValue));
  };

  // Handle verification code input change
  const handleVerificationCodeChange = (event) => {
    let value = event.target.value;
    // Sanitize input to remove any non-digit characters
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    // Format the number and update state
    setVerificationCode(formatVerificationNumber(sanitizedValue));
  };

  return (
    <div className={[styles.textField, className].join(" ")}>
      <b className={styles.b}>
        <span className={darkMode ? "text-[#fff]" : ""}>{text1}</span>
      </b>
      <div className={styles.field}>
        <input
          className={`${styles.field1} ${
            darkMode ? "bg-[#22232a] text-[#464a5a] border-none" : ""
          }`}
          placeholder=""
          type="text"
          value={value}
          onChange={handlePhoneNumberChange}
          maxLength="13"
          disabled={disabled}
        />
        {/* </div> */}
        <div
          className={styles.field2}
          onClick={() => {
            sessionStorage.setItem("userIdforP", email);
            sessionStorage.setItem("whereforP", "rs");
            navigate("/lost-pw1");
          }}
        >
          <b className={styles.b1}>{text2}</b>
        </div>
      </div>
    </div>
  );
};

PhoneVerification.propTypes = {
  className: PropTypes.string,
};

export default PhoneVerification;
