import axios from "axios";
import { useSelector } from "react-redux";
const apiUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: "https://pastellapp.com/api",
  timeout: 10000, // Optional: Set a timeout to avoid hanging requests
});

let isRefreshing = false; // Flag to prevent multiple refresh requests
let refreshSubscribers = []; // Queue to hold pending requests during refresh

// Function to add subscribers (requests) while refreshing token
const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

// Notify all subscribers about the new token
const onRefreshed = (newToken) => {
  refreshSubscribers.forEach((cb) => cb(newToken));
  refreshSubscribers = [];
};

// Interceptor to check if token is expired
api.interceptors.request.use(
  async (config) => {
    // Ensure the interceptor is triggered

    let accessToken = sessionStorage.getItem("accessToken");

    if (!accessToken) {
      return config; // Allow the request to proceed without the token
    }

    let tokenExpiry = getExpiryFromAccessToken(accessToken); // Extract the expiry time from token

    const currentTime = Math.floor(Date.now() / 1000);

    // Check if token is expired
    if (isTokenExpired(tokenExpiry)) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const data = await refreshAccessToken(); // Try to refresh the token
          if (data) {
            sessionStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("refreshToken", data.refreshToken);
            accessToken = data.accessToken;
            console.log(
              "Token refresh complete. New access token:",
              accessToken
            );

            // Notify all subscribers about the new token
            onRefreshed(accessToken);
          } else {
            return Promise.reject(new Error("Failed to refresh token"));
          }
        } catch (error) {
          return Promise.reject(error); // Propagate the error
        } finally {
          isRefreshing = false; // Reset the flag after refresh attempt
        }
      } else {
        console.log(
          "Token refresh is already in progress, queuing the request."
        );
      }

      // Queue pending requests until the token refresh is done
      return new Promise((resolve) => {
        subscribeTokenRefresh((newToken) => {
          config.headers.Authorization = `Bearer ${newToken}`;
          resolve(config);
        });
      });
    } else {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to check if the token is expired
function isTokenExpired(exp) {
  const currentTime = Math.floor(Date.now() / 1000);
  const bufferTime = 0; // 5 minutes buffer before expiration
  console.log(
    "Buffer time check. Expiration with buffer:",
    exp,
    " Current + Buffer:",
    currentTime + bufferTime
  );
  return exp <= currentTime + bufferTime; // Trigger refresh 5 minutes before expiration
}

// Function to extract the expiry time from the access token (JWT)
function getExpiryFromAccessToken(token) {
  if (!token) return null;
  try {
    const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT to get the payload

    return payload.exp;
  } catch (error) {
    return null;
  }
}

// Function to refresh the access token using the refresh token
async function refreshAccessToken() {
  try {
    const response = await fetch(`${apiUrl}/auth/refresh-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userid: localStorage.getItem("userid"), // Pass userId or other required params
        refreshToken: localStorage.getItem("refreshToken"),
      }),
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export default api;
