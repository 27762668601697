import React from 'react';
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi"; // Example for a modern icon

const PersonalInformation = () => {
    const navigate = useNavigate();
    return (
        <div className='container mx-auto p-4'>
            <div className="flex items-center justify-between h-[60px] px-4">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center cursor-pointer"
          onClick={()=>{navigate(-1)}}
        >
          <FiArrowLeft size={20} />
        </div>
        <div className="text-xl font-bold tracking-wide text-gray-700">
          개인정보 처리방침
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center opacity-0">
          {/* Empty to balance layout */}
        </div>
      </div>

            <section className='mb-8'>
                <textarea
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap" 
                    readOnly
                    value={`(주)이지브레인소프트(또는 “회사”)는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
○ 본 방침은 2024년 09월 12일부터 시행됩니다.`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>1장. 개인정보의 처리 목적</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`개인정보는 생존하는 개인에 관한 정보로서 실명 등의 사항으로 회사 회원을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 회사가 수집한 개인정보는 다음의 목적을 위해 활용합니다.
회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 예정입니다.
가. 회원가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지, 고충처리 등을 목적으로 개인정보를 처리합니다.
나. 민원사무 처리
민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.
다. 재화 또는 서비스 제공
서비스 제공, 콘텐츠 제공, 본인인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.
라. 마케팅 및 광고에의 활용
신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>2장. 개인정보 항목 및 수집방법</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`개인정보는 생존하는 개인에 관한 정보로서 실명 등의 사항으로 회사 회원 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 회사가 수집한 개인정보는 다음의 목적을 위해 활용합니다.
수집하는 개인정보 항목
1) 최초 회원가입시 회원식별 및 최적화된 서비스 제공을 위해 아래와 같은 정보를 수집합니다.
- 필수항목 : 아이디, 비밀번호, 휴대전화번호, 이메일주소, 닉네임
- 선택항목 : 이름, 성별, 생년월일, 주소, 전화번호
2) 서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
- 서비스 이용기록, 접속로그, 쿠키, 접속 IP 정보, 컴퓨터 기기 정보, 휴대전화 기기 정보, 결제기록, 불량이용 기록
- 실명인증정보
수집방법
1) 회원가입 ,경품 행사응모, 배송 요청
2) 생성정보 수집 툴을 통한 수집`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>3장. 개인정보의 처리 및 보유기간</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보유합니다.
회원탈퇴 개인정보
- 보유항목 : 이름, 아이디, 이메일 주소, 전화번호
- 보유근거 : 불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및 수사협조
- 보유기간 : 회원탈퇴 후 1년`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>4장. 정보주체의 권리, 의무 및 그 행사방법 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
1. 개인정보 열람요구
2. 오류 등이 있을 경우 정정 요구
3. 삭제요구
4. 처리정지 요구
② 제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>5장. 처리하는 개인정보의 항목 작성</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`① 회사는 다음의 개인정보 항목을 처리하고 있습니다.
1. 일반 회원가입 및 관리
- 필수항목 : ID, 비밀번호, 이름, 휴대폰번호, 서비스 이용 기록, 접속 로그, 쿠키
- 선택항목 : 추천인 계정
2. 소셜 회원가입 및 관리 (카카오, 애플)
- 필수항목 : 소셜 ID, 이름, 휴대폰번호, 서비스 이용 기록, 접속 로그, 쿠키
- 선택항목 : 추천인 정보
3. 컨텐츠 이용
- 필수항목 : 닉네임, 언어, MBTI, 성별, 연령대, 직업, 혼인여부
4. 민원사무 처리
- 필수항목 : 이메일, 서비스 이용 기록, 접속 로그, 쿠키
- 선택항목 : 휴대전화번호, 이름`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>6장. 개인정보의 파기</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
-파기절차이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.-파기기한이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
-파기방법
전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>7장. 개인정보의 안전성 확보 조치 (주)이지브레인소프트는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`1. 정기적인 자체 감사 실시
개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
2. 개인정보 취급 직원의 최소화 및 교육
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
3. 내부관리계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
4. 해킹 등에 대비한 기술적 대책
(주)이지브레인소프트는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
5. 개인정보의 암호화
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
6. 접속기록의 보관 및 위변조 방지
개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
7. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
8. 문서보안을 위한 잠금장치 사용
개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
9. 비인가자에 대한 출입 통제
개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>8장. 개인정보 보호책임자 작성</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
▶ 개인정보 보호책임자
성명 :박정한
직책 :과장
직급 :과장
연락처 : ezbrainsoft@gmail.com
※ 개인정보 보호 담당부서로 연결됩니다.
② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
개인정보주체는 개인정보침해로 인한 피해를 구제 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다.
개인정보 분쟁조정위원회 : (국번없이) 118 (www.kopico.go.kr)
개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
대검찰청 사이버범죄수사단 : 02-3480-3571 (cybercid@spo.go.kr)
경찰청 사이버안전국 : (국번없이) 182 (cyberbureau.police.go.kr)`}
                />
            </section>

            <section className='mb-8'>
                <h2 className='text-2xl font-semibold mb-2'>9장. 개인정보 처리방침 변경</h2>
                <textarea 
                    className="w-full p-4 border border-gray-300 rounded-lg resize-none h-40 text-sm md:text-base bg-gray-100 focus:outline-none whitespace-pre-wrap"
                    readOnly
                    value={`이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.`}
                />
            </section>
        </div>
    );
}

export default PersonalInformation;