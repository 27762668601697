import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import FileUploader from './FileUploader';
import LanguageSelector from './LanguageSelector';
import ScriptEditor from './ScriptEditor';
import QuizEditor from './QuizEditor';
import StudyPointEditor from './StudyPointEditor';
import TreeNodeSelector from './CustomizeTreeNodeSelector';
import plusIcon from '../../assets/admin/icons/plus.png';

const CustomizeForm = ({ 
    mode,
    tagOptions,
    manageData, 
    setManageData,
    onManageChange, 
    connectData,
    onConnectChange, 
    handleTitleChange,
    onImageUpload,
    onFileDelete, 
    scriptData,
    onScriptChange,
    studyPointsData,
    onStudyPointsChange,
    onRemoveStudyPoint,
    quizzesData,
    onQuizzesChange,
    quizTypes,
    onRemoveQuiz,
    languageSections,
    setLanguageSections,
    onTagsChange,
    initialTags,
    visibleLanguages,
    setVisibleLanguages,
    onLanguageReset,
    onAddLanguage
}) => {

  const [translationData, setTranslationData] = useState(null);

  useEffect(() => {
    const loadTranslationData = async () => {
      try {
        const response = await fetch('/select_kor_eng.json');
        const data = await response.json();
        setTranslationData(data);
      } catch (error) {
        console.error('Error loading translation data:', error);
      }
    };
  
    loadTranslationData();
  }, []);

  const [activeTabs, setActiveTabs] = useState({
    kor: 'easy',
    eng: 'easy',
    jpn: 'easy'
  });

  const handleTabChange = (language, type) => {
    setActiveTabs({
      ...activeTabs,
      [language]: type,
    });
  };

  // 선택된 한글 값을 영어로 변환하여 저장하는 함수
  const handleManageInputChange = (e) => {
    const { name, value } = e.target;
  
    let englishValue = value;  // 기본적으로는 value가 그대로 들어갑니다.
  
    // translationData가 로드되었고 해당 name에 매핑되는 값이 존재하는지 확인
    if (translationData && translationData[name]) {
      // 한글 값이 영어로 변환 가능한지 확인 후 변환
      englishValue = translationData[name][value] || value; 
    }
  
    onManageChange({ [name]: englishValue });
  };

  const getKoreanLabel = (name, englishValue) => {
    if (translationData && name in translationData) {
      return Object.keys(translationData[name]).find(key => translationData[name][key] === englishValue) || englishValue;
    }
    return englishValue;
  };

  const handleImageUpload = (url) => {
    onManageChange({ imageurl: url });
  };

  const handleLanguageChange = (language) => {
    onConnectChange(prevState => ({
        ...prevState,
        [language]: {
            ...prevState[language],
            language: language
        }
    }));
  };

  const getTextColorClass = (value) => {
    return value === '' ? 'text-[#A9B6CA]' : 'text-[#000000]';
  };

  const handleLanguageReset = (language) => {
    onLanguageReset(language);
  };

  return (
    <div className="min-h-screen" style={{ backgroundColor: '#FAF8F6' }}>
      <div 
        style={{
          padding: '26px 30px', // 위아래 26px, 좌우 30px 여백을 줍니다.
        }}
      >
        {/* 제목 표시 */}
        <h1 
          className="font-bold uppercase"
          style={{
            width: '140px',
            height: '28px',
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontWeight: 700,
            textAlign: 'left',
          }}
        >
          CUSTOMIZE
        </h1>
        {/* manage 영역 */}
        <div 
          className="bg-white p-5 rounded-lg shadow-md"
          style={{
            margin: '26px 0px 20px 0', // 위 26px, 아래 20px 여백을 줍니다.
          }}  
        >
          {/* COVER Section */}
          <div>
            <label 
              className="block"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontWeight: 700,
                textAlign: 'left',
              }}
            >
              COVER
            </label>
            <div className="mt-4 flex items-center space-x-6">
              <div className="flex-grow">
                {/* 관리자 제목 */}
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  관리자 제목
                </label>
                <input
                  type="text"
                  name="title"
                  placeholder="표제를 입력해 주세요."
                  value={manageData.title}
                  onChange={handleManageInputChange}
                  className="border h-[48px] w-full"
                  style={{
                    padding: '18px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                  }}
                />
              </div>

              {/* 출력 여부 */}
              <div className="w-48">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  출력 여부
                </label>
                <select
                  value={manageData.used ? 'USED' : 'UNUSED'}
                  onChange={(e) => onManageChange({ used: e.target.value === 'USED' })}
                  className="border h-[48px] w-full"
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="USED">출력</option>
                  <option value="UNUSED">비출력</option>
                </select>
              </div>
              
              {/* 시리얼 넘버 */}
              <div className="w-48">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  시리얼 넘버
                </label>
                <input
                  type="text"
                  name="serialnumber"  // 여기에 적절한 name 속성이 필요합니다.
                  value={manageData.serialnumber || ''}
                  onChange={handleManageInputChange}
                  className="border h-[48px] w-full bg-gray-200"
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#666B81',
                  }}
                />
              </div>
            </div>
          </div>

          {/* TAG Section */}
          <div className="mb-4 mt-4">
            <label 
              className="block"
              style={{
                fontFamily: 'Pretendard',
                fontSize: '18px',
                fontWeight: 700,
                textAlign: 'left',
              }}
            >
              TAG
            </label>
            <div className="mt-4 flex items-center space-x-6">
              {/* MBTI */}
              <div className="w-1/6">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  MBTI
                </label>
                <select
                  name="mbti"
                  value={getKoreanLabel('mbti', manageData.mbti) || ''}
                  onChange={handleManageInputChange}
                  className={`border h-[48px] w-full ${getTextColorClass(manageData.mbti)}`}
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="" >선택</option>
                  {translationData && Object.keys(translationData.mbti).map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {/* SEX */}
              <div className="w-1/6">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  성별
                </label>
                <select
                  name="sex"
                  value={getKoreanLabel('sex', manageData.sex) || ''}
                  onChange={handleManageInputChange}
                  className={`border h-[48px] w-full ${getTextColorClass(manageData.sex)}`}
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="">선택</option>
                  {translationData && Object.keys(translationData.sex).map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {/* AGE */}
              <div className="w-1/6">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  나이
                </label>
                <select
                  name="age"
                  value={getKoreanLabel('age', manageData.age) || ''}
                  onChange={handleManageInputChange}
                  className={`border h-[48px] w-full ${getTextColorClass(manageData.age)}`}
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="">선택</option>
                  {translationData && Object.keys(translationData.age).map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {/* JOB */}
              <div className="w-1/6">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  직업
                </label>
                <select
                  name="job"
                  value={getKoreanLabel('job', manageData.job) || ''}
                  onChange={handleManageInputChange}
                  className={`border h-[48px] w-full ${getTextColorClass(manageData.job)}`}
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="">선택</option>
                  {translationData && Object.keys(translationData.job).map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {/* LOVE */}
              <div className="w-1/6">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  연애상태
                </label>
                <select
                  name="love"
                  value={getKoreanLabel('love', manageData.love) || ''}
                  onChange={handleManageInputChange}
                  className={`border h-[48px] w-full ${getTextColorClass(manageData.love)}`}
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="">선택</option>
                  {translationData && Object.keys(translationData.love).map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {/* Counter MBTI */}
              <div className="w-1/6">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  counter MBTI
                </label>
                <select
                  name="partnermbti"
                  value={getKoreanLabel('partnermbti', manageData.partnermbti) || ''}
                  onChange={handleManageInputChange}
                  className={`border h-[48px] w-full ${getTextColorClass(manageData.partnermbti)}`}
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="">선택</option>
                  {translationData && Object.keys(translationData.partnermbti).map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {/* Culture */}
              <div className="w-1/6">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  문화
                </label>
                <select
                  name="countrycode"
                  value={getKoreanLabel('countrycode', manageData.countrycode) || ''}
                  onChange={handleManageInputChange}
                  className={`border h-[48px] w-full ${getTextColorClass(manageData.countrycode)}`}
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  <option value="">선택</option>
                  {translationData && Object.keys(translationData.countrycode).map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {/* HOBBY */}
              <div className="w-1/6">
                <label 
                  className="block mb-2.5"
                  style={{
                    fontFamily: 'Pretendard Variable',
                    fontSize: '14px',
                    fontWeight: 700,
                    textAlign: 'left',
                  }}
                >
                  취미
                </label>
                <input
                  type="text"
                  name="hobby"
                  placeholder="입력"
                  value={manageData.hobby}
                  onChange={handleManageInputChange}
                  className="border h-[48px] w-full"
                  style={{
                    padding: '4px 14px',
                    borderRadius: '12px',
                    border: '1px solid #E2E8EF',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                />
              </div>
            </div>
            {/* TAG Node Section */}
            <TreeNodeSelector 
              mode={mode}
              manageData={manageData}
              onTagsChange={onTagsChange}
            />
          </div>
        
          {/* FILE Section */}
          <FileUploader 
            fileUrl={manageData.imageurl}
            onFileChange={onImageUpload}
            onFileUpload={handleImageUpload}
            onFileDelete={onFileDelete}
            link={manageData.link}
            memo={manageData.memo}
            onLinkChange={(value) => onManageChange({ link: value })}
            onMemoChange={(value) => onManageChange({ memo: value })}
          />
        </div>
        {/* CONNECT Sections */}
        {visibleLanguages.map((language) => (
          <div key={language} className="mt-5 bg-white p-6 rounded-lg shadow-md">
            <LanguageSelector
                key={language}
                languages={[language]}
                selectedLanguage={language}
                selectedTitle={connectData[language]?.title || ''}
                onLanguageChange={(newLang) => handleLanguageChange(language, newLang)}
                onTitleChange={(newTitle) => handleTitleChange(language, newTitle)}
                onLanguageReset={() => handleLanguageReset(language)}
                onAddLanguage={onAddLanguage}
                visibleLanguages={visibleLanguages}
            />
            
            {/* Easy, Normal, Difficult Sections */}
            <div className="flex justify-between space-x-4">
              {scriptData && scriptData[language] && ['easy', 'normal', 'difficult'].map((type) => (
                <div key={type} className="flex-grow">
                  {/* Section Header */}
                  <h2
                    className="text-lg font-bold text-center mb-6 relative after:content-[''] after:absolute after:left-0 after:bottom-[-10px] after:w-full after:h-[2px] after:bg-[#E2E8EF]"
                    style={{
                      fontFamily: 'Pretendard',
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    {type.toUpperCase()}
                  </h2>
                  
                  {/* Script Editor */}
                  <div>
                    <ScriptEditor 
                      script={scriptData[language]?.[type] || {}}
                      onScriptChange={onScriptChange}
                      language={language}
                      type={type}
                    />
                  </div>

                  {/* Study Points */}
                  <div>
                    <StudyPointEditor 
                      studyPoints={studyPointsData[language]?.[type] || []} 
                      onStudyPointsChange={(updatedStudyPoints) => onStudyPointsChange(language, type, updatedStudyPoints)}
                      onRemoveStudyPoint={(index) => onRemoveStudyPoint(language, type, index)} 
                      scriptId={scriptData[language]?.[type]?.id}
                    />
                  </div>

                  {/* Quizzes */}
                  <div>
                    <QuizEditor
                      quizzes={quizzesData[language]?.[type] || []} 
                      onQuizzesChange={(updatedQuizzes) => onQuizzesChange(language, type, updatedQuizzes)}
                      scriptId={scriptData[language]?.[type]?.id}
                      onRemoveQuiz={(index) => onRemoveQuiz(language, type, index)}
                      quizTypes={quizTypes}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}

        {/* 언어 추가하기 버튼 */}
        {visibleLanguages.length < 3 && (
          <button
            onClick={onAddLanguage}
            className="mt-4 bg-white rounded-lg shadow-md w-full cursor-pointer h-[70px] flex items-center justify-between px-6 text-[#383C4B] font-bold font-pretendard"
          >
            <span className="text-lg">언어 추가하기</span>
            <img src={plusIcon} alt="Add" className="h-7 w-7" />
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomizeForm;