import React, { useState } from "react";
import { uploadFile, getFileUrl } from "../../api/admin/file";
import trashIcon from "../../assets/admin/icons/trash.png";

const FileUploader = ({
  onFileUpload,
  fileUrl,
  onFileDelete,  // 이 prop을 통해 부모 컴포넌트의 상태를 업데이트합니다.
  link,
  memo,
  onLinkChange,
  onMemoChange,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [linkError, setLinkError] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file || !file.type.startsWith("image/")) {
      return;
    }
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert("이미지 파일을 먼저 선택하세요.");
      return;
    }

    try {
      const fileUrl = await uploadFile(selectedFile);
      setImageUrl(fileUrl);
      onFileUpload(fileUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("이미지 업로드에 실패했습니다.");
    }
  };

  const handleLinkChange = (e) => {
    const { value } = e.target;
    setLinkError("");
    onLinkChange(value);
  };

  const handleLinkBlur = (e) => {
    const { value } = e.target;

    // URL 형식 검증 (정규식 사용)
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // 프로토콜 (http, https 선택적)
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // 도메인 이름
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // 또는 IP (v4) 주소
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // 포트와 경로
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // 쿼리 스트링
        "(\\#[-a-z\\d_]*)?$",
      "i" // 해시 (fragment)
    );

    if (!value || urlPattern.test(value)) {
      setLinkError("");
    } else {
      setLinkError("올바른 URL 주소를 복사하여 붙여 넣으세요.");
    }
  };

  const handleClearFileUrl = () => {
    onFileDelete();  // 부모 컴포넌트의 fileUrl 상태를 지웁니다.
  };

  return (
    <div className="mb-4">
      {/* File 제목 */}
      <div className="mb-4">
        <label
          className="block text-lg font-bold mb-2"
          style={{
            fontFamily: "Pretendard",
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "left",
          }}
        >
          FILE
        </label>
      </div>

      {/* Image, Link, Memo 섹션 */}
      <div className="flex items-start space-x-4">
        {/* 이미지 파일 업로드 */}
        <div className="w-1/3">
          <label
            className="block text-sm font-bold mb-2.5"
            style={{
              fontFamily: "Pretendard Variable",
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "left",
            }}
          >
            Image
          </label>
          <div className="flex space-x-4">
            <input
              type="file"
              accept="image/*"
              className="border p-2 rounded-md w-full h-[48px]"
              style={{
                borderRadius: "12px",
                border: "1px solid #E2E8EF",
                fontFamily: "Pretendard",
                fontSize: "14px",
                fontWeight: 500,
              }}
              onChange={handleFileChange}
            />
            <button
              className="w-[100px] h-[48px] bg-[#E3EDFF] text-[#3677F6] rounded-md flex items-center justify-center"
              style={{
                borderRadius: "12px",
                fontFamily: "Pretendard",
                fontSize: "12px",
                fontWeight: 700,
              }}
              onClick={handleFileUpload}
            >
              이미지 추가
            </button>
          </div>
          {/* 업로드된 이미지 파일 표시 */}
          {fileUrl && (
            <div className="block items-center mt-2">
              {/* URL을 HTTPS로 변경하고 필요한 경우 / 추가 */}
              {(() => {
                let secureImageUrl = fileUrl.startsWith("http://")
                  ? fileUrl.replace("http://", "https://")
                  : fileUrl;
                
                // /로 시작하지 않으면 /를 추가
                if (!secureImageUrl.startsWith("/")) {
                  secureImageUrl = "/" + secureImageUrl;
                }
                
                return (
                  <>
                    <img
                      src={getFileUrl(secureImageUrl)}
                      alt="Uploaded"
                      className="object-cover h-32 w-32 border border-gray-200 rounded-md"
                    />
                    <label className="text-sm font-bold ml-2">
                      {secureImageUrl}
                    </label>
                    <button onClick={handleClearFileUrl}>
                      <img
                        src={trashIcon}
                        alt="Delete"
                        className="w-5 h-5 ml-2"
                      />
                    </button>
                  </>
                );
              })()}
            </div>
          )}
        </div>

        {/* Link 입력 필드 */}
        <div className="w-1/3">
          <label
            className="block text-sm font-bold mb-2.5"
            style={{
              fontFamily: "Pretendard Variable",
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "left",
            }}
          >
            LINK
          </label>
          <input
            type="text"
            name="link"
            placeholder="입력하세요"
            value={link || ''}
            onChange={handleLinkChange}
            onBlur={handleLinkBlur}
            className={`border p-2 rounded-md w-full h-[48px] ${
              linkError ? "border-red-500" : ""
            }`}
            style={{
              borderRadius: "12px",
              border: "1px solid #E2E8EF",
              fontFamily: "Pretendard",
              fontSize: "14px",
              fontWeight: 500,
            }}
          />
          {linkError && (
            <p className="text-red-500 text-sm mt-1">{linkError}</p>
          )}
        </div>

        {/* Memo 입력 필드 */}
        <div className="w-1/3">
          <label
            className="block text-sm font-bold mb-2.5"
            style={{
              fontFamily: "Pretendard Variable",
              fontSize: "14px",
              fontWeight: 700,
              textAlign: "left",
            }}
          >
            MEMO
          </label>
          <input
            name="text"
            placeholder="입력하세요"
            value={memo || ''}
            onChange={(e) => onMemoChange(e.target.value)}
            className="border p-2 rounded-md w-full h-[48px]"
            style={{
              borderRadius: "12px",
              border: "1px solid #E2E8EF",
              fontFamily: "Pretendard",
              fontSize: "14px",
              fontWeight: 500,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
