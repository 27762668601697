import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from '../../components/admin/Navigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/admin/icons/calendar_black.png';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { getProducts, addProduct, updateProduct, getProductById } from '../../api/admin/product';

const ProductEditor = ({ mode = 'create' }) => {
  const navigate = useNavigate();
  const { productId } = useParams();

  const [productData, setProductData] = useState({
    category: '',
    name: '',
    regularPrice: '',
    salePrice: '',
    content: '',
    quantity: '',
    isDisplayed: true,
    isForSale: true,
    usageDays: '',
    paymentMethod: '',
    salePeriod: null
  });

  const [openDropdown, setOpenDropdown] = useState('');

  useEffect(() => {
    const loadProductData = async () => {
      try {
        if (mode === 'edit' && productId) {
          const product = await getProductById(productId);
          setProductData({
            ...product,
            regularPrice: product.regularPrice.toString(),
            salePrice: product.salePrice.toString(),
            quantity: product.quantity.toString(),
            usageDays: product.usageDays.toString(),
            salePeriod: product.salePeriod ? new Date(product.salePeriod) : null
          });
        }
      } catch (error) {
        console.error('상품 데이터 로딩 실패:', error);
        alert('상품 데이터를 불러오는 데 실패했습니다.');
      }
    };

    loadProductData();
  }, [mode, productId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // 사용일수 필드에 대한 특별 처리
    if (name === 'usageDays') {
      const numValue = parseInt(value);
      if (value === '') {
        // 빈 값은 허용
        setProductData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } else if (!isNaN(numValue) && numValue >= 0 && numValue <= 60) {
        // 0-60 사이의 숫자만 허용
        setProductData(prevData => ({
          ...prevData,
          [name]: value
        }));
      } else {
        alert('사용일수는 최대 60일까지만 입력 가능합니다.');
        return;
      }
    } else {
      // 다른 필드들은 기존과 동일하게 처리
      setProductData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleDateChange = (date) => {
    setProductData(prevData => ({
      ...prevData,
      salePeriod: date
    }));
  };

  const toggleDropdown = (field) => {
    setOpenDropdown(openDropdown === field ? '' : field);
  };

  const handleSelectChange = (field, value) => {
    setProductData(prevData => ({
      ...prevData,
      [field]: value
    }));
    setOpenDropdown('');
  };

  const handleSave = async () => {
    try {
      const dataToSend = {
        ...productData,
        regularPrice: Number(productData.regularPrice),
        salePrice: Number(productData.salePrice),
        quantity: Number(productData.quantity),
        usageDays: Number(productData.usageDays),
        salePeriod: productData.salePeriod ? productData.salePeriod.toISOString() : null
      };

      if (mode === 'create') {
        await addProduct(dataToSend);
        alert('상품이 성공적으로 추가되었습니다.');
      } else if (mode === 'edit') {
        await updateProduct(productId, dataToSend);
        alert('상품이 성공적으로 수정되었습니다.');
      }
      navigate('/product');
    } catch (error) {
      console.error('Error saving product:', error);
      let errorMessage = '상품 저장 중 오류가 발생했습니다.';
      if (error.response) {
        errorMessage += ` 상태 코드: ${error.response.status}`;
        if (error.response.data) {
          errorMessage += ` 서버 메시지: ${JSON.stringify(error.response.data)}`;
        }
      } else if (error.request) {
        errorMessage += ' 서버로부터 응답을 받지 못했습니다.';
      } else {
        errorMessage += ` 오류 메시지: ${error.message}`;
      }
      alert(errorMessage);
    }
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold text-left" style={{fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700}}>
          {mode === 'create' ? '상품 추가' : '상품 수정'}
        </h1>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => navigate('/product')}
            className="w-[100px] h-[48px] bg-[#666B81] text-white"
            style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, borderRadius: '12px'}}
          >
            목록가기
          </button>
          <button
            onClick={handleSave}
            className="w-[100px] h-[48px] bg-[#3677F6] text-white"
            style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, borderRadius: '12px'}}
          >
            저장하기
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <div className="grid grid-cols-3 gap-8">
          <div>
            <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
              상품정보
            </h2>
            <SelectField 
              label="상품구분" 
              name="category" 
              value={productData.category} 
              options={['기간제 상품', '멤버십 상품']}
              isOpen={openDropdown === 'category'}
              onToggle={() => toggleDropdown('category')}
              onChange={(value) => handleSelectChange('category', value)}
            />
            <InputField label="상품명" name="name" value={productData.name} onChange={handleInputChange} />
            <InputField label="상품설명" name="content" value={productData.content} onChange={handleInputChange} />
          </div>
          
          <div>
            <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
              상태
            </h2>
            <SelectField 
              label="출력 여부" 
              name="isDisplayed" 
              value={productData.isDisplayed ? '출력' : '미출력'} 
              options={['출력', '미출력']}
              isOpen={openDropdown === 'isDisplayed'}
              onToggle={() => toggleDropdown('isDisplayed')}
              onChange={(value) => handleSelectChange('isDisplayed', value === '출력')}
            />
            <SelectField 
              label="판매여부" 
              name="isForSale" 
              value={productData.isForSale ? '판매' : '미판매'} 
              options={['판매', '미판매']}
              isOpen={openDropdown === 'isForSale'}
              onToggle={() => toggleDropdown('isForSale')}
              onChange={(value) => handleSelectChange('isForSale', value === '판매')}
            />
            <DateField label="판매 시작일" value={productData.salePeriod} onChange={handleDateChange} />
          </div>
          
          <div>
            <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
              가격
            </h2>
            <div className="flex gap-4">
              <InputField label="정가" name="regularPrice" value={productData.regularPrice} onChange={handleInputChange} />
              <InputField label="판매가" name="salePrice" value={productData.salePrice} onChange={handleInputChange} />
            </div>
            <div className="flex gap-4">
              <InputField label="수량" name="quantity" value={productData.quantity} onChange={handleInputChange} />
              <InputField label="사용일수" name="usageDays" value={productData.usageDays} onChange={handleInputChange} />
            </div>
            <SelectField 
              label="결제방법" 
              name="paymentMethod" 
              value={productData.paymentMethod} 
              options={['신용카드']}
              isOpen={openDropdown === 'paymentMethod'}
              onToggle={() => toggleDropdown('paymentMethod')}
              onChange={(value) => handleSelectChange('paymentMethod', value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const InputField = ({ label, name, value, onChange }) => (
  <div className="flex-1">
    <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
      {label}
    </label>
    <input
      type={name === 'usageDays' ? 'number' : 'text'}
      name={name}
      value={value}
      onChange={onChange}
      placeholder="기재해주세요"
      max={name === 'usageDays' ? "60" : undefined}
      min={name === 'usageDays' ? "0" : undefined}
      className="w-full mb-4"
      style={{
        height: '48px',
        padding: '4px 14px',
        borderRadius: '12px',
        border: '1px solid #E2E8EF',
        fontFamily: 'Pretendard',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '14px',
        textAlign: 'left',
        color: value ? '#000000' : '#A9B6CA'
      }}
    />
  </div>
);

const SelectField = ({ label, name, value, options, isOpen, onToggle, onChange }) => (
  <div className="mb-4">
    <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
      {label}
    </label>
    <div className="relative">
      <div
        onClick={onToggle}
        className="w-full cursor-pointer"
        style={{
          height: '48px',
          padding: '4px 14px',
          borderRadius: '12px',
          border: '1px solid #E2E8EF',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '14px',
          textAlign: 'left',
          color: value ? '#000000' : '#A9B6CA',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <span>{value || '선택해주세요'}</span>
        <ChevronDownIcon className="h-5 w-5 text-gray-400" />
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
          {options.map((option, index) => (
            <div
              key={index}
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => onChange(option)}
              style={{
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontWeight: 500,
                color: '#000000'
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
);

const DateField = ({ label, value, onChange }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
        {label}
      </label>
      <div 
        className="relative flex items-center"
        style={{
          height: '48px',
          padding: '4px 14px',
          borderRadius: '12px',
          border: '1px solid #E2E8EF'
        }}
      >
        <DatePicker
          selected={value}
          onChange={onChange}
          dateFormat="yyyy-MM-dd"
          placeholderText="선택해주세요"
          className="w-full"
          style={{
            height: '48px',
            padding: '18px 14px',
            borderRadius: '12px',
            border: '1px solid #E2E8EF',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '14px',
            textAlign: 'left',
            color: value ? '#000000' : '#A9B6CA'
          }}
        />
        <img 
          src={calendarIcon} 
          alt="Calendar Icon" 
          className="absolute right-2 top-1/2 transform -translate-y-1/2" 
          style={{ width: '16px', height: '18px' }}
        />
      </div>
    </div>
  );
};

export default ProductEditor;
