import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { LanguageContext } from "./LanguageContext";

const AccountInfo = () => {
  const { translations, language } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/mypage");
  };
  const handleGoRegister = () => {
    navigate("/registersetting");
  };
  const handleGoLang = () => {
    navigate("/langsetting");
  };
  const handleGoMBTI = () => {
    navigate("/mbtisetting");
  };
  const handleGoPersona = () => {
    navigate("/personasetting");
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className="relative min-h-screen flex flex-col bg-[#faf8f6] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center"
          onClick={handleGoBack}
        >
          <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
        </div>
        <div className="flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
          {translations.acinfo1}
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="absolute left-[5%] top-[12vh] w-[90%] flex flex-col items-start justify-start gap-[12px]">
        <div
          className="self-stretch h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[#fff] shadow-lg rounded-[12px]"
          onClick={handleGoRegister}
        >
          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
            {translations.acinfo2}
          </div>
        </div>
        <div
          className="self-stretch h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[#fff] shadow-lg rounded-[12px]"
          onClick={handleGoLang}
        >
          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
            {translations.acinfo3}
          </div>
        </div>
        <div
          className="self-stretch h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[#fff] shadow-lg rounded-[12px]"
          onClick={handleGoMBTI}
        >
          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
            {translations.acinfo4}
          </div>
        </div>
        <div
          className="self-stretch h-[54px] shrink-0 flex flex-row items-center justify-center py-[10px] px-[20px] bg-[#fff] shadow-lg rounded-[12px]"
          onClick={handleGoPersona}
        >
          <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap">
            {translations.acinfo5}
          </div>
        </div>
        <div
          className="fixed bottom-[25px] underline left-1/2 transform -translate-x-1/2 w-[90%] h-[54px] flex flex-row items-center justify-center py-[10px] px-[20px] text-black dark:text-[#fff] font-sans font-medium"
          onClick={() => {
            // Clear tokens and session data
            localStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
            sessionStorage.removeItem("autoFlag");
            localStorage.removeItem("userid");

            // Redirect to another page briefly to force re-render, then back to splash
            navigate("/temporary");
            setTimeout(() => {
              navigate("/splash");
            }, 100); // Small delay to ensure routing is handled
          }}
        >
          <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
            {translations.acinfo6}
          </div>
        </div>
      </div>

      {/* Footer */}

      {/* Toast Container */}
    </div>
  );
};

export default AccountInfo;
