import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setKeywords, setLis } from "./actions";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { IoIosArrowBack } from "react-icons/io";
import BeatLoader from "react-spinners/BeatLoader";
import kor_eng from "../data/select_kor_eng.json";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SituationStudy = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translations, language } = useContext(LanguageContext);
  const [animation, setAnimation] = useState("");

  const handleNextClick2 = () => {
    if (selectedGenders.length === 0) {
      const toastId = "tag-error";
      setAnimation("animate-shake border-red-500"); // Trigger shake animation for error
      if (!toast.isActive(toastId)) {
        // Show error toast if email is invalid
        toast.error("태그를 하나 이상 선택해주세요", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
        });
      }
      return null;
    }

    const tagIds = selectedGenders.join(",");
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/situation/managerFilter?tagid=${tagIds}&limit=1000&page=1`
        );
        const fetchedLis = await response.json();
        if (response.ok) {
          console.log(
            `${apiUrl}/situation/managerFilter?tagid=${tagIds}&limit=1&page=1`
          ); // Store fetched data in state
          const filteredList = fetchedLis.data.filter(
            (item) => item.used === true
          );
          dispatch(setKeywords(selectedKeys));
          dispatch(setLis(filteredList));
          navigate("/situationstudylist");
        }
      } catch (error) {}
    };

    fetchData();
  };
  const handleGoBack = () => {
    navigate("/study");
  };
  // Use an array to keep track of multiple selected items
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expandedIndex2, setExpandedIndex2] = useState(null);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [data, setData] = useState({});
  const [topics, setTopics] = useState([]);

  const handleSelect2 = (value) => {
    setSelectedKeys((prevSelectedKeys) => {
      // Initialize the new array based on the previous state
      const newSelectedKeys = [...prevSelectedKeys];

      // Determine the opposite language
      const oppositeLanguage = language === "kor" ? "eng" : "kor";

      // First, find the secondLevelChild that contains the title matching the current language
      const secondLevelChild = data.children
        .flatMap((firstLevelChild) => firstLevelChild.children)
        .find((child) =>
          child.translations.some(
            (translation) =>
              translation.language === language && translation.name === value
          )
        );

      if (secondLevelChild) {
        // Find the title in the current language
        const currentLanguageTitle = secondLevelChild.translations.find(
          (translation) => translation.language === language
        )?.name;

        // Find the title in the opposite language
        const oppositeLanguageTitle = secondLevelChild.translations.find(
          (translation) => translation.language === oppositeLanguage
        )?.name;

        // Add the titles to the newSelectedKeys array if they are not already present
        if (
          currentLanguageTitle &&
          oppositeLanguageTitle &&
          newSelectedKeys.includes(currentLanguageTitle) &&
          newSelectedKeys.includes(oppositeLanguageTitle)
        ) {
          // Remove both titles
          return newSelectedKeys.filter(
            (key) =>
              key !== currentLanguageTitle && key !== oppositeLanguageTitle
          );
        } else {
          // Add the titles if they are not already present
          if (
            currentLanguageTitle &&
            !newSelectedKeys.includes(currentLanguageTitle)
          ) {
            newSelectedKeys.push(currentLanguageTitle);
          }
          if (
            oppositeLanguageTitle &&
            !newSelectedKeys.includes(oppositeLanguageTitle)
          ) {
            newSelectedKeys.push(oppositeLanguageTitle);
          }
        }
      }

      return newSelectedKeys;
    });
  };

  // Fetch data once when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/2`);
        const fetchedData = await response.json();
        setData(fetchedData); // Store fetched data in state
      } catch (error) {}
    };

    fetchData();
  }, []); // Empty dependency array: fetch data only once on mount

  // Update topics and filteredTopics based on `data` and `selectedGenders`
  useEffect(() => {
    if (data && data.children) {
      const topics = data.children.flatMap(
        (firstLevelChild, firstLevelIndex) => {
          const firstLevelNames = firstLevelChild.translations
            ? firstLevelChild.translations
                .filter((translation) => translation.language === language)
                .map((translation) => translation.name)
            : [];

          const secondLevel = firstLevelChild.children || [];
          const secondLevelData = secondLevel.map(
            (secondLevelChild, secondLevelIndex) => {
              const secondLevelNames = secondLevelChild.translations
                ? secondLevelChild.translations
                    .filter((translation) => translation.language === language)
                    .map((translation) => translation.name)
                : [];

              return {
                id: `second-${firstLevelIndex}-${secondLevelIndex}`,
                title: secondLevelNames[0],
                content: (
                  <div
                    key={`second-content-${secondLevelIndex}`}
                    className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                      selectedGenders.includes(secondLevelChild.id)
                        ? darkMode
                          ? "bg-[#fff] border-[#fff] text-[#000]"
                          : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                        : darkMode
                        ? " border-[#e9ecef] text-[#fff]"
                        : "border-[#e9ecef] text-[#000]"
                    }`}
                    onClick={() => {
                      handleSelect(secondLevelChild.id);
                      handleSelect2(secondLevelNames[0]);
                    }}
                    style={{ flexBasis: "calc(33.333% - 6px)" }}
                  >
                    <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                      {secondLevelNames[0]}
                    </div>
                  </div>
                ),
              };
            }
          );

          return firstLevelNames.map((firstLevelName, index) => ({
            id: `first-${firstLevelIndex}-${index}`,
            title: firstLevelName,
            content: (
              <div
                key={`first-content-${index}`}
                className="flex flex-wrap gap-2"
              >
                {/* 전체 Button alongside other second-level topics */}
                <div
                  className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] cursor-pointer ${
                    secondLevel.every((secondLevelChild) =>
                      selectedGenders.includes(secondLevelChild.id)
                    )
                      ? darkMode
                        ? "bg-[#fff] border-[#fff] text-[#000]"
                        : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                      : darkMode
                      ? "border-[#e9ecef] text-[#fff]"
                      : "border-[#e9ecef] text-[#000]"
                  }`}
                  onClick={() => {
                    const idsToSelect = secondLevel.map(
                      (secondLevelChild) => secondLevelChild.id
                    );
                    const titlesToSelect = secondLevel.map(
                      (secondLevelChild) =>
                        secondLevelChild.translations.find(
                          (translation) => translation.language === language
                        )?.name
                    );

                    if (
                      idsToSelect.every((id) => selectedGenders.includes(id)) &&
                      titlesToSelect.every((title) =>
                        selectedKeys.includes(title)
                      )
                    ) {
                      // If all are already selected, deselect all
                      secondLevel.forEach((secondLevelChild) => {
                        const title = secondLevelChild.translations.find(
                          (translation) => translation.language === language
                        )?.name;

                        handleSelect(secondLevelChild.id); // Deselect each second-level ID
                        if (title) {
                          handleSelect2(title); // Handle deselection for each title
                        }
                      });
                    } else {
                      // Otherwise, select all
                      secondLevel.forEach((secondLevelChild) => {
                        const title = secondLevelChild.translations.find(
                          (translation) => translation.language === language
                        )?.name;

                        handleSelect(secondLevelChild.id); // Select each second-level ID
                        if (title) {
                          handleSelect2(title); // Handle selection for each title
                        }
                      });
                    }
                  }}
                  style={{ flexBasis: "calc(33.333% - 56px)" }}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                    {translations.ALL}
                  </div>
                </div>

                {/* Render each second-level button */}
                {secondLevelData.map((secondLevelTopic) => (
                  <div key={secondLevelTopic.id}>
                    {secondLevelTopic.content}
                  </div>
                ))}
              </div>
            ),
          }));
        }
      );

      setTopics(topics.reverse());
    }
  }, [data, selectedGenders, language]); // Update when `data` or `selectedGenders` changes

  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Close the currently expanded topic
    } else {
      setExpandedIndex(index); // Open the clicked topic
      setExpandedIndex2(index); // Open the clicked topic
    }
  };

  const handleSelect = (value) => {
    setSelectedGenders((prevSelectedGenders) => {
      if (prevSelectedGenders.includes(value)) {
        // If the value is already selected, remove it
        return prevSelectedGenders.filter((item) => item !== value);
      } else {
        // Otherwise, add the value
        return [...prevSelectedGenders, value];
      }
    });
  };

  // Function to find the key by value in an object
  const findKeyByValue = (obj, valueToFind) => {
    for (const [key, value] of Object.entries(obj)) {
      if (value === valueToFind) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  useEffect(() => {}, [selectedGenders]);
  useEffect(() => {
    setSelectedGenders([]);
    setSelectedKeys([]);
  }, [expandedIndex2]);

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6] dark:bg-[#121418]">
        <header className="flex items-center justify-between py-8 px-4">
          <div className="flex items-center">
            {/* <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            /> */}
            <IoIosArrowBack
              color={darkMode ? "white" : "black"}
              size={30}
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] dark:text-[#fff] text-center">
            Situation
          </h1>
          {darkMode ? (
            <LanguageSwitcher2></LanguageSwitcher2>
          ) : (
            <LanguageSwitcher></LanguageSwitcher>
          )}
        </header>

        <main className="flex flex-col items-center gap-4 px-4 mt-6">
          <section className="w-full">
            <h2 className="text-[16px] font-sans font-bold text-[#000] dark:text-[#fff] mb-4">
              {translations.ss1}
            </h2>
            <div className="flex flex-col">
              {topics.length === 0 ? (
                <div className="flex justify-center items-center">
                  <BeatLoader
                    color="#3677f6"
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                topics.map((topic, index) => (
                  <div
                    key={index}
                    className="w-full border-b border-[#e9ecef] dark:border-[#666b81] bg-[#fff] dark:bg-[#22232a]"
                  >
                    <button
                      onClick={() => handleToggle(index)}
                      className={`flex justify-between items-center w-full p-4 border-b border-[#e9ecef] dark:border-[#666b81] last:border-none ${
                        expandedIndex !== index &&
                        selectedGenders.length > 0 &&
                        expandedIndex2 !== index
                          ? darkMode
                            ? "text-[#555]"
                            : "text-[#ccc]"
                          : darkMode
                          ? "text-[#fff]"
                          : "text-[#000]"
                      }`}
                    >
                      <span className="text-[16px] font-sans font-bold ">
                        {topic.title}
                      </span>
                      <img
                        width="30"
                        height="30"
                        src={`open.png`}
                        alt="Toggle"
                        className={`transition-transform duration-300 ${
                          expandedIndex === index ? "rotate-180" : "rotate-0"
                        }${darkMode ? "filter invert" : ""}`}
                      />
                    </button>
                    {expandedIndex === index && (
                      <div className="p-4 bg-[#fff] dark:bg-[#22232a]">
                        <div className="text-[14px] font-sans text-[#666b81]">
                          {topic.content}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </section>
        </main>

        {selectedGenders.length > 0 ? (
          <footer className="fixed w-full bottom-0 py-4 px-4">
            <button
              className="w-full h-[54px] flex items-center justify-center bg-gradient-to-r from-[#61e3eb] to-[#3677f6] rounded-[12px] text-[#fff] text-[14px] font-sans font-bold"
              onClick={handleNextClick2}
              // onClick={handleNextClick}
            >
              완료
            </button>
          </footer>
        ) : (
          <footer className="w-full bottom-0 py-4 px-4">
            <button
              className="w-full h-[54px] flex items-center justify-center bg-gradient-to-r from-[#61e3eb] to-[#3677f6] rounded-[12px] text-[#fff] text-[14px] font-sans font-bold"
              onClick={handleNextClick2}
              // onClick={handleNextClick}
            >
              완료
            </button>
          </footer>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default SituationStudy;
