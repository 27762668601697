import axios from 'axios';
import API_BASE_URL from '../../config';

// FAQ 조회
export const getFaqs = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/faq`);
        return response.data;
    } catch (error) {
        console.error('Error fetching FAQs:', error);
        throw error;
    }
};

// FAQ 생성
export const createFaq = async (faq) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/faq`, faq);
        return response.data;
    } catch (error) {
        console.error('Error creating FAQ:', error);
        throw error;
    }
};

// FAQ 수정
export const updateFaq = async (faqId, faq) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/faq/${faqId}`, faq);
        return response.data;
    } catch (error) {
        console.error('Error updating FAQ:', error);
        throw error;
    }
};

// FAQ 삭제
export const deleteFaq = async (faqId) => {
    try {   
        const response = await axios.delete(`${API_BASE_URL}/faq/${faqId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting FAQ:', error);
        throw error;
    }
};  

// FAQ 상세 조회    
export const getFaqById = async (faqId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/faq/${faqId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching FAQ by ID:', error);
        throw error;
    }
};

// FAQ 페이지네이션
export const getFaqsPaginated = async (page=1, limit=10) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/faq`, {
            params: { page, limit }
        });
        return {
            data: response.data,
            total: response.headers['x-total-count'] ? parseInt(response.headers['x-total-count'], 10) : 0 // 총 항목 수를 헤더에서 가져옴
        };
    } catch (error) {
        console.error('Error fetching paginated FAQs:', error);
        throw error;
    }
};

// FAQ 저장 (생성 및 수정)
export const saveFaq = async (faq, mode, faqId = null) => {
    try {
        let response;
        if (mode === 'create') {
            response = await createFaq(faq);
        } else if (mode === 'edit' && faqId) {
            response = await updateFaq(faqId, faq);
        } else {
            throw new Error('Invalid mode or missing faqId for edit mode');
        }
        return response.data;
    } catch (error) {
        console.error(`Error ${mode === 'create' ? 'creating' : 'updating'} FAQ:`, error);
        throw error;
    }
};
