import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Nickname = () => {
  const [nickname, setNickname] = useState("");
  const [pass, setPass] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || {};

  const [onNickname, setOnNickname] = useState("");

  useEffect(() => {
    // Load the nickname from sessionStorage on component mount
    const savedNickname = sessionStorage.getItem("onNickname");
    if (savedNickname) {
      setOnNickname(savedNickname);
      setNickname(savedNickname);

      // Validate the saved nickname and set pass if valid
      const emailRegex = /^[가-힣a-zA-Z0-9]{2,10}$/;
      if (emailRegex.test(savedNickname)) {
        setPass("/lang"); // Set pass if nickname is valid
      }
    }
  }, []);

  const handleInputChange = (value) => {
    setOnNickname(value);
    const emailRegex = /^[가-힣a-zA-Z0-9]{2,10}$/;
    if (emailRegex.test(value)) {
      setNickname(value);
      setPass("/lang");
    } else if (value === "") {
      setNickname("");
      setPass(null);
    } else {
      setNickname("");
      setPass(null);
    }
  };

  const handleButtonClick = () => {
    if (!pass) {
      const toastId = "nickname-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("올바른 닉네임을 입력해주세요", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
    } else {
      sessionStorage.setItem("onNickname", nickname); // Save to sessionStorage as the user types
      navigate(pass, {
        state: {
          userId,
          nickname,
        },
      });
    }
  };

  const handleGoBack = () => {
    navigate("/welcome");
  };
  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] overflow-hidden">
      <div className="mb-[4vh]"></div>
      {/* Header */}
      <div className="flex items-center justify-between px-[16px] h-[50px] bg-[#fff]">
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px]">
          <img
            width="10"
            height="16"
            src="back.png"
            alt="icon"
            onClick={handleGoBack}
          />
        </div>
      </div>

      {/* Progress Bar */}
      <div className="w-full h-1 bg-gray-200">
        <div className="w-1/4 h-full bg-blue-500"></div>
      </div>

      {/* Content */}
      <div className="flex-1 px-[16px] py-[20px] pb-[20px] flex flex-col gap-[14px]">
        {/* Title and Description */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[18px] leading-[100%] font-sans font-bold text-[#000]">
            닉네임을 적어주세요
          </div>
          <div className="text-[14px] leading-[22px] font-sans text-[#000]">
            최소 2자, 최대 10자 / 한글, 영문자를 사용할 수 있어요
          </div>
        </div>

        {/* Nickname Input */}
        <input
          type="text"
          onChange={(e) => handleInputChange(e.target.value)}
          value={onNickname}
          placeholder="입력하세요."
          className="w-full h-[48px] py-[14px] px-[14px] border-[1px] border-solid border-[#e9ecef] rounded-[12px] text-[14px] leading-[100%] font-sans font-medium text-[#000]"
        />
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={handleButtonClick}
        >
          다음
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Nickname;
