import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NicePhone = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const nameParam = queryParams.get("name");
    const phoneParam = queryParams.get("phone");

    const formData = {
      name: decodeURIComponent(nameParam),
      phoneNumber: phoneParam,
    };
    if (sessionStorage.getItem("registerwhere") === "register") {
      navigate("/register", { state: formData, replace: true });
      window.location.reload();
    } else if (sessionStorage.getItem("registerwhere") === "id") {
      navigate("/lost-id1", { state: formData, replace: true });
      window.location.reload();
    } else if (sessionStorage.getItem("registerwhere") === "pw") {
      navigate("/lost-pw1", { state: formData, replace: true });
      window.location.reload();
    } else if (sessionStorage.getItem("registerwhere") === "ph") {
      navigate("/lost-ph1", { state: formData, replace: true });
      window.location.reload();
    } else {
      navigate("/social-login", { state: formData, replace: true });
      window.location.reload();
    }
  }, [location, navigate]);

  return <div></div>;
};

export default NicePhone;
