import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "./actions";
import api from "./api"; // Import your custom axios instance
import { IoIosArrowBack } from "react-icons/io";
import { LanguageContext } from "./LanguageContext"; // Import the Language context

const MbtiSetting = () => {
  // State to track selected options for each row
  const { translations } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [mbti, setMbti] = useState("");
  const userInfo = useSelector((state) => state.userInfo);
  const accessToken = sessionStorage.getItem("accessToken");

  const [selectedOptions, setSelectedOptions] = useState({
    row1: null,
    row2: null,
    row3: null,
    row4: null,
  });

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  // Pre-select options based on userInfo.mbti
  useEffect(() => {
    if (userInfo.mbti) {
      const mbtiStr = userInfo.mbti.toUpperCase(); // Convert to uppercase for comparison
      setSelectedOptions({
        row1: mbtiStr[0], // First letter for row1 (E or I)
        row2: mbtiStr[1], // Second letter for row2 (N or S)
        row3: mbtiStr[2], // Third letter for row3 (F or T)
        row4: mbtiStr[3], // Fourth letter for row4 (J or P)
      });
    }
  }, [userInfo.mbti]);

  const fetchInfo = async () => {
    try {
      const response = await api.get("/auth/user-info"); // Use axios `get` method

      if (response.status === 200) {
        const result = response.data; // Get the data from axios response

        // Assuming you're using Redux or some other state management
        dispatch(setUserInfo(result)); // Dispatch or handle the fetched user info
      } else {
        // Log error message
      }
    } catch (error) {
      // Log any errors
    }
  };

  const updateProfile = async () => {
    const data = {
      userid: userInfo.userid,
      nickname: userInfo.nickname,
      nativeLanguage: userInfo.nativeLanguage,
      targetLanguage: userInfo.targetLanguage,
      mbti: mbti,
      sex: userInfo.sex,
      age: userInfo.age,
      job: userInfo.job,
      love: userInfo.love,
    };

    try {
      const response = await fetch(`${apiUrl}/auth/complete-onboarding`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });

      if (response.ok) {
        const result = await response.json();

        fetchInfo(accessToken);
        if (sessionStorage.getItem("wherembti") === "study") {
          sessionStorage.removeItem("wherembti");
          navigate("/mbtistudy");
        } else {
          navigate("/accountinfo");
        }
      } else {
        // alert("Failed to update profile.");
      }
    } catch (error) {
      alert("An error occurred while updating the profile.");
    }
  };

  useEffect(() => {
    const values = Object.values(selectedOptions);

    // Combine values into a single string, filtering out null or undefined values
    const combinedString = values
      .filter((value) => value !== null && value !== undefined)
      .join("");

    setMbti(combinedString);
  }, [selectedOptions]);
  // Handle option selection
  const handleSelect = (row, option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [row]: option,
    }));
  };

  // Define the options for each row
  const options = {
    row1: [
      {
        id: "E",
        title: translations.mbtirow1t1,
        description: translations.mbtirow1d1,
      },
      {
        id: "I",
        title: translations.mbtirow1t2,
        description: translations.mbtirow1d2,
      },
    ],
    row2: [
      {
        id: "N",
        title: translations.mbtirow2t1,
        description: translations.mbtirow2d1,
      },
      {
        id: "S",
        title: translations.mbtirow2t2,
        description: translations.mbtirow2d2,
      },
    ],
    row3: [
      {
        id: "F",
        title: translations.mbtirow3t1,
        description: translations.mbtirow3d1,
      },
      {
        id: "T",
        title: translations.mbtirow3t2,
        description: translations.mbtirow3d2,
      },
    ],
    row4: [
      {
        id: "J",
        title: translations.mbtirow4t1,
        description: translations.mbtirow4d1,
      },
      {
        id: "P",
        title: translations.mbtirow4t2,
        description: translations.mbtirow4d2,
      },
    ],
  };

  // Navigation
  const navigate = useNavigate();
  const location = useLocation();

  const handleNextClick = () => {
    // Check if all options are selected
    if (Object.values(selectedOptions).every((option) => option !== null)) {
      navigate(-1);
    } else {
      const toastId = "mbti-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("모든 옵션을 선택해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center"
          onClick={handleGoBack}
        >
          <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
          {translations.mbtisettings1}
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      {/* MBTI Options */}
      <div className="flex-1 grid grid-cols-2 gap-[16px] p-[16px]">
        {/* Row 1 */}
        {options.row1.map((option) => (
          <div
            key={option.id}
            className={`flex flex-col items-center justify-center gap-[12px] py-[22px] px-0 bg-[#fff] dark:bg-[#22232a] border-[1px] border-solid border-[#d5dce4] rounded-[10px] ${
              selectedOptions.row1 === option.id
                ? "bg-blue-100 border-blue-500"
                : ""
            }`}
            onClick={() => handleSelect("row1", option.id)}
          >
            <div className="text-[58px] leading-[100%] font-sans font-bold text-[#a9b6ca]">
              {option.id}
            </div>
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#666b81]">
              {option.title}
            </div>
            <div className="text-[14px] leading-[22px] font-sans text-[#666b81] text-center">
              {option.description}
            </div>
          </div>
        ))}

        {/* Row 2 */}
        {options.row2.map((option) => (
          <div
            key={option.id}
            className={`flex flex-col items-center justify-center gap-[12px] py-[22px] px-0 bg-[#fff] dark:bg-[#22232a] border-[1px] border-solid border-[#d5dce4] rounded-[10px] ${
              selectedOptions.row2 === option.id
                ? "bg-blue-100 border-blue-500"
                : ""
            }`}
            onClick={() => handleSelect("row2", option.id)}
          >
            <div className="text-[58px] leading-[100%] font-sans font-bold text-[#a9b6ca]">
              {option.id}
            </div>
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#666b81]">
              {option.title}
            </div>
            <div className="text-[14px] leading-[22px] font-sans text-[#666b81] text-center">
              {option.description}
            </div>
          </div>
        ))}

        {/* Row 3 */}
        {options.row3.map((option) => (
          <div
            key={option.id}
            className={`flex flex-col items-center justify-center gap-[12px] py-[22px] px-0 bg-[#fff] dark:bg-[#22232a] border-[1px] border-solid border-[#d5dce4] rounded-[10px] ${
              selectedOptions.row3 === option.id
                ? "bg-blue-100 border-blue-500"
                : ""
            }`}
            onClick={() => handleSelect("row3", option.id)}
          >
            <div className="text-[58px] leading-[100%] font-sans font-bold text-[#a9b6ca]">
              {option.id}
            </div>
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#666b81]">
              {option.title}
            </div>
            <div className="text-[14px] leading-[22px] font-sans text-[#666b81] text-center">
              {option.description}
            </div>
          </div>
        ))}

        {/* Row 4 */}
        {options.row4.map((option) => (
          <div
            key={option.id}
            className={`flex flex-col items-center justify-center gap-[12px] py-[22px] px-0 bg-[#fff] dark:bg-[#22232a] border-[1px] border-solid border-[#d5dce4] rounded-[10px] ${
              selectedOptions.row4 === option.id
                ? "bg-blue-100 border-blue-500"
                : ""
            }`}
            onClick={() => handleSelect("row4", option.id)}
          >
            <div className="text-[58px] leading-[100%] font-sans font-bold text-[#a9b6ca]">
              {option.id}
            </div>
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#666b81]">
              {option.title}
            </div>
            <div className="text-[14px] leading-[22px] font-sans text-[#666b81] text-center">
              {option.description}
            </div>
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={updateProfile}
        >
          {translations.acsettings9}
        </button>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default MbtiSetting;
