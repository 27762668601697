// Example React route handling
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/home");
  }, []);

  return <div>Processing your payment...</div>;
};

export default PaymentFailed;
