import {
  ADD_ITEMS,
  SET_ITEMS,
  SET_USER_ID,
  CLEAR_USER_ID,
  SET_KEYWORDS,
  CLEAR_KEYWORDS,
  SET_LIS,
  CLEAR_LIS,
  SET_TYPE,
  SET_MANAGEID,
  SET_IMAGEURL,
  SET_EXID,
  SET_SCRIPT,
  SET_WHERE,
  SET_USERINFO,
} from "./actions";

const initialState = {
  items: [], // Existing state for items
  userId: null, // New state for userId
  keywords: [],
  lis: [],
  type: "",
  manageId: "",
  imageUrl: "",
  exId: "",
  script: {},
  where: "",
  userInfo: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Handle items actions
    case ADD_ITEMS:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };
    case SET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };

    // Handle userId actions
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload, // Set userId
      };
    case CLEAR_USER_ID:
      return {
        ...state,
        userId: null, // Clear userId
      };

    // Handle keywords actions
    case SET_KEYWORDS:
      return {
        ...state,
        keywords: action.payload, // Set userId
      };
    case CLEAR_KEYWORDS:
      return {
        ...state,
        keywords: [], // Clear userId
      };

    // Handle lis actions
    case SET_LIS:
      return {
        ...state,
        lis: action.payload, // Set userId
      };
    case CLEAR_LIS:
      return {
        ...state,
        lis: [], // Clear userId
      };

    case SET_TYPE:
      return {
        ...state,
        type: action.payload, // Set userId
      };

    case SET_MANAGEID:
      return {
        ...state,
        manageId: action.payload, // Set userId
      };

    case SET_IMAGEURL:
      return {
        ...state,
        imageUrl: action.payload, // Set userId
      };

    case SET_EXID:
      return {
        ...state,
        exId: action.payload, // Set userId
      };

    case SET_SCRIPT:
      return {
        ...state,
        script: action.payload, // Set userId
      };

    case SET_WHERE:
      return {
        ...state,
        where: action.payload, // Set userId
      };

    case SET_USERINFO:
      return {
        ...state,
        userInfo: action.payload, // Set userId
      };

    default:
      return state;
  }
};

export default reducer;
