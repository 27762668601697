import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import LearnCustomize from "./pages/admin/LearnCustomize";
import LearnSituation from "./pages/admin/LearnSituation";
import LearnPsychology from "./pages/admin/LearnPsychology";
import LearnExpression from "./pages/admin/LearnExpression";
import CustomizeManage from "./pages/admin/CustomizeManage";
import SituationManage from "./pages/admin/SituationManage";
import PsychologyManage from "./pages/admin/PsychologyManage";
import ExpressionManage from "./pages/admin/ExpressionManage";
import InputJson from "./pages/admin/InputJson";
import ExpressionInput from "./pages/admin/ExpressionInput";
import Login from "./components/admin/Login";
import TermsAndConditions from "./components/admin/TermsAndConditions";
import NoticeList from "./pages/admin/NoticeList";
import NoticeEditor from "./pages/admin/NoticeEditor";
import FaqList from "./pages/admin/FaqList";
import FaqEditor from "./pages/admin/FaqEditor";
import ErrorList from "./pages/admin/ErrorList";
import ErrorEditor from "./pages/admin/ErrorEditor";
import Terms from "./components/admin/PersonalInformation";
import MemberList from "./pages/admin/MemberList";
import ProductList from "./pages/admin/ProductList";
import CashList from "./pages/admin/CashList";
import CashEditor from "./pages/admin/CashEditor";
import ProductEditor from "./pages/admin/ProductEditor";
import MemberEditor from "./pages/admin/MemberEditor";
import HomeDashboard from "./pages/admin/HomeDashboard";
import LearnOverview from "./pages/admin/LearnOverview";
import ExpressionOverview from "./pages/admin/ExpressionOverview";
import AdminList from "./pages/admin/AdminList";
import AdminEditor from "./pages/admin/AdminEditor";
import AdminMeta from "./pages/admin/admin-meta";
import AppEditor from "./pages/admin/AppEditor";

//user
import { motion, AnimatePresence } from "framer-motion";

import Mbti2 from "./user/Mbti2";
import Persona from "./user/Persona";
import Lang from "./user/Lang";
import Nickname from "./user/Nickname";
import Home from "./user/Home";
import Study from "./user/Study";
import Express from "./user/Express";
import Ai from "./user/Ai";
import MyPage from "./user/MyPage";
import VocabList from "./user/VocabList";
import Splash from "./user/loginpages/Splash";
import Welcome from "./user/loginpages/Welcome";
import SocialLogin from "./user/loginpages/SocialLogin";
import Login1 from "./user/loginpages/Login1";
import Login2 from "./user/loginpages/Login2";
import ID from "./user/loginpages/ID";
import ID1 from "./user/loginpages/ID1";
import PW from "./user/loginpages/PW";
import PW1 from "./user/loginpages/PW1";
import Register from "./user/loginpages/Register";
import RegisterSetting from "./user/loginpages/RegisterSetting";
import "./user/global.css";
import QuizA from "./user/QuizA";
import SocialKakao from "./user/loginpages/SocialKakao";
import SocialApple from "./user/loginpages/SocialApple";
import Prac from "./user/Prac";
import BookMark from "./user/BookMark";
import MbtiStudy from "./user/MbtiStudy";
import MbtiStudyList from "./user/MbtiStudyList";
import PersonaStudy from "./user/PersonaStudy";
import StudyOnboard from "./user/StudyOnboard";
import PersonaStudyList from "./user/PersonaStudyList";
import PsychologyStudyList from "./user/PsychologyStudyList";
import SituationStudyList from "./user/SituationStudyList";
import Note from "./user/Note";
import Associated from "./user/Associated";
import SituationStudy from "./user/SituationStudy";
import PsychologyStudy from "./user/PsychologyStudy";
import Conversation from "./user/Conversation";
import QuizB from "./user/QuizB";
import StarScore from "./user/StarScore";
import PersonaSetting from "./user/PersonaSetting";
import LangSetting from "./user/LangSetting";
import AccountInfo from "./user/AccountInfo";
import MyPageNotice from "./user/MyPageNotice";
import MyPageFaq from "./user/MyPageFaq";
import MyPageNoticeContent from "./user/MyPageNoticeContent";
import StudyPoint from "./user/StudyPoint";
import AiChat from "./user/AiChat";
import Purchase from "./user/Purchase";
import AppInfo from "./user/AppInfo";
import PaymentSuccess from "./user/PaymentSuccess";
import PaymentFailed from "./user/PaymentFailed";
import CancelSuccess from "./user/CancelSuccess";
import CancelFailed from "./user/CancelFailed";
import MbtiSetting from "./user/MbtiSetting";
import ErrorAction from "./user/ErrorAction";
import StoryBoard from "./user/StoryBoard";
import StoryBoardCreate from "./user/StoryBoardCreate";
import RefundPolicyPage from "./user/RefundPolicy";
import NoteQuiz from "./user/NoteQuiz";
import NoteQuiz2 from "./user/NoteQuiz2";
import NicePhone from "./user/NicePhone";
import PH from "./user/loginpages/PH";

const AnimatedRoutes = () => {
  const location = useLocation();

  const accessToken = sessionStorage.getItem("accessToken");
  const flutterRoutes = [
    "/conditions",
    "/terms",
    "/",
    "/welcome",
    "/social-login",
    "/social-kakao",
    "/social-apple",
    "/login1",
    "/login2",
    "/lost-id1",
    "/lost-id2",
    "/lost-pw1",
    "/lost-pw2/:userId",
    "/register",
    "/nice_phone",
    "/nice_phone_auth",
    "/registersetting",
    "/nickname",
    "/lang",
    "/mbti",
    "/persona",
    "/home",
    "/study",
    "/express",
    "/ai",
    "/aichat",
    "/mypage",
    "/mypagenotice",
    "/mypagenoticecontent",
    "/mypagefaq",
    "/refundpolicy",
    "/accountinfo",
    "/langsetting",
    "/mbtisetting",
    "/personasetting",
    "/vocablist",
    "/associated",
    "/studypoint",
    "/quiza",
    "/quizb",
    "/notequiz",
    "/notequiz2",
    "/starscore",
    "/bookmark",
    "/note",
    "/storyboard",
    "/storyboardcreate",
    "/prac",
    "/mbtistudy",
    "/mbtistudylist",
    "/personastudy",
    "/personastudylist",
    "/psychologystudy",
    "/psychologystudylist",
    "/situationstudy",
    "/situationstudylist",
    "/studyonboard",
    "/conversation",
    "/purchase",
    "/appinfo",
    "/payment-success",
    "/payment-failed",
    "/cancel-success",
    "/cancel-failed",
    "/error-action",
  ];

  useEffect(() => {
    // Check if current path matches any of the paths in the flutterRoutes array
    try {
      if (flutterRoutes.includes(location.pathname)) {
        window.flutter_inappwebview.callHandler(
          "urlChangeHandler",
          window.location.href
        );
      }
    } catch {}
  }, [location.pathname]); // Re-run the effect whenever the pathname changes

  // Function to apply the default or saved font
  const applyFont = () => {
    const defaultFont = "'기본', sans-serif";
    const savedFont = localStorage.getItem("font");

    // Set default font initially
    document.documentElement.style.setProperty(
      "--font-sans-family",
      defaultFont
    );

    // If a saved font exists, override with it
    if (savedFont) {
      document.documentElement.style.setProperty(
        "--font-sans-family",
        savedFont
      );
    }
  };

  // Apply the font when the component mounts or when returning to the app
  useEffect(() => {
    applyFont();
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.key}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
      >
        <Routes location={location} key={location.key}>
          {/* 관리자 라우트 */}
          <Route path="/admin" element={<Login />} />
          <Route element={<PrivateRoute requiredPermission="studyOverview" />}>
            <Route path="/dashboard" element={<HomeDashboard />} />
            <Route path="/learn-overview" element={<LearnOverview />} />
          </Route>
          <Route
            element={<PrivateRoute requiredPermission="expressionOverview" />}
          >
            <Route path="/express-overview" element={<ExpressionOverview />} />
          </Route>
          <Route element={<PrivateRoute requiredPermission="expression" />}>
            <Route path="/expression" element={<LearnExpression />} />
          </Route>
          <Route element={<PrivateRoute requiredPermission="customize" />}>
            <Route path="/customize" element={<LearnCustomize />} />
          </Route>
          <Route element={<PrivateRoute requiredPermission="situation" />}>
            <Route path="/situation" element={<LearnSituation />} />
          </Route>
          <Route element={<PrivateRoute requiredPermission="psychology" />}>
            <Route path="/psychology" element={<LearnPsychology />} />
          </Route>
          <Route element={<PrivateRoute requiredPermission="userManagement" />}>
            <Route path="/member" element={<MemberList />} />
          </Route>
          <Route
            element={<PrivateRoute requiredPermission="productManagement" />}
          >
            <Route path="/product" element={<ProductList />} />
          </Route>
          <Route
            element={<PrivateRoute requiredPermission="paymentManagement" />}
          >
            <Route path="/cash" element={<CashList />} />
          </Route>
          <Route element={<PrivateRoute requiredPermission="faqManagement" />}>
            <Route path="/faq" element={<FaqList />} />
          </Route>
          <Route
            element={<PrivateRoute requiredPermission="noticeManagement" />}
          >
            <Route path="/notice" element={<NoticeList />} />
          </Route>
          <Route
            element={<PrivateRoute requiredPermission="errorManagement" />}
          >
            <Route path="/error" element={<ErrorList />} />
          </Route>
          <Route element={<PrivateRoute requiredPermission="appSettings" />}>
            <Route path="/app-editor" element={<AppEditor />} />
          </Route>

          {/* Admin 전용 라우트 */}
          <Route element={<AdminRoute />}>
            <Route path="/admin-meta" element={<AdminMeta />} />
            <Route path="/admin-list" element={<AdminList />} />
          </Route>

          {/* 기존의 다른 라우트들 */}
          {/* inputJson 경로로 InputJson 페이지 렌더링 */}
          <Route path="/inputJson" element={<InputJson />} />
          {/* expressionJson 경로로 ExpressionInput 페이지 렌더링 */}
          <Route path="/expressJson" element={<ExpressionInput />} />
          {/* 스크립트 생성 모드로 CustomizeManage 페이지 렌더링 */}
          <Route
            path="/customize/manage/create"
            element={<CustomizeManage mode="create" />}
          />
          {/* 스크립트 생성 모드로 SituationManage 페이지 렌더링 */}
          <Route
            path="/situation/manage/create"
            element={<SituationManage mode="create" />}
          />
          {/* 스크립트 생성 모드로 PsychologyManage 페이지 렌더링 */}
          <Route
            path="/psychology/manage/create"
            element={<PsychologyManage mode="create" />}
          />
          {/* 스크립트 생성 모드로 expression 페이지 렌더링 */}
          <Route
            path="/expression/manage/create"
            element={<ExpressionManage mode="create" />}
          />

          {/* 스크립트 수정 모드로 CustomizeManage 페이지 렌더링 */}
          <Route
            path="/customize/manage/edit/:managerId"
            element={<CustomizeManage mode="edit" />}
          />
          {/* 스크립트 수정 모드로 SituationManage 페이지 렌더링 */}
          <Route
            path="/situation/manage/edit/:managerId"
            element={<SituationManage mode="edit" />}
          />
          {/* 스크립트 수정 모드로 PsychologyManage 페이지 렌더링 */}
          <Route
            path="/psychology/manage/edit/:managerId"
            element={<PsychologyManage mode="edit" />}
          />
          {/* 스크립트 수정 모드로 ExpressionManage 페이지 렌더링 */}
          <Route
            path="/expression/manage/edit/:managerId"
            element={<ExpressionManage mode="edit" />}
          />

          {/* notice-editor 경로로 NoticeEditor 페이지 렌더링 */}
          <Route
            path="/notice-editor"
            element={<NoticeEditor mode="create" />}
          />
          <Route
            path="/notice-editor/:noticeId"
            element={<NoticeEditor mode="edit" />}
          />
          {/* faq-editor 경로로 FaqEditor 페이지 렌더링 */}
          <Route path="/faq-editor" element={<FaqEditor mode="create" />} />
          <Route
            path="/faq-editor/:faqId"
            element={<FaqEditor mode="edit" />}
          />
          {/* error-editor 경로로 ErrorEditor 페이지 렌더링 */}
          <Route path="/error-editor/:errorId" element={<ErrorEditor />} />
          {/* cash-editor 경로로 CashEditor 페이지 렌더링 */}
          <Route path="/cash-editor/:cashId" element={<CashEditor />} />
          {/* product-editor 경로로 ProductEditor 페이지 렌더링 */}
          <Route
            path="/product-editor"
            element={<ProductEditor mode="create" />}
          />
          <Route
            path="/product-editor/:productId"
            element={<ProductEditor mode="edit" />}
          />
          {/* member-editor 경로로 MemberEditor 페이지 렌더링 */}
          <Route path="/member-editor/:userid" element={<MemberEditor />} />

          {/* admin-editor 경로로 AdminEditor 페이지 렌더링 */}
          <Route path="/admin-editor" element={<AdminEditor />} />
          {/* admin-editor/:userid 경로로 AdminEditor 페이지 렌더링 (edit mode) */}
          <Route path="/admin-editor/:userid" element={<AdminEditor />} />

          {/* 이용약관 */}
          <Route path="/conditions" element={<TermsAndConditions />} />
          {/* 개인정보 */}
          <Route path="/terms" element={<Terms />} />

          {/* 사용자 라우트 */}
          <Route path="/" element={<Splash />} />
          <Route path="/welcome" element={<Welcome />} />
          {/* 3 */}
          <Route path="/social-login" element={<SocialLogin />} />
          {/* 4 */}
          <Route path="/social-kakao" element={<SocialKakao />} />
          {/* 4 */}
          <Route path="/social-apple" element={<SocialApple />} />
          {/* 4 */}
          <Route path="/login1" element={<Login1 />} />
          {/* 5 */}
          <Route path="/login2" element={<Login2 />} />
          {/* 6 */}
          <Route path="/lost-id1" element={<ID />} />
          {/* 7 */}
          <Route path="/lost-id2" element={<ID1 />} />
          {/* 8 */}
          <Route path="/lost-pw1" element={<PW />} />
          {/* 9 */}
          <Route path="/lost-ph1" element={<PH />} />
          {/* 9 */}
          <Route path="/lost-pw2/:userId" element={<PW1 />} />
          {/* 10 */}
          <Route path="/register" element={<Register key={location.key} />} />
          {/* 10 */}
          <Route path="/nice_phone" element={<NicePhone />} />
          {/* 10 */}
          <Route path="/registersetting" element={<RegisterSetting />} />

          {/* 1 */}
          <Route path="/nickname" element={<Nickname />} />
          {/* 2 */}
          <Route path="/lang" element={<Lang />} />
          {/* 3 */}
          <Route path="/mbti" element={<Mbti2 />} />
          {/* 4 */}
          <Route path="/persona" element={<Persona />} />
          {/* 5 */}
          <Route
            path="/home"
            element={
              accessToken ? <Home /> : <Navigate to="/welcome" replace />
            }
          />
          {/* 6 */}
          <Route
            path="/study"
            element={
              accessToken ? <Study /> : <Navigate to="/welcome" replace />
            }
          />
          {/* 7 */}
          <Route
            path="/express"
            element={
              accessToken ? <Express /> : <Navigate to="/welcome" replace />
            }
          />
          {/* 8 */}
          <Route
            path="/ai"
            element={accessToken ? <Ai /> : <Navigate to="/welcome" replace />}
          />
          {/* 8 */}
          <Route path="/aichat" element={<AiChat />} />
          {/* 9 */}
          <Route
            path="/mypage"
            element={
              accessToken ? <MyPage /> : <Navigate to="/welcome" replace />
            }
          />
          {/* 9 */}
          <Route path="/mypagenotice" element={<MyPageNotice />} />
          {/* 9 */}
          <Route
            path="/mypagenoticecontent"
            element={<MyPageNoticeContent />}
          />
          {/* 9 */}
          <Route path="/mypagefaq" element={<MyPageFaq />} />
          {/* 9 */}
          <Route path="/refundpolicy" element={<RefundPolicyPage />} />
          {/* 9 */}
          <Route path="/accountinfo" element={<AccountInfo />} />
          {/* 9 */}
          <Route path="/langsetting" element={<LangSetting />} />
          {/* 9 */}
          <Route path="/mbtisetting" element={<MbtiSetting />} />
          {/* 9 */}
          <Route path="/personasetting" element={<PersonaSetting />} />
          {/* 10 */}
          <Route path="/vocablist/:expressionId" element={<VocabList />} />
          {/* 10 */}
          <Route path="/associated/:category" element={<Associated />} />
          {/* 12 */}
          <Route path="/studypoint" element={<StudyPoint />} />
          {/* 12 */}
          <Route path="/quiza" element={<QuizA />} />
          {/* 12 */}
          <Route path="/quizb" element={<QuizB />} />
          {/* 12 */}
          <Route path="/notequiz" element={<NoteQuiz />} />
          {/* 12 */}
          <Route path="/notequiz2" element={<NoteQuiz2 />} />
          {/* 12 */}
          <Route path="/starscore" element={<StarScore />} />
          {/* 14 */}
          <Route path="/bookmark" element={<BookMark />} />
          {/* 14 */}
          <Route path="/note" element={<Note />} />
          {/* 14 */}
          <Route path="/storyboard" element={<StoryBoard />} />
          {/* 14 */}
          <Route path="/storyboardcreate" element={<StoryBoardCreate />} />

          {/* pr */}
          <Route path="/prac" element={<Prac />} />

          {/* pr */}
          <Route path="/mbtistudy" element={<MbtiStudy />} />
          {/* pr */}
          <Route path="/mbtistudylist" element={<MbtiStudyList />} />
          {/* pr */}
          <Route path="/personastudy" element={<PersonaStudy />} />
          {/* pr */}
          <Route path="/personastudylist" element={<PersonaStudyList />} />
          {/* pr */}
          <Route path="/psychologystudy" element={<PsychologyStudy />} />
          {/* pr */}
          <Route
            path="/psychologystudylist"
            element={<PsychologyStudyList />}
          />
          {/* pr */}
          <Route path="/situationstudy" element={<SituationStudy />} />
          {/* pr */}
          <Route path="/situationstudylist" element={<SituationStudyList />} />

          {/* pr */}
          <Route path="/studyonboard" element={<StudyOnboard />} />
          {/* pr */}
          <Route path="/conversation" element={<Conversation />} />
          {/* pr */}
          <Route path="/purchase" element={<Purchase />} />
          {/* pr */}
          <Route path="/appinfo" element={<AppInfo />} />
          {/* pr */}
          <Route path="/payment-success" element={<PaymentSuccess />} />
          {/* pr */}
          <Route path="/payment-failed" element={<PaymentFailed />} />
          {/* pr */}
          <Route path="/cancel-success" element={<CancelSuccess />} />
          {/* pr */}
          <Route path="/cancel-failed" element={<CancelFailed />} />
          {/* pr */}
          <Route path="/error-action" element={<ErrorAction />} />

          {/* 모든 경로가 일치하지 않을 때 기본적으로 /로 리디렉션 */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AnimatedRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;
