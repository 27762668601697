import styles from "./TextField.module.css";

const TextField = ({
  prop,
  prop1,
  prop2,
  onChange,
  type,
  className,
  darkMode,
}) => {
  return (
    <div className={styles.textField}>
      <b className={styles.b}>
        <span className={`${darkMode ? "text-white" : ""}`}>{prop}</span>
        <br></br>
        <span className={`${darkMode ? "text-white" : ""}`}>{prop1}</span>
      </b>
      {/* <div className={styles.field}> */}
      <input
        className={`${className} ${styles.field} ${
          darkMode
            ? "bg-[#22232a] placeholder-gray-500"
            : "placeholder-gray-500"
        }`}
        placeholder={prop2}
        type={type}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        style={{
          color: darkMode ? "#ffffff" : "#000000", // Input text color
        }}
      />
      {/* </div> */}
    </div>
  );
};

export default TextField;
