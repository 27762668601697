import React from "react";
import { useNavigate } from "react-router-dom";

const NOPModal = ({ isOpen, onClose, title, children }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <div className="flex justify-center items-center mb-4">{children}</div>
        <div className="mt-4 flex justify-between">
          {/* Close button */}
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-md"
          >
            닫기
          </button>
          {/* Navigate to /purchase button */}
          <button
            onClick={() => navigate("/purchase")}
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            구독하러가기
          </button>
        </div>
      </div>
    </div>
  );
};

export default NOPModal;
