import React, { useState, useEffect, useContext } from "react";
import OptionalTextField from "../logincomponents/OptionalTextField";
import BBtn from "../logincomponents/BBtn";
import PhoneVerification from "../logincomponents/PhoneVerification";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import Title from "../logincomponents/Title";
import FormForm from "../logincomponents/FormForm";
import MustTextField from "../logincomponents/MustTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { LanguageContext } from "../LanguageContext";

const SocialLogin = () => {
  const location = useLocation();
  const { name, phoneNumber } = location.state || {};
  const { email } = location.state || {};
  const navigate = useNavigate();
  const { translations, language } = useContext(LanguageContext);

  // State for form fields
  const [userId, setUserId] = useState(email || ""); // 아이디
  // const [name, setName] = useState(""); // 이름
  const [password, setPassword] = useState(""); // 비밀번호
  const [confirmPassword, setConfirmPassword] = useState(""); // 비밀번호 확인
  const [recommender, setRecommender] = useState(""); // 추천인 계정 입력
  // const [phoneNumber, setPhoneNumber] = useState(""); // 전화번호 (set inside PhoneVerification)
  const [ageConsent, setAgeConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false); // 마케팅 수신 동의 (선택)

  // Load form data from localStorage if available
  useEffect(() => {
    const savedFormData = JSON.parse(sessionStorage.getItem("socialLoginForm"));
    if (savedFormData) {
      setUserId(savedFormData.userId || "");
      setPassword(savedFormData.password || "");
      setConfirmPassword(savedFormData.confirmPassword || "");
      setRecommender(savedFormData.recommender || "");
      setAgeConsent(savedFormData.ageConsent || false);
      setMarketingConsent(savedFormData.marketingConsent || false);
    }
    sessionStorage.removeItem("socialLoginForm");
  }, []);

  // Save form data to localStorage before navigating to NICE authentication
  const saveFormData = () => {
    const formData = {
      userId,
      name,
      password,
      confirmPassword,
      recommender,
      phoneNumber,
      ageConsent,
      marketingConsent,
    };
    sessionStorage.setItem("socialLoginForm", JSON.stringify(formData));
  };

  const handleConditions = () => {
    saveFormData();
    navigate("/conditions");
  };
  const handleTerms = () => {
    saveFormData();
    navigate("/terms");
  };

  const policyStyle = {
    fontSize: "12px",
    lineHeight: "1.5",
    textAlign: "center",
    margin: "10px auto",
  };

  // Function to format the phone number
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length === 11) {
      return `+82${cleaned.substring(1)}`;
    }
    return `+82${cleaned}`;
  };

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/;

    if (!password) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("비밀번호를 입력해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return false;
    }

    if (!passwordRegex.test(password)) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error(
          "비밀번호는 영문, 숫자, 특수문자 조합으로 8자~15자 이내여야 합니다.",
          {
            position: "top-center",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: toastId, // Assign the unique toastId
            progress: undefined,
          }
        );
      }
      return false;
    }

    if (password !== confirmPassword) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("비밀번호가 일치하지 않습니다.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return false;
    }

    return true;
  };

  // Function to update profile with HTTP POST
  const apiUrl = process.env.REACT_APP_API_URL;
  const updateProfile = async () => {
    if (!ageConsent) {
      const toastId = "age-consent-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("만 14세 이상 확인이 필요합니다.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return; // Stop further execution
    }
    if (!validatePassword()) {
      return; // Stop if validation fails
    }
    if (!name) {
      const toastId = "auth-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("본인인증을 완료해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return false;
    }
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    const data = {
      userid: userId,
      loginType: "local",
      username: name,
      password: password,
      recommender: recommender,
      marketingConsent: marketingConsent,
      phoneNumber: formattedPhoneNumber,
    };

    try {
      const response = await fetch(`${apiUrl}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();

        navigate("/nickname", {
          state: { userId },
        });
      } else if (response.status === 409) {
        // Handle 409 Conflict (e.g., already registered phone number)
        const toastId = "conflict-error"; // Unique ID for the toast
        if (!toast.isActive(toastId)) {
          toast.error("이미 가입된 휴대폰번호입니다.", {
            position: "top-center",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: toastId,
            progress: undefined,
          });
        }
      } else {
        alert("Failed to update profile.");
      }
    } catch (error) {
      alert("An error occurred while updating the profile.");
    }
  };

  const openNiceWindow = async () => {
    sessionStorage.setItem("registerwhere", "register");
    saveFormData();
    const { form } = document;
    const res = await axios.get(`https://pastellapp.com/api/auth/nice`);

    if (form && res.data) {
      const { tokenVersionId, encData, integrityValue } = res.data;
      form.enc_data.value = encData;
      form.token_version_id.value = tokenVersionId;
      form.integrity_value.value = integrityValue;
      form.submit();
    }
  };

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#fff]">
      <div className="relative min-x-[360px] w-full bg-[#fff]">
        <ReactiveOuter>
          <FormTop>
            <Title
              titleGap="40%"
              frameDivWidth="unset"
              prop="회원가입"
              bAlignSelf="unset"
              bHeight="unset"
              bOpacity="unset"
              bMinWidth="101px"
              bDisplay="inline-block"
              to="/welcome"
            />
            <FormForm>
              <OptionalTextField
                prop="이메일"
                prop1="아이디"
                value={userId}
                onChange={(e) => setUserId(e.target.value)} // Set 아이디 in state
                disabled={"disabled"}
              />
              {name ? (
                <MustTextField
                  prop="이름"
                  prop1="본인인증을 진행해주세요."
                  value={name}
                  style={{ backgroundColor: "#E2E8EF" }}
                />
              ) : (
                <div
                  onClick={openNiceWindow}
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    textAlign: "center",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    margin: "20px auto", // To center horizontally
                    maxWidth: "200px", // Optional, adjust as needed
                  }}
                >
                  본인인증
                </div>
              )}
              {phoneNumber ? (
                <MustTextField
                  prop="휴대폰번호"
                  prop1="본인인증을 진행해주세요."
                  value={phoneNumber}
                  style={{ backgroundColor: "#E2E8EF" }}
                />
              ) : (
                ""
              )}
              {/* Assuming this component sets phone number */}
              <MustTextField
                prop="비밀번호"
                prop1="영문, 숫자, 특수문자 조합 8자~15자 이내"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Set 비밀번호 in state
              />
              <MustTextField
                prop="비밀번호 확인"
                prop1="비밀번호를 한번 더 입력해주세요"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)} // Set 비밀번호 확인 in state
              />
              <OptionalTextField
                prop="추천인 계정 입력"
                prop1="이름"
                value={recommender}
                onChange={(e) => setRecommender(e.target.value)} // Set 추천인 계정 입력 in state
              />
              <div className="flex flex-col gap-4 w-full">
                <div className="flex items-center justify-between">
                  <b className="mr-2">
                    만 14세 이상입니다{" "}
                    <span style={{ color: "var(--color-tomato)" }}>*</span>
                  </b>
                  <input
                    type="checkbox"
                    checked={ageConsent}
                    onChange={(e) => setAgeConsent(e.target.checked)} // Set 만 14세 이상입니다 in state
                  />
                </div>
                <div className="flex items-center justify-between">
                  <b className="mr-2">마케팅 수신 동의(선택)</b>
                  <input
                    type="checkbox"
                    checked={marketingConsent}
                    onChange={(e) => setMarketingConsent(e.target.checked)} // Set 마케팅 수신 동의(선택) in state
                  />
                </div>
              </div>
            </FormForm>
            <div className="bottom-[5vh] w-full mt-[5vh]">
              <div style={policyStyle}>
                <a className="underline" onClick={handleConditions}>
                  <b>이용 약관</b>
                </a>
                과{" "}
                <a className="underline" onClick={handleTerms}>
                  <b>개인정보 처리방침</b>
                </a>
                에 동의시 회원가입을 진행해주세요
              </div>
              <BBtn prop={"회원가입"} onClick={updateProfile} />{" "}
              {/* Call updateProfile on button click */}
            </div>
          </FormTop>
        </ReactiveOuter>
      </div>
      <ToastContainer />
      <form
        name="form"
        id="form"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
    </div>
  );
};

export default SocialLogin;
